import ReactCookieConsent from 'react-cookie-consent';

export const CookieConsent = () => {
    return (
        <ReactCookieConsent
            location='bottom'
            buttonText='Accept'
            enableDeclineButton
            declineButtonText='Decline'
            cookieName='userConsentCookie'
            style={{
                background: '#fff',
                borderRadius: '12px',
                color: '#15295a',
                flexDirection: 'column',
                padding: '2.2em',
                marginLeft: '20%',
                marginRight: '10%',
                marginBottom: '1em',
                width: '70% !important',
                boxShadow: '-2px 3px 5px rgba(0,0,0,.1)'
            }}
            contentStyle={{ flex: '1 0 50px', margin: 0, marginBottom: '15px' }}
            overlayStyle={{ background: 'red' }}
            declineButtonStyle={{ 
                color: '#fff',
                fontSize: '13px',
                backgroundColor: '#425b76',
                height: '3.3em',
                minWidth: '11em',
                borderRadius: '3em',
                margin: 0
            }}
            buttonStyle={{
                color: '#fff',
                fontSize: '13px',
                backgroundColor: '#425b76',
                height: '3.3em',
                minWidth: '11em',
                borderRadius: '3em',
                margin: 0,
                marginRight: '2em'
            }}
            expires={150}
        >
            By clicking “Accept”, you agree to the storing of cookies on your device to enhance site navigation, 
            analyze site usage, and assist in our marketing efforts. 
            To find out more about the cookies we use, see our {' '}
            <a href='https://www.indy.auction/indy.auction-global-privacy-policy'>
                <span style={{ textDecoration: 'underline' }}>Privacy Policy</span>
            </a>.
        </ReactCookieConsent>
    )
}