/**
 * Add the fields with true if you want to disable the fields
 */

export default {
    draft: {
        // auction_image: true,
        // extension_time_between_lots: true,
        // extension_time: true,
        // extension_type: true,
        // registration_type: true,
        // add_buyer_fees: true,
        // currency: true,
        // termsAndCondition: true,
        // startHour: true,
        // startMin: true,
        // timeZone: true,
    },
    published: {
        extension_time_between_lots: true,
        extension_time: true,
        extension_type: true,
        registration_type: true,
        add_buyer_fees: true,
        currency: true,
        termsAndCondition: true,
    },
    acceptingBids: {
        extension_time_between_lots: true,
        extension_time: true,
        extension_type: true,
        registration_type: true,
        add_buyer_fees: true,
        currency: true,
        termsAndCondition: true,
        startHour: true,
        startMin: true,
        timeZone: true,
    }
};
