import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function BidderDetailsShimmer(){
    
    return(
        <div className='z-1' >
            <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border"> 
                <p className="py-[22px] border-b border-[#CECECE] sm:px-8 px-4 text-[16px] font-medium 
                    leading-normal ">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                        <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                    </SkeletonTheme></p>
                <div className="flex flex-wrap my-[27px] w-full xxs:mx-4">
                    <div className="md:w-[33%] xxs:w-[50%] w-full px-4 mb-[24px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={2} className='h-[10px] max-w-[155px]' />
                        </SkeletonTheme>
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full px-4 mb-[24px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={2} className='h-[10px] max-w-[155px]' />
                        </SkeletonTheme>
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full px-4 mb-[24px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={2} className='h-[10px] max-w-[155px]' />
                        </SkeletonTheme>
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full px-4 mb-[24px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={2} className='h-[10px] max-w-[155px]' />
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
            <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                <p className="py-[22px] border-b border-[#CECECE] px-8 text-[16px] font-medium 
                    leading-normal text-[#343434]">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                        <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                    </SkeletonTheme>
                </p>
                <div className="pt-[27px] pb-[13px] px-9">
                    <p className="text-[16px] font-medium leading-normal text-[#343434]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[16px] max-w-[180px] pt-4 ' />
                        </SkeletonTheme></p>
                    <p className="text-[16px] font-medium leading-normal text-[#343434]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[16px] max-w-[180px] pt-4 ' />
                        </SkeletonTheme></p>
                    <p className="text-[16px] font-medium leading-normal text-[#343434]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[16px] max-w-[180px] pt-4 ' />
                        </SkeletonTheme></p>
                    <p className="text-[16px] font-medium leading-normal text-[#343434]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[16px] max-w-[180px] pt-4 ' />
                        </SkeletonTheme></p>
                    <p className="text-[16px] font-medium leading-normal text-[#343434]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[16px] max-w-[180px] pt-4 ' />
                        </SkeletonTheme></p>
                    <p className="text-[16px] font-medium leading-normal text-[#343434]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[16px] max-w-[180px] pt-4 ' />
                        </SkeletonTheme></p>
                </div>
            </div> 
        </div>
    );
}