export const dateConverter = (timestamp)=>{
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Format the date as "dd MMM yyyy"
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    }).format(date);
    return formattedDate;
}
