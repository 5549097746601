/* eslint-disable security/detect-object-injection */
import React, { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'

export default function FaqModal({auctionData,setAuctionData,isFaqOpen,setIsFaqOpen, sections, setSections, 
    handleAddFAQ, addSection, setAddSection}) {
        
    const [disableButton, setDisableButton] = useState(true)
    /**
     * The function `handleSectionChange` updates a specific field value in an array of sections at a
     * given index.
     */
    const handleSectionChange = (index, field, value) => {
        const updatedSections = [...sections];
        updatedSections[index][field] = value;
        setSections(updatedSections);
    };
    useEffect(()=>{
        let allFilled = true;

        sections.forEach(element => {
            if ((element.question === '' && element.answer !== '')|| (element.question !== '' && element.answer === '')||
            (element.question === '' && element.answer === '')) {
                allFilled = false;
            }
        });

        setDisableButton(!allFilled);

    },[sections])
    return (
        <Dialog open={isFaqOpen} onClose={() => setIsFaqOpen(false)}
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-[#282828cc]"
            id="exampleModalXl2"
            tabIndex="-1"
            aria-labelledby="exampleModalXlLabel"
            aria-modal="true"
            role="dialog">
            <Dialog.Panel
                data-te-modal-dialog-ref
                className="pointer-events-none mx-5 relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] 
                items-center transition-all duration-300 ease-in-out min-[740px]:mx-auto min-[740px]:mt-7 
                min-[740px]:min-h-[calc(100%-3.5rem)] min-[740px]:max-w-[658px]">
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none 
                    bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <Dialog.Title
                        className="flex flex-shrink-0 border-b border-[#A1A1A9] items-center justify-between rounded-t-md p-4">
                        <p className="text-[22px] text-[#343434] leading-normal font-medium pl-3">FAQs</p>
                        {/* <!--Close button--> */}
                        <button
                            onClick={()=>{setIsFaqOpen(false);setSections([
                                { question: '', answer: '' }
                            ]);setAddSection(false)}}
                            type="button"
                            className="box-content rounded-none border-none"
                            data-te-modal-dismiss
                            aria-label="Close">
                            <img src="/images/CloseButton-modalcontent.svg" alt="img" />
                        </button>
                    </Dialog.Title>
                    {/* <!--Modal body--> */}
                    {addSection===false ?
                        <div id='faq-modal' className="relative p-4 mx-3 mt-2 ">
                            <div id='faq-modal' className="relative p-4 mx-3 mt-2 ">
                                <div className=" sm:px-10" >
                                    <div className=" h-[330px] pr-6 overflow-y-auto overflow-x-hidden customScrollbar">
                                        {auctionData.faq.map((faq, index) => (
                                            <div className='mb-4' key={index}>
                                                <p className="text-[#343434] text-[12px] font-bold leading-normal">
                                                    {faq.question}</p>
                                                <p className="text-[#343434] text-[12px] font-medium leading-normal pt-3">
                                                    {faq.answer} 
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex justify-center shadow-bs6 pt-2 mb-4" 
                                    style={{filter: "drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.06));"}}>
                                    <button id='add-to-faq' 
                                        type='button' onClick={()=>{setAddSection(true),setSections([{question:'', answer:''}])}}
                                        className="border border-[#A1A1A9] rounded-md text-[14px] text-[#52525A] 
                                leading-normal mt-2.5 font-medium px-[20px] py-[12px] w-[135px]"
                                        data-te-toggle="modal"
                                        data-te-target="#exampleModalXl"
                                        data-te-ripple-init
                                        data-te-ripple-color="light"
                                        data-te-modal-dismiss
                                        aria-label="Close">Add to FAQs</button>
                                </div>
                            </div>
                        </div>

                        :
                        <div className="relative p-4 sm:mx-14 mt-6 mx-5">
                            <div>
                                {sections.map((section, index) => (
                                    <div key={index}>
                                        <div>
                                            <input id='faq-question' type="text"
                                                value={section.question}
                                                className="border border-[#A1A1A9] w-full px-3 py-3 outline-none 
                                    text-[14px] leading-normal font-medium text-[#343434] placeholder:text-[#A1A1A9]" 
                                                placeholder="Add FAQ question"             
                                                onChange={(e) => handleSectionChange(index, 'question', e.target.value)}
                                            />  
                                        </div> 
                                        <div className="mt-2">
                                            <textarea id='faq-answer' name="text-area"
                                                value={section.answer} 
                                                onChange={(e) => handleSectionChange(index, 'answer', e.target.value)}
                                                className=" border border-[#A1A1A9] w-full px-3 h-[144px] 
                                    overflow-y-auto overflow-x-hidden customScrollbar py-2 outline-none text-[14px] 
                                    text-start resize-none leading-normal text-[#343434] font-medium placeholder:text-[#A1A1A9]" 
                                                placeholder="Add FAQ Answer" cols="10" rows="10"></textarea> 
                                        </div> 
                                    </div>
                                ))} 
                            </div>
                            <button type='button' disabled={disableButton} 
                                className="w-full flex items-center mt-3 rounded-md disabled:cursor-auto cursor-pointer 
                                        disabled:opacity-[0.6]" 
                                onClick={()=>setSections([...sections, { question: '', answer: '' }])}>
                                <img src="/images/addlinks-icon.svg" className="disabled:cursor-auto" 
                                    alt="add-links-icon" />
                                <span className=" w-fit px-1 py-3 text-[12px] leading-normal font-medium 
                                            text-[#343434]"> 
                                    <span className="disabled:cursor-auto">Add another FAQ</span></span>
                            </button>
                            {/* <!-- active confirm btn --> */}
                            <div className="flex justify-center dropshadow-s3 sm:mt-7 my-3 sm:mb-6">
                                <button id='confirm-faq' 
                                    disabled={disableButton}
                                    className={`${disableButton?'bg-[#D4D4D8]':'bg-[#282828]'} 
                                    rounded-md text-[14px] text-[#fff] leading-normal mt-2.5 
                                font-medium px-[20px] py-[12px] w-[135px]`} 
                                    onClick={()=>{setDisableButton(false),!disableButton?handleAddFAQ():null}}>Confirm</button>
                            </div>
                        </div>
                    }
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}
