/* eslint-disable security/detect-object-injection */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    free_user: '',
    plan_type: '',
    email_address: ''
};
/* The code is creating a slice of the Redux store called `pricingSlice`. */
const pricingSlice = createSlice({
    name: 'pricing',
    initialState,
    reducers: {
        updatePricingValue: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
            if(field==='plan_type'){
                localStorage.setItem('plan_type',value);
            }
        }
    }
})
export const { updatePricingValue} = pricingSlice.actions;
export default pricingSlice.reducer;

