import ReactPaginate from 'react-paginate'

export default function Paginate(props){

    const {totalPageNumber,selectedPageNumber,handleOnClickPageNumber }=props;

    return(
        totalPageNumber> 1 &&
            <ReactPaginate className="flex mt-5 justify-center pt-7 md:pb-16 pb-[100px] text-[#C2C2C2]" 
                previousLabel={
                    <button className={` ${selectedPageNumber===1 ? 'cursor-default' : ''} mr-2 `}>
                        <img decoding="async" loading="lazy" id="previous-page" src={` ${selectedPageNumber===1 ? 
                            "/images/ArrowButton.png" : '/images/arrow-left-dark.svg'}`} alt="icon" />
                    </button>
                }
                nextLabel={
                    <button className={` ${selectedPageNumber===totalPageNumber ? 'cursor-default' : ''} ml-3 `}>
                        <img decoding="async" loading="lazy" id="next-page" src={` ${selectedPageNumber===totalPageNumber ? 
                            "/images/disable-arrow.png" : "/images/active-arrow.png" }`} alt="icon" />
                    </button>
                }
                breakLabel="..."
                pageCount={totalPageNumber}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                forcePage={selectedPageNumber - 1}
                onPageChange={handleOnClickPageNumber}
                containerClassName="flex justify-center items-center"
                pageClassName="text-[12px] leading-normal font-semibold mr-1 bg-white  w-[32px] h-[30px] 
            inline-flex justify-center items-center"
                activeClassName="text-[#1E1E1E]"
            />
    );
}