import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function TableShimmer(){
    return(
        <div className="border border-[#DEDEDE] table-row  h-14 ">
            <td className="px-4 py-4 text-[16px] leading-normal font-medium">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                    <Skeleton count={1} className='h-[30px] max-w-[100px]' />
                </SkeletonTheme>
            </td>
            <td className=" px-4 py-4 w-[90px] h-[70px]">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                    <Skeleton count={1} className='h-[70px] w-[90px]' />
                </SkeletonTheme>
            </td>
            <td className="px-4 py-3 text-[16px] text-center leading-normal">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                    <Skeleton count={1} className='h-[30px] max-w-[100px]' />
                </SkeletonTheme>
            </td>
            <td className="px-4 py-3 text-[16px] text-center leading-normal">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                    <Skeleton count={1} className='h-[30px] max-w-[100px]' />
                </SkeletonTheme>
            </td>
            <td className="px-4 py-3 text-[16px] text-center leading-normal">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                    <Skeleton count={1} className='h-[30px] max-w-[100px]' />
                </SkeletonTheme>
            </td>
            <td className="px-4 py-3 text-[16px] text-center leading-normal rounded-[10px]">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                    <Skeleton count={1} className='h-[30px] max-w-[100px]' />
                </SkeletonTheme>
            </td>
        </div>
    )
}