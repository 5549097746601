/* eslint-disable max-len */
import { useEffect, useState } from "react";
import { timeZoneMap } from "../../../../../CommonComponent/staticValues";
/* eslint-disable security/detect-unsafe-regex */
export default function Bid(props){

    const { bidsInfo, auctionData, pagination, bids,topBid, topBidder, underBidder,searchParams } = props;
    const [sortedBids,setSortedBids]=useState([])

    useEffect(() => {
        if (bids?.length > 0) {
            const bidsPerPage = 10; // Number of bids to display per page
            const currentPage = parseInt(searchParams.get('page')) || 1; // Get current page from URL or default to 1
            const startIndex = (currentPage - 1) * bidsPerPage;
            const endIndex = startIndex + bidsPerPage;
            
            // Sort bids by timestamp
            const sortedBidsCopy = bids.slice().sort((a, b) => {
                const timestampA = new Date(a.time_stamp).getTime();
                const timestampB = new Date(b.time_stamp).getTime();
                return timestampB - timestampA;
            });
    
            // Set sorted bids for the current page
            setSortedBids(sortedBidsCopy.slice(startIndex, endIndex));
        }
    }, [bids, searchParams.get('page')]);


    // const formatDate = (timestamp) => {
    //     const date = parseISO(new Date(timestamp).toISOString()); // Convert timestamp to Date
    //     const formatted = format(date, 'dd MMM yyyy / HH:mm XXX', { timeZone: timeZoneMap[auctionData.time_zone] });
    //     return formatted;
    // };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp); // Convert timestamp to Date
  
        // Format options for date and time
        const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            timeZoneName: 'short',
            hour12: false, // Force 24-hour format
            timeZone: timeZoneMap[auctionData.time_zone],
        };
  
        // Format the date using toLocaleString
        let formatted = date.toLocaleString('en-US', options);
        if(formatted.includes('GMT+5:30')){
            formatted = formatted.replace('GMT+5:30', 'IST');
        }else if(formatted.includes('GMT+11')){
            formatted = formatted.replace('GMT+11', 'AESR');
        }else if(formatted.includes('GMT+13')){
            formatted = formatted.replace('GMT+13', 'NZST');
        }else if(formatted.includes('GMT+1')){
            formatted = formatted.replace('GMT+1', 'CET');
        }else if(formatted.includes('GMT+9')){
            formatted = formatted.replace('GMT+9', 'JST');
        }else if(formatted.includes('GMT+8')){
            formatted = formatted.replace('GMT+8', 'CST');
        }
  
        return formatted;
    };

    return(
        Object.keys(auctionData).length > 0  && (
            bids?.length>0 ? sortedBids.map((bid, index)=><tr key={index} className={`border border-[#DEDEDE] table-row text-left h-14`} > 
                <td className="text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium ml-3" >
                    {bid.paddle_number}
                </td>
                <td className="text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium" >
                    {(bid.name =="" || bid.name ==" ") ? '-' : bid.name}
                </td>
                <td className={` ${(topBid==bid.bid_amount && (topBidder==bid.name || (topBidder=="-" && (bid.name=="" || bid.name==" ")))) ? 'text-[#489892]' : ((underBidder.bid_amount==bid.bid_amount && underBidder.name==bid.name) ? 'text-[#B98B47]' : 'text-[#343434]') } px-4 py-3 text-[16px] leading-normal font-medium`} >
                    {Number(bid.bid_amount).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                        style: 'currency',
                        currency: auctionData.currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </td>
                <td className="text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium" >
                    {formatDate(bid.time_stamp)}
                </td>
            </tr>) : 
                bidsInfo.map((bid, index)=><tr key={index} className={`border border-[#DEDEDE] table-row text-left h-14`} > 
                    <td className="text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium ml-3" >
                        {bid.paddle_number}
                    </td>
                    <td className="text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium" >
                        {(bid.name =="" || bid.name ==" ") ? '-' : bid.name}
                    </td>
                    <td className={` ${(topBid==bid.bid_amount && (topBidder==bid.name || (topBidder=="-" && (bid.name=="" || bid.name==" ")))) ? 'text-[#489892]' : ((underBidder?.bid_amount==bid.bid_amount && underBidder.name==bid.name)  ? 'text-[#B98B47]' : 'text-[#343434]') } px-4 py-3 text-[16px] leading-normal font-medium`} >
                        {Number(bid.bid_amount).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                            style: 'currency',
                            currency: auctionData.currency,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </td>
                    <td className="text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium" >
                        {formatDate(bid.time_stamp)}
                    </td>
                </tr>)
        )

    )
}