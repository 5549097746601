import React from 'react'
import { Link } from 'react-router-dom'

export default function RegistrationHeader() {
    return (
        <div className="flex sm:justify-between">
            <a href='https://www.indy.auction' target='_blank' rel="noreferrer">
                <img src="images/indy-logo.svg" alt="logo" className="cursor-pointer" loading="lazy" />
            </a>
            <div className=" items-center hidden sm:flex justify-between">
                <p className="text-[#FFF] font-medium  text-[16px] leading-normal ">Already have an account?</p>
                <Link to="/login" id="signin-btn" className="ml-2 text-[#FFF] cursor-pointer rounded-md border border-[#FFF] 
                    px-8 py-1.5 text-sm font-medium">Sign in</Link>
            </div>
        </div>
    )
}
