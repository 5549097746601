import { asyncWithLDProvider,LDClient } from 'launchdarkly-react-client-sdk';


const clientSideIDTest = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;


const initLaunchDarkly = async (email_address) => {

    try {      
        // Initialize LaunchDarkly with the provided context
        const provider = await asyncWithLDProvider({
            clientSideID:clientSideIDTest, // Replace with your actual client side ID
            options: {
                bootstrap: "localStorage"
            },
        });

        console.log('LaunchDarkly initialized successfully:');
        return provider;
    } catch (error) {
        console.error('Failed to initialize LaunchDarkly:');
        return null; // Return null or handle the error as needed
    }
};

export default initLaunchDarkly;
