import React,{useState} from 'react'
import { ChromePicker } from 'react-color';

export default function PaddleDesign({auctionData, setAuctionData, setEditedAuctionData}) {
    const [showBgColorPicker, setShowBgColorPicker] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    
    return (
        <>
            {/* <!-- paddle --> */}
            <div 
                className="w-full mt-2 flex justify-center items-center  h-[134px] text-[34px] text-[#fff] 
                    font-medium leading-normal border border-[#282828] bg-[#2E2E2E] rounded-[14px]"
                style={{backgroundColor: auctionData.paddle['background_color'], color: auctionData.paddle['text_color']}}>
                102
            </div>
            {/* <!-- color content --> */}
            <div className="flex mt-5">
                <div onClick={()=>setShowBgColorPicker(true)} 
                    className="w-[30px] h-[22px] border border-[#282828] bg-[#343434] rounded-[4px] cursor-pointer"
                    style={{backgroundColor: auctionData.paddle['background_color']}}>
                </div>
                <p className="font-medium text-[#343434] text-[12px] pl-[10px] leading-normal">
                    Background colour</p>
            </div>
            {showBgColorPicker && 
            <>
                <div className='fixed top-0 left-0 right-0 w-full h-full' 
                    onClick={()=>setShowBgColorPicker(false)}/>
                <ChromePicker color={auctionData.paddle['background_color']} 
                    onChange={(updatedColor) => {setAuctionData(prevData => ({
                        ...prevData,
                        paddle: {
                            text_color : auctionData.paddle.text_color,
                            background_color: updatedColor.hex
                        }
                    })), setEditedAuctionData(prevData => ({
                        ...prevData,
                        paddle: {
                            text_color : auctionData.paddle.text_color,
                            background_color: updatedColor.hex
                        }
                    }))}}
                />
            </>
            }
            <div className="flex mt-5">
                <div onClick={()=>setShowColorPicker(true)} 
                    className="w-[30px] h-[22px] border border-[#282828] bg-[#F9F7F7] rounded-[4px] cursor-pointer"
                    style={{backgroundColor: auctionData.paddle['text_color']}}>
                </div>
                <p className="font-medium text-[#343434] text-[12px] pl-[10px] leading-normal">Text colour</p>
            </div>
            {showColorPicker && 
            <>
                <div className='fixed top-0 left-0 right-0 w-full h-full' 
                    onClick={()=>setShowColorPicker(false)}/>
                <ChromePicker color={auctionData.paddle['text_color']} 
                    onChange={(updatedColor) => {setAuctionData(prevData => ({
                        ...prevData,
                        paddle: {
                            background_color: auctionData.paddle.background_color,
                            text_color: updatedColor.hex
                        }
                    })), setEditedAuctionData(prevData => ({
                        ...prevData,
                        paddle: {
                            background_color: auctionData.paddle.background_color,
                            text_color: updatedColor.hex
                        }
                    }))}}
                />
            </>
            }
        </>
    )
}
