/* eslint-disable max-len */
import React from 'react';
import Skeleton from 'react-loading-skeleton';

function ItemShimmer() {
    return (
        <div class="pb-[200px] pt-2 overflow-auto">
            <div class="z-10 border-[#DEDEDE] border rounded-lg xl:w-full w-auto">
                <table aria-label="Outside Table" class="w-full overflow-auto table-auto">
                    <tbody>
                        {[...Array(5)].map((item, index)=>(
                            <tr class="border-b  border-[#DEDEDE] table-row  h-14" key={index}>
                                <td class=" text-[#343434] w-[100px]  px-8 py-8 text-[14px] leading-normal font-medium">
                                    <Skeleton/>
                                </td>
                                <td className='w-[100px] px-8 py-8'><Skeleton/></td>
                                <td class=" text-[#343434] w-[100px] px-8 py-8 text-[14px] text-center leading-normal font-medium">
                                    <Skeleton/>
                                </td>
                                <td class=" text-[#343434] w-[100px] px-8 py-8 text-[14px] text-center leading-normal font-medium">
                                    <Skeleton/>
                                </td>
                                <td class=" text-[#343434] w-[100px] px-8 py-8 text-[14px] text-center leading-normal font-medium">
                                    <Skeleton/>
                                </td>
                                <td class=" text-[#343434] w-[100px] px-8 py-8 text-[14px] text-center leading-normal font-medium">
                                    <Skeleton/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ItemShimmer;