/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

export default function FlagBanner({sblogin,SellerLogin,logValue}) {
    const [message, setMessage] = useState('');
    const [isHtmlMessage, setIsHtmlMessage] = useState(false);

    useEffect(() =>{
        if(sblogin===true || SellerLogin=== true){
            setMessage(
                `We're currently upgrading our platform and will be back online as quickly as possible. For urgent assistance, you can contact us at  <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@indy.auction" target="_blank" rel="noopener noreferrer" class="highlight-link">customer support</a> .Thank you for your patience and understanding. Please try again shortly.`
            );
            setIsHtmlMessage(true); 
        }
        // If logValue is true, start the 2-minute timer
        if (logValue) {
            setMessage(`We're currently upgrading our platform and will be back online as quickly as possible. For urgent assistance, you can contact us at customer support <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@indy.auction" target="_blank" rel="noopener noreferrer" class="highlight-link">customer support</a>. Thank you for your patience and understanding. Please try again shortly.`);
            setIsHtmlMessage(true); 
        }
        
         
    },[logValue])

    
    return (
       
        <div>
            <div className={`fixed  bg-[#DDD1D5] text-center text-[#282828]
             text-[14px] leading-[17px] font-medium font-Medium w-full top-[2.5rem] px-2 py-4 mb-5 z-[9999]`}>
                <div className={`justify-center items-center md:flex flex flex-wrap   `}>
                    {isHtmlMessage ? (
                        <p
                            className="text-[#282828]"
                            dangerouslySetInnerHTML={{ __html: message }}
                        ></p>
                    ) : (
                        <p className="text-[#282828]">{message}</p>
                    )}
                </div>
            </div>
        </div>
    )
}

