import React, { useEffect, useRef } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const AutoLogout = ({}) => {
    const { SBLogin } = useFlags();  // Get the feature flag from LaunchDarkly
    const navigate = useNavigate();  // React Router's hook for navigation
    const ldClient = useLDClient();
    const sblogin = ldClient.variation("SBLogin",false);
    const SellerLogin =ldClient.variation("SellerLogin", false);
    const email_ID = localStorage.getItem("user");
    const initializedRef = useRef(false); // Track if listener is set up

    console.log('SellerLogin',SellerLogin)


    // Function to handle user logout
    const logoutUser = () => {
        Auth.signOut();
        sessionStorage.clear();  // Clear session data
        localStorage.clear();
        navigate('/login');  // Redirect user to login page

    };

    // Function to identify the user and check the flag value
    const identifyUserAndCheckFlag = async () => {
        if (!email_ID) {
            console.warn('No email ID found in local storage.');
            return;
        }

        const context = {
            kind: 'user',
            key: email_ID,
            email: email_ID,
        };

        try {
            await ldClient.identify(context); // Identify the user
            await ldClient.waitForInitialization(); // Ensure client is initialized

            // Check the SellerSpecificUser flag
            const disableLoginFlag = ldClient.variation('SellerSpecificUser', false);

            if (disableLoginFlag) {
                logoutUser(); // Logout if flag is enabled
                // Store the logout message
                sessionStorage.setItem('log','true')

            }
        } catch (error) {
            console.log('Error identifying user or checking flag:', error);
        }
    };

    // Monitor flag changes in real-time
    const setupFlagChangeListener = () => {
        if (initializedRef.current) return; // Avoid multiple listeners

        ldClient.on('change', (changes) => {

            if (changes.SellerSpecificUser) {
                const newValue = changes.SellerSpecificUser.current;

                if (newValue) {
                    logoutUser(); // Logout if the flag becomes true
                    sessionStorage.setItem('log','true')

                }
            }
        });

        initializedRef.current = true; // Mark listener as set up
    };
    
    useEffect(() => {
        if (ldClient && email_ID) {
            identifyUserAndCheckFlag(); // Check flag immediately on mount
            setupFlagChangeListener();  // Set up listener for future changes
        }
    }, [ldClient, email_ID]); // Runs when ldClient or email_ID changes

    // Monitor the SBLogin flag for changes and log out if enabled
    useEffect(() => {
        if (sblogin || SellerLogin) {
            logoutUser();  // Auto logout if the feature flag is enabled
        }
    }, [sblogin,SellerLogin]);  // Re-run if the SBLogin flag changes

    return null;  // No UI is required for this component
};

export default AutoLogout;
