import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { motion } from 'framer-motion';
import Button from '../../../CommonComponent/button';
import RecaptchaModal from './recaptchaModal';

export default function Otp(props) {
  
    const {
        otp, setOTP, otpError, setOtpError, timer, setTimer, setIsOTPResent, loader, isOTPResent, 
        otpVerification, registration, handleSubmit, setLoader, setCaptchaToken,
        recaptcha, setRecaptcha, otpSuccess, setOtpSuccess
    } = props;

    const [tcaptcha, setTCaptcha] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (otpSuccess) {
            const timeoutId = setTimeout(() => {
                setOtpSuccess(false);
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [otpSuccess]);

    const handleCaptcha = (newToken) => {
        if (newToken) {
            setRecaptcha(true);
            setCaptchaToken(newToken);
            setOtpSuccess(true);
            setIsOTPResent(false);
            handleSubmit(false, newToken);
            setLoader(false);
            const timeoutId = setTimeout(() => {
                setTCaptcha(false);
                setTimer(30); // Start the timer

            }, 2000);
            return () => clearTimeout(timeoutId);
        } else {
            setRecaptcha(false);
            setCaptchaToken('');
        }
    };

    const handleCaptchaError = () => {
        setRecaptcha(false);
        setCaptchaToken('');
        setErrorMessage('Captcha verification failed. Please try again.');
    };

    const handleResendClick = () => {
        if (isOTPResent) {
            setTCaptcha(true);
            setIsOTPResent(false);
        } else {
            return;
        }
    };

    const closeModal = () => {
        setTCaptcha(false);
    };

    useEffect(() => {
        let intervalId;
    
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setIsOTPResent(true);
        }
        return () => clearInterval(intervalId);
    }, [timer]);
    

    return (
        <motion.div
            initial={{ opacity: 0, scaleX: '0' }}
            animate={{ opacity: 1, scaleX: '1' }}
            transition={{ duration: 0.9 }}
            className="xl:w-[30%] lg:w-[38%] md:w-[48%] sm:w-[60%] w-full bg-white mt-12 md:float-right 
                lg:mr-20 md:mr-5 mx-auto h-fit rounded-[10px] ">
            <div className="px-8 pt-[26px] pb-7 overflow-y-auto overflow-x-hidden sm:h-customh2 h-customh3  
            flex justify-center flex-col items-center">
                <p className="text-[22px] font-medium text-center leading-normal vrfy-email-label">Verify your email</p>
                <p className="xxs:text-[18px] text-[16px] font-medium text-[#A1A1A9] text-center leading-normal">
                    Please enter the code sent to <br /> {registration.email}</p>
                <p className="mt-5 text-center text-sm font-medium text-[#52525A] leading-normal">
                    Didn’t receive the code? Check your spam or <br /> update your email.</p>
                <form id="verify-email" autoComplete="off" onSubmit={(e) => otpVerification(e)}>
                    <OTPInput
                        value={otp}
                        onChange={(e) => {
                            if (/^[0-9]*$/.test(e) || e === '') {
                                setOTP(e);
                                setOtpError('');
                            }
                        }}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        renderSeparator={<span className='w-5'></span>}
                        renderInput={(props, index) => <input className='otp-input' {...props} id={`digit-${index + 1}`} />}
                        inputStyle={{ width: '20%', height: '70px', fontSize: '20px', marginTop: '20px', 
                            outline: '0', border: otpError !== "" ? 
                                '1px solid #984848' : '1px solid #343434' }} />
                    {otpError !== '' &&
                        <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                            className="font-medium pt-1 otp-err-msg text-[#984848] text-[10px] leading-normal">
                            Invalid OTP. Please try again or request a new OTP</motion.p>}
                    <button type='button'
                        className={`underline mt-2 font-medium text-[10px] text-center leading-normal pt-2 flex 
                        justify-center w-full ${isOTPResent ? "cursor-pointer" : "cursor-auto"}`} 
                        onClick={handleResendClick}>
                        {!isOTPResent ? `Resend code in 00:${timer < 10 ? `0${timer}` : timer}` : "Resend code"}
                    </button>

                    {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}

                    {tcaptcha && (
                        <RecaptchaModal
                            isVisible={tcaptcha}
                            onClose={closeModal}
                            onCaptchaChange={handleCaptcha}
                            onCaptchaError={handleCaptchaError}
                        />
                    )}

                    <Button text="Register" type="submit" disabled={loader} loader={loader} onClick={(e) => otpVerification(e)} />
                </form>
            </div>
        </motion.div>
    );
}
