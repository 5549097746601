export default function NewPasswordHeader(){
    return(
        <>
            <div className="flex sm:justify-between">
                <img src="images/indy-logo.svg" alt="logo" className="cursor-pointer" loading="lazy"/>
                <div className=" items-center hidden sm:flex justify-between">
                    <p className="text-[#FFF] font-medium  text-[16px] leading-normal ">Don’t have an account?</p>
                    <a href="seller-registration-page.html"> <button id="signin-btn" 
                        className="ml-2 text-[#FFF] cursor-pointer rounded-md border border-[#FFF] px-8 py-1.5 
                                text-sm font-medium">Get started</button></a>  
                </div>
            </div> 
        </>
    );
}