import SingleRow from "./singleRow";

export default function Bids({bids, auctionData}){
    return(
        <>
            {bids.map((bid,index)=>(
                <SingleRow bid={bid} key={index} auctionData={auctionData}
                />
            ))}
        </>
    )
}