import React, { useState } from 'react'
import TableRow from './tableRow'
import { Link } from 'react-router-dom';
import TbodyShimmer from './tbodyShimmer';

export default function ListingBody(props) {

    const { auctionData , handleSort, handleAddNote, isFilterApplied, searchParams, auction, loading, currentSortColumn, 
        cloneHandler,isDeleteModalOpen,loader,setIsDeleteModalOpen,handleDelete, isCancelModalOpen,setMessage,setLoader,
        setIsCancelModalOpen,handleCancel,handleFetch, domain, fetchAuctions, setErrorMessage} =props;

    const keyword=`${decodeURIComponent(searchParams.get('keyword')).length>20 ? 
        decodeURIComponent(searchParams.get('keyword')).substring(0,20)+'...' : decodeURIComponent(searchParams.get('keyword'))}`

    /**
     * The addNoteHandler function calls the handleAddNote function with the provided auction_id and
     * note parameters.
     */
    const addNoteHandler=(auction_id,note)=>{
        handleAddNote(auction_id,note)
    }
    return (
        <div className='pb-[200px] pt-2 overflow-auto'>
            <div className="z-10 border-[#DEDEDE] border rounded-lg xl:w-full w-fit" style={{transform: "rotateX(180deg)"}}>
                <table aria-label="Outside Table" className="w-full overflow-auto table-auto" 
                    style={{transform: "rotateX(-180deg)"}}>
                    <thead>
                        <tr className="bg-[#FFFFFF] border border-[#DEDEDE] h-12">
                            <th data-testid="title" scope="col" className="rounded-lg px-4 py-3 min-w-[110px]">
                                <span className="flex items-center text-[16px] font-medium leading-normal text-[#343434]">
                                    Auction ID
                                </span>
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[130px]">
                            </th>
                            <th data-testid="auction_name" scope="col" className=" px-4 py-3 min-w-[140px] flex justify-center">
                                <span className="flex items-center justify-center cursor-pointer w-fit" 
                                    onClick={(e) => {handleSort(e,'title')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1">Name</p>
                                    {currentSortColumn.key==='title' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 " />:
                                            <img src="images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3" />
                                        ):
                                        (<img src="images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3  " />)
                                    }
                                </span>
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[200px]">
                                <span className="flex items-center justify-center" 
                                    onClick={(e) => {handleSort(e,'start_date')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1">
                                        <span className='cursor-pointer'> Start Date</span>
                                    </p>
                                    {currentSortColumn.key==='start_date' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />)
                                    }
                                </span>          
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[200px]">
                                <span className="flex items-center justify-center"
                                    onClick={(e) => {handleSort(e,'end_date')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1">
                                        <span className='cursor-pointer'> End Date</span>
                                    </p>
                                    {currentSortColumn.key==='end_date' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />)
                                    }
                                </span>          
                            </th>
                            <th data-testid="auction_status" scope="col" className=" px-4 py-3 min-w-[180px]">
                                <span className="flex items-center justify-center"
                                    onClick={(e) => {handleSort(e,'status')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1">
                                        <span className='cursor-pointer' >Status</span>
                                    </p>
                                    {currentSortColumn.key==='status' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 " />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />)
                                    }
                                </span>
                            </th>
                            <th scope="col" className=" px-4 py-3 sm:min-w-[20px] min-w-[60px]"> 
                            </th>
                            <th scope="col" className=" px-4 py-3 sm:min-w-[70px] min-w-[50px] "> 
                            </th>
                        </tr>
                    </thead>
                    {/* <!-- empty auction list --> */}
                    {(auctionData.length===0 && isFilterApplied!==true && searchParams.get('keyword')=== null && !loading) &&
                    <tbody>
                        <tr>
                            <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                                <div className="h-[200px] mt-[100px]">
                                    <p className="text-[18px] font-medium  leading-normal text-[#34343">Currently no auctions</p>
                                    {(auction.total_pages===0) &&  
                                    <Link to="/auctions/create"><button className="px-[31px] mt-2 py-[12px] bg-[#282828] 
                                    sm:mx-0 mx-3 text-[#FFFFFF] leading-normal font-medium text-[14px] rounded-[6px] 
                                    hover:bg-[#3F3F45]">
                                        Create auction
                                    </button></Link>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }
                    {/* <!-- empty search  --> */}
                    {(searchParams.get('keyword')!== null && auctionData.length===0 && isFilterApplied===false && !loading) && 
                    <tr>
                        <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                            <div className="h-[180px] mt-[100px]">
                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                    No search results for ‘{keyword}’</p>
                            </div>
                        </td>
                    </tr>}
                    {(searchParams.get('keyword')!== null && isFilterApplied===true && auctionData.length===0 && !loading) && 
                    <tr>
                        <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                            <div className="h-[180px] mt-[100px]">
                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                    No results found</p>
                            </div>
                        </td>
                    </tr>}
                    {/* <!-- empty filter --> */}
                    {(isFilterApplied===true && auctionData.length===0 && searchParams.get('keyword')=== null && !loading ) && 
                    <tr>
                        <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                            <div className="h-[180px] mt-[100px]">
                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                    No results for applied filter</p>
                            </div>
                        </td>
                    </tr>}
                    {loading && <TbodyShimmer/>}
                    {!loading && <tbody className="mt-5">
                        <TableRow auctionData={auctionData}
                            domain={domain} fetchAuctions={fetchAuctions}
                            addNoteHandler={addNoteHandler}
                            cloneHandler={cloneHandler}
                            setMessage={setMessage}
                            setLoader={setLoader}
                            loader={loader}                            
                            isDeleteModalOpen={isDeleteModalOpen}
                            setIsDeleteModalOpen={setIsDeleteModalOpen}
                            handleDelete={handleDelete}
                            handleFetch={handleFetch}
                            isCancelModalOpen={isCancelModalOpen}
                            setIsCancelModalOpen={setIsCancelModalOpen}
                            handleCancel={handleCancel} setErrorMessage={setErrorMessage} />
                    </tbody>}
                </table>
            </div>
        </div>
    )
}
