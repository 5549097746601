import React, { useState } from 'react'
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import SetupSection from './setUpSection';

export default function SetUpAuctionforMobile({isOpen, setIsOpen, auctionData, setEditedAuctionData, 
    handleLogoChange, handleLogoRedirectionChange, text, setText,
    handleLogoDelete, errorMessages, addMenuLink, deleteMenuLink, addDropdownLink,deleteDropdownLink, newMenuLink, 
    setNewMenuLink, isAddingDropdown, setIsAddingDropdown, setMenuModal, menuModal, dropdownLinkError, setDropdownLinkError,
    setAuctionData, startDate,setStartDate,endDate,setEndDate,startHour,setStartHour,startHourShow, 
    setStartHourShow,startMin,setStartMin,startMinShow, setStartMinShow,endHour,setEndHour,endHourShow,setEndHourShow,
    endMin,setEndMin,mobileModal, isMobileModal,
    endMinShow,setEndMinShow,timezoneShow,setTimezoneShow,menuLinkError, setMenuLinkError,
    logoLoader,setLogoLoader,imageLoader,setImageLoader,invalidDate,setInvalidDate,sameTime,setSameTime,isBanner}) {

    
    return (
        <Drawer
            id="mobile-panel-configuration"
            open={isOpen}
            onClose={()=>setIsOpen((prevState) => !prevState)}
            direction='right'
            className={`bg-[#FFFFFF] mobile-setup ${mobileModal ? 'overflow-hidden': 'overflow-auto'} pb-[65px]`}
        >
            <SetupSection auctionData={auctionData}
                setText={setText}
                text={text}
                setEditedAuctionData={setEditedAuctionData}
                invalidDate={invalidDate}
                setInvalidDate={setInvalidDate}
                handleLogoChange={handleLogoChange}
                sameTime={sameTime}
                setSameTime={setSameTime}
                handleLogoRedirectionChange={handleLogoRedirectionChange}
                handleLogoDelete={handleLogoDelete}
                errorMessages={errorMessages}
                addMenuLink={addMenuLink}
                deleteMenuLink={deleteMenuLink}
                addDropdownLink={addDropdownLink}
                deleteDropdownLink={deleteDropdownLink}
                newMenuLink={newMenuLink}
                setNewMenuLink={setNewMenuLink}
                isAddingDropdown={isAddingDropdown} 
                setIsAddingDropdown={setIsAddingDropdown}
                setMenuModal={setMenuModal}
                menuModal={menuModal}
                dropdownLinkError={dropdownLinkError} 
                setDropdownLinkError={setDropdownLinkError}
                setAuctionData={setAuctionData}
                startDate={startDate} 
                setStartDate={setStartDate}
                endDate={endDate} 
                setEndDate={setEndDate}
                startHour={startHour}
                setStartHour={setStartHour}
                startHourShow={startHourShow} 
                setStartHourShow={setStartHourShow}
                startMin={startMin}
                setStartMin={setStartMin}
                startMinShow={startMinShow} 
                setStartMinShow={setStartMinShow}
                endHour={endHour}
                setEndHour={setEndHour}
                endHourShow={endHourShow}
                setEndHourShow={setEndHourShow}
                endMin={endMin}
                setEndMin={setEndMin}
                endMinShow={endMinShow}
                setEndMinShow={setEndMinShow}
                timezoneShow={timezoneShow}
                setTimezoneShow={setTimezoneShow}
                menuLinkError={menuLinkError} 
                setMenuLinkError={setMenuLinkError}
                logoLoader={logoLoader}
                setLogoLoader={setLogoLoader}
                imageLoader={imageLoader}
                mobileModal={mobileModal}
                isMobileModal ={isMobileModal}
                setImageLoader={setImageLoader}
                isBanner/>
        </Drawer>
    )
}
