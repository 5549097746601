import React from 'react'
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle'

export default function LotListingHeader({auctionData}) {
    return (
        <div className="sm:mt-10 mt-3 flex justify-between flex-wrap items-center">
            <p className="text-[16px] leading-normal font-medium text-[#343434]" 
                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}} > <span>0 </span> lots</p>
            <div className="flex ml-auto  flex-wrap items-center">
                {/* <!-- search  --> */}
                <div className="relative pr-3 py-[12px]">
                    <input autoComplete='off' type="text" disabled className="text-[14px] relative w-[270px] 
                            h-[32px] pl-8 pr-3  bg-[#fff] border border-[#A1A1A9] placeholder:text-[#A1A1A9] 
                            focus:border-[#343434] outline-none font-medium leading-normal text-[#4A4A4A]" placeholder="Search" />
                    <img src="/images/search-icon-large.svg" alt="search-icon" 
                        className="absolute top-5 cursor-default left-2.5" loading="lazy" />
                </div>
                {/* <!-- filter --> */}
                <div className="relative mr-2.5 cursor-default">
                    <button id="filter" className="text-[14px] cursor-default  relative text-[#52525A] 
                            border w-[83px] h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal 
                            font-medium pr-[20px] pl-[36px]" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                        Filter
                    </button>
                    <img src="/images/filter-icon.svg" alt="filter-icon" className="absolute top-[10px] left-2.5" 
                        loading="lazy" />
                </div>
                {/* <!-- export button --> */}
                <div className="relative cursor-default">
                    <button id="sort" type="button" className="text-[14px] cursor-default text-[#52525A] 
                            border w-[80px] h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal font-medium 
                            pr-[5px] pl-[34px]" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                        Sort
                    </button>
                    <img src="/images/export-icon.svg" alt="export-icon" className="absolute top-[7px] left-2.5" loading="lazy" />
                </div>
            </div>
        </div>    
    )
}
