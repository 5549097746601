/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable max-len */
import { useCallback, useEffect, useState } from "react"
import { dataService } from "../../../../../services/dataService";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Paginate from "./paginate";
import Table from "./table";
import { useDispatch, useSelector } from "react-redux";
import { setBids } from "../../../bidsSlice";
import { io } from "socket.io-client";
import config from "../../../../../config";
import Custom404 from "../../../../../404";
import { Auth } from "aws-amplify";

export default function ViewBidsOfLot(){

    const {id,uid, lotId} = useParams();
    const [lot, setLot]=useState({});
    const [auctionData,setAuctionData]=useState({})
    const [searchParams, setSearchParams] = 
        useSearchParams({ page:  1, limit: 10});
    const [currentSortColumn, setCurrentSortColumn] = useState({key:null, sort_order: 'asc'});
    const [bidsInfo, setBidsInfo]=useState([])
    const [pagination, setPagination]=useState({})
    const [loading, setLoading] = useState(true)
    const bids=useSelector((state)=>state.bidsSlice);
    const dispatch = useDispatch();
    const location=useLocation()
    const Navigate=useNavigate()
    const [socketConn,setSocket]=useState(null);
    const [isAuctionInvalid , setIsAuctionInvalid] = useState(false);
    const [topBid, setTopBid]=useState("");
    const [bidders, setBidders]=useState("");
    const [topBidder, setTopBidder]=useState("");
    const [underBidder, setUnderBidder]=useState("");
    const [bidsLength, setBidsLength]=useState(0);


    useEffect(()=>{

        setTopBid(pagination?.top_bid ? pagination?.top_bid : "0");
        setBidders(pagination?.bidders ? pagination?.bidders : "0")
        setTopBidder(pagination.top_bidder=="" ? "-" : pagination.top_bidder?.trim())
        setUnderBidder(pagination.under_bidder)
        setBidsLength(pagination.total_records);

        if(bids.bids && Object.keys(bids.bids).length > 0){

            if(bids.bids[lot?._id]){
                const data=bids.bids[lot._id];
                setTopBid(data.top_bid);
                setBidders(data.bidders);
                setTopBidder(data.top_bidder==" " ? "-" : data.top_bidder?.trim());
                setUnderBidder(data.under_bidder);
                setBidsLength(data.all_bidders.length);
            }
        }

    },[bids.bids, pagination])

    const [refreshed, setRefreshed] = useState(false);

    const socketConnection=async(lot)=>{
        const idToken = await Auth.currentSession()

        if(socketConn==null){
            const socket= io(config.SOCKETURL, {
                transports: ['websocket'],
                auth: {
                    token: idToken.getIdToken().getJwtToken(),
                    type: "seller"
                },
                reconnection: true
            })

            setSocket(socket);

            socket.on('authorization', (message)=>{
                if(message.success_status==true){
                    socket.emit('joinBidRoom', lot?._id);
                }
            })

            socket.on('placeBid',(message)=>{

                socket.on('sellerListBidHistory', (message)=>{
                    console.log(message, 'sellerListBidHistory')
                    if(message?.response.all_bidders?.length>0  && message?.response.all_bidders[0]?.lot_id !== undefined && message?.response.all_bidders[0]?.lot_id == lot._id){
                        const newBidsList = { [message?.response.all_bidders[0].lot_id]: message.response };
                        dispatch(setBids(newBidsList));
                    }                    
                })
            })

        }
    }

    const getLots=async()=>{
        let endpoint = `?auction_id=${uid}&per_page=${lotId}`

        const response = await dataService.LotList(endpoint);
        
        if(response.error==false){
            const lots= response.data.data;
            setLot(lots.find((lot)=>lot.lot_number==lotId));
            socketConnection(lots.find((lot)=>lot.lot_number==lotId))

        }else{
            setIsAuctionInvalid(true)
        }
        
    }

    const handleView = async () => {

        const data = await dataService.viewAuction(`?auction_id=${id}`)
        if(data.error===false){
            setAuctionData(data.data.data)
        }
        else{
            setIsAuctionInvalid(true)
        }
    }

    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParams)
        setSearchParams({ ...params, page: pageNum.selected + 1 })
    }


    const fetchBidsHandler = useCallback(async (lot) =>{
        let endpoint = `page=${Number(searchParams.get('page'))}&limit=${10}&sort_by=created_at&sort_order=desc`
        if(searchParams.get('sort_by')!==null){
            endpoint += `&sort_by=${String(searchParams.get('sort_by'))}&sort_order=${String(searchParams.get('sort_order'))}`
        }
        // if(searchParams.get('keyword')!==null){
        //     endpoint += `&search_keyword=${searchParams.get('keyword')}`
        // }
        const response = await dataService.getAllBidsOfLot(lot._id,endpoint);
        if(response.error){
            setLoading(false)
            setBidsLength(0)
        }
        else{
            setBidsInfo(response.data.data);
            dispatch(setBids({}))
            setPagination(response.data.pagination);
            setLoading(false)
        }
    }, [location, searchParams])

    /**
     * The function `handleSort` is used to update the search parameters based on the sorting key and
     * order.
     */
    const handleSort = (e, key) => {
        let params = Object.fromEntries(searchParams);
        if(searchParams.get('sort_by') === null) {
            params['sort_by'] = `${key}`
            params['sort_order'] = `asc`
            setCurrentSortColumn({
                key: key,
                sort_order: 'asc'
            });
        }else {
            if(searchParams.get('sort_by').includes(key))
            {
                if(searchParams.get('sort_order')==='asc') {
                    params['sort_order'] = `desc`
                    setCurrentSortColumn({
                        key: key,
                        sort_order: 'desc'
                    });
                }else{
                    params['sort_order'] = `asc`
                    setCurrentSortColumn({
                        key: key,
                        sort_order: 'asc'
                    });
                }
            }
            else {
                params['sort_by'] = key
                params['sort_order'] = `asc`
                setCurrentSortColumn({
                    key: key,
                    sort_order: 'asc'
                });
            }
        }
        setSearchParams({ ...params})
    }

    useEffect(()=>{
        handleView()
        getLots()
    },[])

    useEffect(()=>{
        if(lot){
            if(Object.keys(lot).length>0){
                fetchBidsHandler(lot)
            }
        }
    },[searchParams,lot])

    

    return(
        <> 
            {!isAuctionInvalid && <div className="px-[41px] pb-[100px]">
                <div className="flex justify-between items-center mt-8 sm:flex-row flex-col ">
                    <div className='sm:w-auto w-full'>
                        {auctionData.status===undefined?
                            <p className='w-[70px]'>
                                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4 min-w-[70px]">
                                    <Skeleton count={1} className='h-[30px] rounded-[42px] w-[70px]'/>
                                </SkeletonTheme>
                            </p>:
                            <span data-testid="status" 
                                className={`relative py-[8px] mb-3 px-[25px] text-[12px] 
                                ${auctionData.status==='Draft'?'bg-[#D4D4D8] text-[#343434]':
            auctionData.status==='Published'?'bg-[#E3C79D] text-[#B98B47]':
                auctionData.status==='Cancelled'?'text-[#984848] bg-[#CA8787]':
                    auctionData.status==='Accepting bids'?
                        'bg-[#A8D9C8] text-[#489892]':'bg-[#DDD1D5] text-[#343434]'} 
                    rounded-[42px] text-end leading-normal font-semibold`}>
                                {auctionData.status==='Accepting bids' &&
                                <img className="absolute top-[12px] left-3 " src="/images/green-dot.svg" alt="img" />}
                                {auctionData.status}</span>
                        }
                        {lot?.lot_number!==undefined ?<p className="sm:text-[34px] text-[20px]  font-medium leading-normal text-[#343434] sm:pt-2 pt-3.5 hover:underline cursor-pointer" onClick={()=>Navigate(`/auctions/${id}/${uid}/lots`)}>
                            <span id="lot_number" >{lot.lot_number}</span>.<span id="lot_title" >{lot.title1===''?'Lot title goes here':lot.title1}</span></p> :<p className='md:w-[300px] w-full mt-3 animate-pulse h-7 bg-[#dbd9d9f3]'></p>}
                    </div>
                    <div/>
                </div>

                {Object.keys(auctionData).length > 0 && <div className="flex ss:flex-nowrap flex-wrap mb-[18px] mt-6">
                    <div className="ss:w-1/2 w-full min-h-[99px] lg:px-10 px-7 pt-3 ss:mb-0 mb-2.5 rounded-[20px] bg-[#DDD1D5]">
                        <p className="text-[12px] leading-normal font-semibold text-[#52525A]">TOP BID</p>
                        {!loading ? <p className="xmd:text-[30px] md:text-[20px] text-[22px] lg:text-[34px] leading-normal font-bold text-[#282828] break-all">{Number(topBid).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                            style: 'currency',
                            currency: auctionData.currency,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}</p> : <p className='md:w-[30%] w-full animate-pulse h-10 bg-[#dbd9d9f3]'></p>}
                    </div>
                    <div className=" ss:w-1/2 w-full min-h-[99px] px-10 pt-3 ss:ml-5 ss:mb-0 mb-2.5 rounded-[20px] bg-[#B8D5DB]">
                        <p className="text-[12px] leading-normal font-semibold text-[#52525A]">BIDDERS</p>
                        {!loading ? <p className="xmd:text-[30px] md:text-[20px] text-[22px] lg:text-[34px] leading-normal font-bold text-[#282828]">{bidders}</p> : <p className='md:w-[30%] w-full animate-pulse h-10 bg-[#dbd9d9f3]'></p>}
                    </div>
                </div>}
                {bidsLength > 0 && <div className="border border-[#DEDEDE] rounded-[10px] md:flex py-[15px] px-[23px] mb-[36px]" >
                    <div className="flex md:w-1/2 w-full items-center">
                        <div className="text-[#489892] bg-[#A8D9C8] px-4 py-2 text-[12px] rounded-[42px]" >Top bidder</div>
                        <p className="ml-3" >{topBidder}</p>
                    </div>
                    <div className="flex md:w-1/2 w-full items-center md:border-l md:mt-0 mt-3 border-[#DEDEDE]">
                        <div className="text-[#B98B47] bg-[#E3C79D] px-4 py-2 text-[12px] rounded-[42px] md:ml-[30px]" >Underbidder</div>
                        <p className="ml-3" >{underBidder?.name ? (underBidder?.name== "" ? "-" : underBidder?.name) : "-"}</p>
                    </div>
                </div>}
                <p data-testid='lot-count' className="flex text-[18px] leading-normal font-medium text-[#343434] mb-[15px]"> 
                    <span className="mr-1" >{bidsLength} </span> 
                    {bidsLength==1 ? 'Bid' : 'Bids'} <img src="/images/info-icon.svg" className="w-[16px] ml-4 cursor-pointer" />
                </p>
                <Table bidsInfo={bidsInfo} bids={bids.bids==undefined ? [] : bids?.bids[lot._id]} handleSort={handleSort} currentSortColumn={currentSortColumn} auctionData={auctionData} 
                    pagination={pagination} loading={loading} topBid={topBid} topBidder={topBidder} underBidder={underBidder} bidsLength={bidsLength} searchParams={searchParams} />

                <Paginate totalPageNumber={Math.ceil((bids.bids!==undefined && bids?.bids[lot._id]?.all_bidders?.length > 0) ? Number(bids?.bids[lot._id]?.all_bidders.length)/10 : pagination.total_records/10)}
                    selectedPageNumber={Number(searchParams.get('page')) || 1}
                    handleOnClickPageNumber={handleOnClickPageNumber} />
            </div>}
            {isAuctionInvalid && <Custom404 />}
        </>
    )
}