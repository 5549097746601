import { motion } from 'framer-motion'
import { useEffect, useState } from 'react';
import Button from '../../../CommonComponent/button';

export default function SetNewPasswordForm(props){

    const { clickHandler,confirmPassword, password, setShowConfirmPassword,showPassword,success,setPasswordError,
        showConfirmPassword,setShowPassword, handlePasswordChange,passwordValid, passwordError, loader }=props;
    const [showFormate, setShowFormate] = useState(true);

    useEffect(()=>{
        if(password.length===0){
            setShowFormate(false);
        }
        else if(passwordValid.length===false || passwordValid.uppercase===false || passwordValid.lowercase===false 
            || passwordValid.number===false || passwordValid.symbol===false){
            setShowFormate(true);
        }
        if(passwordValid.length===true && passwordValid.lowercase===true && passwordValid.number===true && 
            passwordValid.symbol===true && passwordValid.uppercase===true){
            setShowFormate(false);
        }if(password.length===0 && confirmPassword.length===0){
            setPasswordError(null);
        }

    },[password,confirmPassword])

    return(
        <>
            <div className="xl:w-[30%] lg:w-[35%] md:w-[45%]  sm:w-[60%] w-full  bg-white xl:mt-14  mt-10 
                    md:float-right lg:mr-20 md:mr-5 mx-auto rounded-[10px] h-fit new-pswd-prompt">
                <div className="pl-8 pr-4 pt-[34px] pb-8">
                    <p className="text-[22px] font-medium text-center leading-normal">Set a new password</p>
                    <p className="text-lg font-medium text-[#A1A1A9] text-center leading-normal">
                        Sign in to your Seller Portal</p>
                    <div className="xl:h-customh6 sm:h-customh5 h-customh7 overflow-y-auto overflow-x-hidden 
                            customScrollbar  pr-4 w-full">
                        <form id="new-password" action="#" autoComplete="off" className="mt-2">
                            <div className=" relative flex flex-col">
                                <label htmlFor="newPassword" className={`font-medium text-[14px] leading-normal
                                    ${passwordError ? 'text-[#984848]':'text-[#343434]' }`}>
                                    New Password</label>
                                <input type={showPassword?"text":"password"} id="password" 
                                    className={`border w-full h-10 
                                        pswd-border-login outline-none focus:border-[#343434] pl-2 pr-7 text-[14px] 
                                        text-[#343434] font-medium leading-normal
                                        ${passwordError ? 'border-[#984848]': 'border-[#A1A1A9]'}`}
                                    value={password} onChange={(e)=>handlePasswordChange(e)}/>
                                <img src={showPassword?"images/eye-icon-open.svg":"images/eye-icon-close.svg"} loading="lazy" 
                                    className="absolute right-[10px] eye-close top-[36px] cursor-pointer" alt="eye-icon" 
                                    onClick={()=>setShowPassword(!showPassword)} />
                            </div>
                            {password.length>0 && showFormate &&
                            <motion.div initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }} 
                                transition={{
                                    ease: "linear",
                                    duration: 0.5,
                                    x: { duration: 1 },
                                    delay: 0.1
                                }}
                                className="error-condition">
                                <div className="flex mt-2">
                                    <img src={passwordValid.length ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                        alt="img" loading="lazy" /> 
                                    <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.length ? 'text-[#489892]' : 'text-[#717179]'}`}>Between 8 and 16 characters</p>
                                </div>
                                <div className="flex mt-1.5">
                                    <img 
                                        src={passwordValid.uppercase ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                        alt="img" loading="lazy" /> 
                                    <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.uppercase ? 'text-[#489892]' : 'text-[#717179]'}`}>Uppercase letter</p>
                                </div>
                                <div className="flex mt-1.5">
                                    <img 
                                        src={passwordValid.lowercase ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                        alt="img" loading="lazy" /> 
                                    <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.lowercase ? 'text-[#489892]' : 'text-[#717179]'}`}>Lowercase letter</p>
                                </div>
                                <div className="flex mt-1.5">
                                    <img src={passwordValid.number ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                        alt="img" loading="lazy" /> 
                                    <p className={`text-[10px] font-medium pl-1.5 
                                    ${passwordValid.number ? 'text-[#489892]' : 'text-[#717179]'}`}>Number</p>
                                </div>
                                <div className="flex mt-1.5">
                                    <img src={passwordValid.symbol ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                        alt="img" loading="lazy" /> 
                                    <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.symbol ? 'text-[#489892]' : 'text-[#717179]'}`}>Symbol</p>
                                </div>
                            </motion.div>}

                            <div className="mt-2 relative flex flex-col">
                                <label htmlFor="confirmPassword" className={`font-medium text-[14px] leading-normal 
                                        ${passwordError ? 'text-[#984848]':'text-[#343434]' } pswd-label-login`}>
                                    Confirm Password</label>
                                <input type={showConfirmPassword?"text":"password"} id="pswd" 
                                    className={`border w-full h-10 
                                        pswd-border-login outline-none focus:border-[#343434] pl-2 pr-7 text-[14px] 
                                        text-[#343434] font-medium leading-normal 
                                        ${passwordError ? 'border-[#984848]': 'border-[#A1A1A9]'}`}
                                    value={confirmPassword} onChange={(e)=>handlePasswordChange(e)}/>
                                <img src={showConfirmPassword?"images/eye-icon-open.svg":"images/eye-icon-close.svg"} 
                                    className="absolute right-[10px] eye-close top-[36px] cursor-pointer" alt="eye-icon" 
                                    onClick={()=>setShowConfirmPassword(!showConfirmPassword)} loading="lazy" />
                                {passwordError && 
                                <motion.p  className="font-medium pt-1 incrct-user-err text-[#984848] 
                                text-[10px] leading-normal">{passwordError}
                                </motion.p>}
                            </div>
                            <Button onclick={(e)=>clickHandler(e)} loader={loader}
                                disabled={password===''|| confirmPassword==='' || password!==confirmPassword ||
                                    (!passwordValid.length || !passwordValid.lowercase || !passwordValid.uppercase || 
                                    !passwordValid.number || !passwordValid.symbol) } text="Set Password" />
                        </form>
                    </div>
                </div>    
            </div> 
            
        </>
    );
}