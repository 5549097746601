/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useEffect, useState } from 'react'
import SalesTabHeader from './salesTabHeader'
import SalesTable from './salesTable'
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { dataService } from '../../../../services/dataService';
import ToastMeassage from '../../../../CommonComponent/toastMeasseage';
import Paginate from './pagination';

export default function Sales() {
    const outletContext = useOutletContext();
    const { auctionData } = outletContext;
    const [sales,setSales]=useState([])
    const [salesInfo,setSalesInfo] = useState([])
    const [searchParam, setSearchParam] = 
    useSearchParams({ page:  1, per_page: 10 });
    const [loading, setLoading] = useState(true)
    const [isSearched, setIsSearched] = useState(false);
    const [searchValue, setSearchValue] = useState(searchParam.get('keyword')===null? 
        '':decodeURIComponent(searchParam.get('keyword')))
    const [showExoprtToastMsg, setShowExportToastMsg]=useState(false)
    const [currentSortColumn, setCurrentSortColumn] = useState({key:null, order: 'asc'});
    const [isFilterApplied, setIsFilterApplied]=useState((searchParam.get('type')!==null||
    searchParam.get('status')!==null||searchParam.get('start_date')!==null|| searchParam.get('end_date')!==null)?true:false);

    /* The `fetchHandler` function is a callback function that is used to fetch sales data based on the
    search parameters. It is called whenever there is a change in the `searchParam` state. */
    const fetchHandler=useCallback(async(id)=>{
        setLoading(true)
        let endpoint = `?auction_id=${id}&per_page=10&page=${searchParam.get('page')!==null?searchParam.get('page'):1}`
        if(searchParam.get('sort')!==null){
            endpoint += `&sort_by=${String(searchParam.get('key'))}&sort_order=${String(searchParam.get('order'))}`
        }
        if(searchParam.get('keyword')!==null){
            endpoint += `&search_keyword=${searchParam.get('keyword')}`
        }
        if(searchParam.get('type')!==null){
            endpoint += `&payment_type=${searchParam.get('type')}`
        }
        if(searchParam.get('status')!==null){
            endpoint += `&payment_status=${searchParam.get('status')}`
        }
        if(searchParam.get('start_date')!==null){
            endpoint += `&start_date=${Math.floor(new Date(searchParam.get('start_date')).getTime() / 1000)}`
        }
        if(searchParam.get('export')!==null){
            endpoint += `&export=1`
        }
        if(searchParam.get('end_date')!==null){
            const endDateTimestamp = 
            Math.floor(new Date(searchParam.get('end_date')).getTime() / 1000) + 23 * 60 * 60 + 59 * 60 + 59;
            endpoint += `&end_date=${endDateTimestamp}`;
        }
        const response = await dataService.SalesList(endpoint);
        if(response.error===false){
            setSales(response.data.data);
            setLoading(false)
            setSalesInfo(response.data);
            setIsSearched(false)
            if(response.data?.csv_url !== undefined){
                window.open(response.data.csv_url, '_blank');
                let params = Object.fromEntries(searchParam);
                delete params.export
                setSearchParam({...params})
            }
        }
    },[searchParam])

    /**
     * The function `handleSort` is used to update the search parameters based on the sorting key and
     * order.
     */
    const handleSort=(e,key)=>{
        let params = Object.fromEntries(searchParam);
        if(searchParam.get('sort') === false || searchParam.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `asc`
            setCurrentSortColumn({
                key: key,
                order: 'asc'
            });
        }else {
            if(searchParam.get('key').includes(key)) 
            {
                if(searchParam.get('order')==='asc') {
                    params['order'] = `dsc`
                    setCurrentSortColumn({
                        key: key,
                        order: 'dsc'
                    });
                }else{
                    params['order'] = `asc`
                    setCurrentSortColumn({
                        key: key,
                        order: 'asc'
                    });
                }
            }
            else {
                params['key'] = key
                params['order'] = `asc`
                setCurrentSortColumn({
                    key: key,
                    order: 'asc'
                });
            }
        }
        setSearchParam({ ...params})
    }

    /**
     * The function updates the search parameters with a new page number when a page number is clicked.
     */
    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParam)
        setSearchParam({ ...params, page: pageNum.selected + 1 })
    }

    /**
     * The function `handleSearch` takes in an event, a key, and a value, and updates the search
     * parameters based on the key-value pair.
     */
    const handleSearch=(e, key, value)=>{
        e.preventDefault();
        let params = Object.fromEntries(searchParam);
        params.page = 1
        if(key === 'keyword') {
            params[key] = encodeURIComponent(value); 
        }else {
            params[key] = value
        }
        if(params['keyword'] === ''){ 
            delete params.keyword
            setIsSearched(true)        
        }
        setSearchParam({ ...params})
    }

    /**
     * The function `handleFilter` takes in three parameters (status, fromDate, toDate) and updates the
     * search parameters based on the values of these parameters.
     */
    const handleFilter=(type, status, fromDate, toDate)=>{
        let params = Object.fromEntries(searchParam);
        params['page'] = 1
        setSearchParam({ ...params, page: 1 })
        if(type==='' && status==='' && fromDate==='' && toDate===''){
            return;
        }
        if(type.status!==undefined){
            params['type']=type.status.replaceAll("%20", ' ');
            setIsFilterApplied(true);
        }else{
            delete params.type;
        }
        if(status.status!==undefined){
            params['status']=status.status.replaceAll("%20", ' ');
            setIsFilterApplied(true);
        }else{
            delete params.status;
        }
        if(fromDate!==''){
            params['start_date']=fromDate;
            setIsFilterApplied(true);
        }else{
            delete params.start_date
        }
        if(toDate!==''){
            params['end_date']=toDate;
        }else{
            delete params.end_date
        }
        if (params['status'] === undefined) delete params.status;
        setSearchParam({...params})
    }
    

    /**
     * The function `getFilter` takes in four parameters and calls the `handleFilter` function with
     * those parameters.
     */
    const getFilter=(type, status, fromDate, toDate)=>{
        handleFilter(type, status, fromDate, toDate)
    }

    /**
     * The function handleChangePaymentInfo takes one parameter and calls a api endpoint.
     */
    const handleChangePaymentInfo = async (orderId, {paymentType, paymentStatus}) => {
        const response = await dataService.changePaymentInfo(orderId, {paymentType, paymentStatus})
        if(!response.error && Object.keys(auctionData).length > 0){
            fetchHandler(auctionData._id)
        }
    }

    useEffect(()=>{
        if(Object.keys(auctionData).length > 0){
            fetchHandler(auctionData._id)
        }
    },[fetchHandler,auctionData])

    return (
        <div
            className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
            id="tabs-profile4"
            role="tabpanel"
            data-te-tab-active
            aria-labelledby="tabs-profile-tab4">
            {showExoprtToastMsg && <ToastMeassage message={'Export complete'}/>}
            <SalesTabHeader handleSearch={handleSearch} searchParam={searchParam} loading={loading} 
                setShowExportToastMsg={setShowExportToastMsg}
                searchValue={searchValue} setSearchValue={setSearchValue} salesInfo={salesInfo} 
                auctionData={outletContext.auctionData} getFilter={getFilter}
                sales={sales} isFilterApplied={isFilterApplied} setIsFilterApplied={setIsFilterApplied} 
                setSearchParam={setSearchParam} />
            <SalesTable sales={sales} fetchHandler={fetchHandler} handleSearch={handleSearch} handleSort={handleSort} 
                currentSortColumn={currentSortColumn} searchParam={searchParam} 
                searchValue={searchValue} setSearchValue={setSearchValue} loading={loading} isSearched={isSearched}
                auctionData={outletContext.auctionData} salesInfo={salesInfo} isFilterApplied={isFilterApplied}
                onChangePaymentInfo={handleChangePaymentInfo}
            />
            {(salesInfo.current_page <= salesInfo.total_pages) &&
            <Paginate totalPageNumber={Math.ceil(salesInfo.total_records/10)}
                selectedPageNumber={Number(searchParam.get('page')) || 1}
                handleOnClickPageNumber={handleOnClickPageNumber} />}
        </div>
    )
}
