import React from 'react'
import { Triangle } from  'react-loader-spinner'

export default function CommonLoader() {
    return (
        <div className="flex justify-center items-center h-screen">
            <Triangle
                height="80"
                width="80"
                color="#000"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>  
    )
}
