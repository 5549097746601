export default function ToastMeassage ({message}){
    return(
        <div className="mt-[15px] px-5 success-msg-csv py-3 bg-[#A8D9C8] border border-[#489892] rounded-[6px]">
            <div className="flex items-center">
                <img src="/images/verify-account.svg" alt="img" className="pr-3" />
                <div>
                    <p className="text-[12px] leading-normal font-bold text-[#343434]">{message}</p>
                </div>
            </div>
        </div>
    )
}