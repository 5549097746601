import { Dialog } from "@headlessui/react"
import { Oval } from "react-loader-spinner"

export default function AcceptBidders(props){

    const {handleBidders, setAcceptBidderModal, acceptBidderModal, loader,id }=props;
    return(
        <Dialog open={acceptBidderModal.isModalOpen} 
            onClose={() => setAcceptBidderModal({isModalOpen: false, type: '', id: '', auction_id: ''})}
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-[#282828cc]"
            id="exampleModalXl3"
            tabIndex="-1"
            aria-labelledby="exampleModalXlLabel"
            aria-modal="true"
            role="dialog">
            <Dialog.Panel
                data-te-modal-dialog-ref
                className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] 
                    items-center mx-3 transition-all duration-300 ease-in-out min-[640px]:mx-auto 
                    min-[640px]:mt-7 min-[640px]:min-h-[calc(100%-3.5rem)] min-[640px]:max-w-[520px]">
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white 
                    bg-clip-padding text-current shadow-lg outline-none">
                    <Dialog.Title
                        className="flex flex-shrink-0 items-center justify-end rounded-t-md p-3">
                        {/* <!--Close button--> */}
                        <button onClick={()=>setAcceptBidderModal({isModalOpen: false, type: '', id: '', auction_id: ''})}
                            type="button"
                            className="box-content rounded-none border-none"
                            data-te-modal-dismiss
                            aria-label="Close">
                            <img src="/images/CloseButton-modalcontent.svg" alt="img" />
                        </button>
                    </Dialog.Title>
                    {/* <!--Modal body--> */}
                    <div className="relative">
                        <p className=" sm:px-[97px] px-10 text-center pb-4 leading-normal text-[16px] text-[#343434]
                            font-medium" id="confirm-msg" >
                            Are you sure you would like to {acceptBidderModal.type} this bidder?</p>
                        <div className="flex justify-center pb-[47px]">
                            <button type='button' 
                                onClick={()=>setAcceptBidderModal({isModalOpen: false, type: '', id: '', auction_id:''})} 
                                id="cancel-delete"
                                className="px-[31px] py-[12px] border esm:w-[135px] w-[100px] border-[#A1A1A9] 
                            bg-[#fffff]  text-[#282828] leading-normal font-medium text-[14px] rounded-[6px]"  
                                data-te-modal-dismiss
                                aria-label="Close">
                                Cancel
                            </button>
                            <a className="ml-3 export-btn">
                                <button data-testid='confirm_approve' 
                                    type='button' onClick={(e)=>{ e.preventDefault(); 
                                        handleBidders(acceptBidderModal.auction_id,acceptBidderModal.id)}}
                                    className="flex justify-center items-center px-[31px] esm:w-[135px] w-[100px] py-[12px] 
                                    bg-[#282828] text-[#FFFFFF] leading-normal font-medium text-[14px] rounded-[6px] 
                                    disabled:bg-[#A1A1A9]" 
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                    disabled={loader}>
                                    Confirm
                                    {loader&& 
                                    <Oval
                                        height={20}
                                        width={50}
                                        color="#FFFFFF"
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#000000"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />
                                    }
                                </button>
                            </a> 
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}