import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

function PricingShimmer() {
    return (
        <div className="sm:pr-5 pr-10 sm:py-8 pt-8 pb-20 overflow-x-hidden overflow-y-auto">
            <p className="text-[34px] sm:px-10 px-5 font-medium leading-normal text-[#343434]">Pricing</p>
            <div className="flex flex-wrap my-[27px] overflow-hidden mx-5 w-full">  
                <div className="border-t-[7px] border border-t-[#B8D5DB] xlgx:w-[21%] sm:w-[42%]  mb-7 rounded-[8px] 
                mx-5 w-full border-[#DEDEDE]"> 
                    <div className=" border-b border-[#DEDEDE] px-4 text-center pb-[18px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] min-w-[300px] pt-4 mt-2.5 mb-10' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] max-w-[115px] pt-4 mb-[18px] mx-auto' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={4} className='max-h-[5px] max-w-[200px] pb-0' />
                        </SkeletonTheme>
                    </div>
                    <ul className='py-[25px] px-5'>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={9} className='h-[11px] max-w-[250px] py-[2px]' />
                        </SkeletonTheme>
                    </ul>
                </div>
                <div className="border-t-[7px] border border-t-[#EAEDE4] xlgx:w-[21%] sm:w-[42%]  mb-7 rounded-[8px] 
                mx-5 w-full border-[#DEDEDE]"> 
                    <div className=" border-b border-[#DEDEDE] px-4 text-center pb-[18px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] min-w-[300px] pt-4 mt-2.5 mb-10' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] max-w-[115px] pt-4 mb-[18px] mx-auto' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={4} className='max-h-[5px] max-w-[200px] pb-0' />
                        </SkeletonTheme>
                    </div>
                    <ul className='py-[25px] px-5'>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={9} className='h-[11px] max-w-[250px] py-[2px]' />
                        </SkeletonTheme>
                    </ul>
                </div>
                <div className="border-t-[7px] border border-t-[#DDD1D5] xlgx:w-[21%] sm:w-[42%]  mb-7 rounded-[8px] 
                mx-5 w-full border-[#DEDEDE]"> 
                    <div className=" border-b border-[#DEDEDE] px-4 text-center pb-[18px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] min-w-[300px] pt-4 mt-2.5 mb-10' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] max-w-[115px] pt-4 mb-[18px] mx-auto' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={4} className='max-h-[5px] max-w-[200px] pb-0' />
                        </SkeletonTheme>
                    </div>
                    <ul className='py-[25px] px-5'>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={9} className='h-[11px] max-w-[250px] py-[2px]' />
                        </SkeletonTheme>
                    </ul>
                </div>
                <div className="border-t-[7px] border border-t-[#ECD6C7] xlgx:w-[21%] sm:w-[42%]  mb-7 rounded-[8px] 
                mx-5 w-full border-[#DEDEDE]"> 
                    <div className=" border-b border-[#DEDEDE] px-4 text-center pb-[18px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] min-w-[300px] pt-4 mt-2.5 mb-10' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[33px] max-w-[115px] pt-4 mb-[18px] mx-auto' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={4} className='max-h-[5px] max-w-[200px] pb-0' />
                        </SkeletonTheme>
                    </div>
                    <ul className='py-[25px] px-5'>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={9} className='h-[11px] max-w-[250px] py-[2px]' />
                        </SkeletonTheme>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default PricingShimmer;