/* eslint-disable security/detect-unsafe-regex */
import { useEffect, useState } from "react";
import {currencySymbolMapping} from '../../../../CommonComponent/staticValues'
import config from "../../../../config";
import NavBar from "./navBar";
import Footer from "./footer";
import { selectFontStyle } from "../../../../CommonComponent/SelectFontStyle";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useParams } from "react-router-dom";
import { AuctionDescription } from '../../description';

export default function PreviewSection(props){

    const { lotData, auctionData }=props;
    const { id,lotNumber }=useParams();
    const [startDate,setStartDate] = useState('')
    const [startMin,setStartMin] = useState('')
    const [endDate,setEndDate] = useState('')
    const [endMin,setEndMin] = useState('')
    const featuredImage=lotData.images.find((image) => image.featured)
    const otherImages=lotData.images.filter((image)=>image.featured===false);
    const shippingDetails=lotData.shipping_details.replace(/\n/g, '<br/>');
    const [updatedImages,setUpdatedImages]=useState([])

    useEffect(() => {
        if(lotData.images.length>0){
            if(otherImages.length==0){
                setUpdatedImages([featuredImage])
            }else if(otherImages.length==1){
                setUpdatedImages([
                    otherImages[0], // First unfeatured image
                    featuredImage,  // Rest of the unfeatured images
                ]);
            }else{
                setUpdatedImages([
                    otherImages[0], // First unfeatured image
                    featuredImage,      // Featured image
                    ...otherImages?.slice(1), // Rest of the unfeatured images
                ]);
            }
            
        }else{
            setUpdatedImages([])
        }
    }, [lotData]);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 50
        },
        tablet: {
            breakpoint: { max: 1024, min: 400 },
            items: 2,
            paritialVisibilityGutter: 80
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1
        }
    };


    /* The above code is a React useEffect hook that is used to format and set the start and end dates
    for an auction. */
    useEffect(()=>{
        const isSafari = navigator.userAgentData && navigator.userAgentData.brands.some(brand => brand.brand === 'Safari');

        const start_date = new Date(auctionData.start_date);
        const end_date = new Date(auctionData.end_date)
        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            // timeZoneName: "short"
        };
        const formatter = new Intl.DateTimeFormat("en-GB", options);
        const formattedDate = formatter.format(start_date);
        const formattedEndDate = formatter.format(end_date);
        const [date1, time] = formattedDate.includes('at') ? formattedDate.split("at ") : formattedDate.split(", ");
        const [date2, time1] = formattedEndDate.includes('at') ? formattedEndDate.split("at ") : formattedEndDate.split(", ");
        setStartDate(date1.includes('1970')?'':date1)
        setStartMin(time)
        setEndDate(date2.includes('1970')?'':date2)
        setEndMin(time1)
        // setTimeout(() => {
        //     setLoading(false)
        // }, 500);
    },[])


    return(
        <div 
            className="md:mt-9 mt-5 md:mx-10 mx-5 w-full overflow-x-hidden bg-[#FFF] rounded-t-lg 
                    h-fit xl:w-[75%] lg:w-[70%] md:w-[60%]"
            style={{backgroundColor: auctionData.content_area['background_color'],color: auctionData.content_area['text_color']}}>
            {/* <!-- Main navigation container --> */}
            <NavBar auctionData={auctionData} />
            <div className=" mt-5 mx-auto pb-[13px] border-[#D4D4D8]">
                <p className="text-[34px] leading-normal font-medium py-3 text-center"
                    style={{fontFamily: selectFontStyle(auctionData.font['hearder_font'])}}>
                    {(auctionData.title==='' || auctionData.title===undefined) ? 
                        'Optional auction title here' : auctionData.title}</p>
                {/* <!-- paragraph --> */}
                <div className="flex justify-center items-center">
                    <AuctionDescription
                        description={auctionData.description}
                        className='text-[14px] lgl:px-[250px] px-10  font-medium leading-normal'
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                    />
                </div>
            </div>
            {/* <!-- image section --> */}
            <div className="relative previewlot">
                {updatedImages.length > 0 && (
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        // itemClass="carousel-item-padding-190-px"
                        itemClass="carousel-item-padding-0-px"
                    >
                        {updatedImages.map((imageData, index) => (
                            <div key={index} className="mx-2 ">
                                <img
                                    src={`${config.Storage.CDN}${imageData.url}`}
                                    alt="lead-auction-img"
                                    loading="lazy"
                                    className="h-[350px] w-full object-contain"
                                />
                            </div>
                        ))}
                        {updatedImages.length < 3 && 
        Array(3 - updatedImages.length)
            .fill(null)
            .map((_, index) => (
                <div key={index - updatedImages.length} className=" mx-2">
                    <div className="flex h-[350px]  space-x-3 justify-center items-center bg-[#E6E6E6] object-contain">
                        
                        <div className="flex justify-center items-center flex-col">
                            <img src="/images/lead-auction-img.png" alt="lead-auction-img" className=""/>
                            <p className="text-[8px] pt-2 text-[#52525A] text-center font-semibold leading-normal">
                            </p>
                        </div>
                    </div>
                </div>
            ))}
                       
                    </Carousel>
                )}
                              
                {updatedImages.length === 0 && (
                    <div className="flex justify-between px-5 w-full">
                        {[...Array(3)].map((_, index) => (
                            <div key={index} className="flex justify-between w-full">
                                <div className="px-3 w-full -mx-3">
                                    <div className="flex h-[350px] w-full justify-center 
                                            items-center bg-[#E6E6E6]">
                                        <div className="flex justify-center items-center flex-col">
                                            <img src="/images/lead-auction-img.png" alt="lead-auction-img" />
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

               
            </div>


          
            <div className="lgl:px-[84px] md:px-[60px] sm:px-8 px-5 mt-5 sm:mt-[42px] relative">
                {/* <!-- zoom image , arrow mark --> */}
                {/* <div className="flex justify-between items-center absolute top-[-83px] left-1/2 -translate-x-1/2">
                
                    <img src="/images/arrow-left-dark.svg" alt="img" />
                
                    <div className="flex">
                        <img src="/images/zoom-image.svg" alt="zoomimage"/>
                        <p className="text-[12px] leading-normal pl-2.5 font-medium">Zoom image</p>
                    </div>
                    <img src="/images/arrow-right-dark.svg" alt="img" />
                </div> */}
                {/* <!-- main content --> */}
                <div className="w-full flex lgl:flex-nowrap flex-wrap  mt-6">
                    <div className="lgl:w-[60%] lg:w-[50%] w-full">
                        <p className="text-[12px] font-medium sm:text-left text-center leading-normal ">
                            {startDate === '' ? '12 Jul 2023 / 08:00 BST' : 
                                (`${startDate} / ${startMin} ${auctionData.time_zone === '' ? 'GMT' : 
                                    auctionData.time_zone.split(' - ')[0]}`)} - {endDate === '' ? '22 Jul 2023 / 20:00 BST' : 
                                (`${endDate} / ${endMin} ${auctionData.time_zone === '' ? 'GMT' : 
                                    auctionData.time_zone.split(' - ')[0]}`)}
                        </p>
                        <p className="text-[23px] leading-normal sm:text-left text-center font-medium"
                            style={{fontFamily: selectFontStyle(auctionData.font['hearder_font'])}}>
                            {(lotNumber==undefined ? "1" : lotNumber) + ". "}
                            {lotData.title1==='' ? 'Lot Title goes here' :  lotData.title1}</p>
                        <p className="text-[12px] leading-normal sm:text-left text-center font-medium ">
                            {lotData.title2==='' ? 'Title 2 goes here' :  lotData.title2}</p>
                        {/* <div className="flex items-center sm:justify-start justify-center mt-3">
                            <img src="/images/location-img.svg" alt="img"/>
                            <p className="text-[12px] leading-normal pl-1.5 font-medium">
                                Location:<span> United Kingdom</span> </p>
                        </div> */}
                        {/* <!-- social media links --> */}
                        <div className="flex sm:justify-start justify-center  mt-2">
                            <div className="relative">
                                <img src="/images/circle-icon.svg" alt="img"/>
                                <img src="/images/like-symbol.svg" className="absolute top-[21px] left-[21px]" alt="img"/>
                            </div>
                            <img src="/images/calender-large.svg" alt="add-calender-icon" className="cursor-default"/>
                            <img src="/images/share-icon-large.svg" alt="share-icon" className="cursor-default"/>
                        </div>
                        {/* <!-- terms condition --> */}
                        <div className="flex mt-2 sm:justify-start justify-center items-center">
                            <p className="text-[12px] text-center font-medium leading-normal">FAQs +</p>
                            <p className="text-[12px] pl-2 text-center font-medium leading-normal">
                                Terms and Conditions +</p>
                        </div>
                        {/* <!-- description --> */}
                        <div className="mt-10">
                            <p className="text-[16px] font-bold leading-normal"
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                                Description</p>
                            <AuctionDescription
                                description={lotData.description}
                                className='text-[16px] pt-2.5 font-medium leading-normal break-all'
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                            />
                        </div>
                        {/* <!-- shipping details --> */}
                        {/* <!-- description --> */}
                        <div className="mt-8 lg:mb-20 mb-6">
                            <p className="text-[16px] font-bold leading-normal"
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                            >Shipping details</p>
                            {lotData.shipping_details==="" ? 
                                <p className="text-[16px] pt-2.5 font-medium leading-normal"
                                    style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse fermentum mollis 
                                    neque vel posuere. Duis nec metus a turpis hendrerit dignissim. Quisque eget velit sed 
                                    erat ornare tristique at sit amet orci. <br/> 
                                </p> : <p className="text-[16px] pt-2.5 font-medium leading-normal"
                                    style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}} 
                                    dangerouslySetInnerHTML={{ __html: shippingDetails }}>
                                </p>}
                        </div>
                    </div>
                    <div className="lgl:w-[40%] lg:w-[50%] lgl:mb-0 sm:mb-14 mb-4 w-full lgl:ml-[85px] ml-0">
                        <div className="border  border-[#DFDFDF]">
                            <p className="text-[16px] border-b  border-[#DFDFDF] py-2.5 text-center font-medium 
                                    leading-normal ">Place your bid</p>
                            <div className="flex  py-[17px] justify-center">
                                <div className="px-[20px] border-r border-[#717179]">
                                    <p className="text-[23px] leading-normal font-medium">12</p>
                                    <p className="text-[8px] text-center font-medium leading-normal">DAYS</p>
                                </div>
                                <div className="px-[20px] border-r border-[#717179]">
                                    <p className="text-[23px] leading-normal font-medium">09</p>
                                    <p className="text-[8px] text-center font-medium leading-normal">HRS</p>
                                </div>
                                <div className="px-[20px] border-r border-[#717179]">
                                    <p className="text-[23px] leading-normal font-medium">34</p>
                                    <p className="text-[8px] text-center font-medium leading-normal">MINS</p>
                                </div>
                                <div className="px-[20px] ">
                                    <p className="text-[23px] leading-normal font-medium">21</p>
                                    <p className="text-[8px] text-center font-medium leading-normal">SECS</p>
                                </div>
                            </div>
                            {/* <!-- estimate --> */}
                            <div className="flex mx-[34px] pb-3 border-b border-[#DFDFDF] justify-between">
                                <div>
                                    <p className="text-[12px] font-medium leading-normal">Estimate</p>
                                    <p className="text-[14px] font-medium leading-normal">
                                        {(auctionData.currency==='' || auctionData.currency===undefined) ? '£' : 
                                            currencySymbolMapping[auctionData.currency]}
                                        {lotData.low_estimate==='' ? '5,000' : 
                                            lotData.low_estimate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}-
                                        {lotData.high_estimate==='' ? '6,000' : 
                                            lotData.high_estimate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                                </div>
                                <div>
                                    <p className="text-[12px] font-medium leading-normal">Current Bid</p>
                                    <p className="text-[14px] font-medium leading-normal">
                                        {(auctionData.currency==='' || auctionData.currency===undefined) ? '£' : 
                                            currencySymbolMapping[auctionData.currency]}
                                        00</p>
                                </div>
                            </div>
                            <div className="mx-[34px] my-5">
                                <button 
                                    className="cursor-default  bg-[#282828] rounded-md text-[9px] text-[#fff] 
                                        leading-normal font-medium px-[10px] py-[8px] w-full"
                                    style={{backgroundColor: auctionData.buttons['background_color'], 
                                        color: auctionData.buttons['text_color']}}>
                                    Register to bid</button>
                            </div>
                        </div>
                        <div className="relative mt-4">
                            <button type="button" disabled className="text-[14px] bg-[#fff] text-start relative w-full 
                                    h-11 px-3 border border-[#A1A1A9]  outline-none font-medium leading-normal"
                            >Select</button>
                            <img src="/images/auction-show-dropdown.svg" alt="dropdown-icon" 
                                className="absolute top-[20px] right-[15px]"/>
                        </div>
                    </div>
                </div>

            </div>
            {/* <!-- footer content --> */}
            <Footer auctionData={auctionData} />
        </div>
    );
}