/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import Lot from "./lot";
import { dataService } from "../../../../services/dataService";
import BidsShimmer from "../bids/bidsShimmer";

export default function LotTable(props){
    const { 
        lots,
        handleDelete,
        loader,
        setDeleteLotModal,
        deleteLotModal,
        fetchHandler,
        handleSort, 
        currentSortColumn,
        searchParams,
        searchValue,
        loadLots,
        lotInfo, lotDetailsFromSocket,
        isSearched,
        auctionData,
        isSocket, loading
    } = props;
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [limit, setLimit] = useState(10); // Track the current page
    const dragItem = useRef();
    const dragOverItem = useRef();
    const { id }=useParams();
    const Navigate = useNavigate();

    const handleDragStart = (position) => {
        dragItem.current = position;
    };
    const handleDragEnter = (position) => {
        dragOverItem.current = position;
    };
    const handleReorderLots = async(reorder_lot_number, reorder_position)=>{
        const response = await dataService.reorderLot({
            "auction_id": lots[0].auction_id,
            "reorder_lot_number": reorder_lot_number,
            "reorder_position": reorder_position,
        });
        return response;
    }
    const handleDrop = async () => {
        const [currentItem, overItem] = [dragItem.current, dragOverItem.current]
        if (currentItem !== null && overItem !== null) {
            const copyListItems = [...items];
            const dragItemContent = copyListItems.splice(currentItem, 1);
            copyListItems.splice(overItem, 0, dragItemContent[0]);
            setItems(copyListItems);
            dragItem.current = null;
            dragOverItem.current = null;
            await(handleReorderLots(lots[currentItem].lot_number, lots[overItem].lot_number))
            fetchMoreData()
        }
    };

    const keyword=`${decodeURIComponent(searchParams.get('search')).length>20 ? 
        decodeURIComponent(searchParams.get('search')).substring(0,20)+'...' : decodeURIComponent(searchParams.get('search'))}`

    /**
     * The function fetches more data and updates the state with the new items.
     */
    const fetchMoreData = async () => {
        const nexPage = limit + 10
        await fetchHandler(limit);
        setLimit(nexPage);
        // TODO: William remove this after checking it's unncessary
        // setTimeout(async() => {
        // }, 500);
    };
    
    useEffect(() => {
        fetchMoreData();
    }, []);

    useEffect(() => {
        setItems(lots)
    }, [lots])
   
    return(
        <div className="md:pb-[70px] pb-[100px] pt-2 overflow-auto mt-4">
            <div className="z-10 border-[#DEDEDE] border rounded-lg xl:w-full w-fit" 
                style={{transform: "rotateX(180deg)"}}>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={true}
                    loader={<h4></h4>}
                    className="lots"
                >
                    <table aria-label="Outside Table" className="w-full overflow-auto table-auto" 
                        style={{transform: "rotateX(-180deg)"}}>
                        <thead>
                            <tr className="bg-[#FFFFFF] border-b rounded-lg border-[#DEDEDE] h-12">
                                <th scope="col" className="rounded-lg px-3 py-3 min-w-[120px]">
                                    <span data-testid='sort-lot-number' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'lot_number')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Lot number</p>
                                        {currentSortColumn.key==='lot_number' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[100px]">
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[140px] ">
                                    <span data-testid='sort-title' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'title1')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Title</p>
                                        {currentSortColumn.key==='title1' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[120px]">
                                    <span className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'starting_price')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Starting bid</p>
                                        {currentSortColumn.key==='starting_price' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>          
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[120px]">
                                    <span className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'current_bid')}}>
                                        <p className="text-[16px] font-medium leading-normal cursor-pointer
                                                    text-[#343434] pr-1 py-1">Current bid</p>
                                        {currentSortColumn.key==='current_bid' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>          
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[160px] ">
                                    <span className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'top_bidder')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                    pr-1 py-1 cursor-pointer">Top bidder</p>
                                        {currentSortColumn.key==='top_bidder' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[120px] ">
                                    <span className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'paddle_number')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                    pr-1 py-1 cursor-pointer">Paddle no.</p>
                                        {currentSortColumn.key==='paddle' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-3 py-3 min-w-[45px] ">
                                     
                                </th>
                                    
                            </tr>
                        </thead>
                        <tbody>
                            {(!loadLots && items.length > 0 && !loading ) && (
                                <Lot
                                    lots={items}
                                    auctionData={auctionData}
                                    loader={loader}
                                    handleDelete={handleDelete}
                                    deleteLotModal={deleteLotModal}
                                    setDeleteLotModal={setDeleteLotModal}
                                    onDragStart={handleDragStart}
                                    onDragEnter={handleDragEnter}
                                    onDragEnd={handleDrop}
                                    lotDetailsFromSocket={lotDetailsFromSocket}
                                    isSocket={isSocket}
                                />
                            )}
                            {(!loading && !loadLots && searchValue !== '' && searchParams.get('search')!==null && items.length==0) &&
                                <tr>
                                    <td
                                        colspan="7"
                                        className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]"
                                    >
                                        <div className="h-[180px] mt-[100px]">
                                            <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                                No search results for ‘{keyword}’</p>
                                        </div>
                                    </td>
                                </tr>}
                            {(!isSearched && !loadLots && !loading && items.length==0 && searchParams.get('search')=== null) && 
                                <tr className="">
                                    <td colSpan="7" className="sm:text-center text-start sm:pl-0 
                                        xs:pl-[200px] pl-[100px]">
                                        <div className="h-[180px] mt-[100px]">
                                            <p
                                                className="text-[18px] font-medium  leading-normal 
                                                        text-[#34343 mb-4"
                                            >
                                                Start adding lots to your auction
                                            </p>
                                            <button 
                                                type="button" 
                                                disabled={(
                                                    auctionData.template_name==='Single Lot' && 
                                                        lotInfo.total_records_found===1
                                                )}
                                                onClick={() => 
                                                    Navigate(`/auctions/${id}/${auctionData.auction_id}/lots/create`, {state: auctionData})
                                                }
                                                className="px-[31px] mt-2 py-[12px] bg-[#282828] sm:mx-0 
                                                        mx-3 text-[#FFFFFF] leading-normal font-medium text-[14px] 
                                                        rounded-[6px] disabled:bg-[#D4D4D8] hover:bg-[#3F3F45]
                                                        disabled:text-[hsl(240,4%,65%)]"
                                            >
                                                Create lot
                                            </button>
                                        </div>
                                    </td>
                                </tr>}
                            
                            {(loadLots || loading) &&
                            <BidsShimmer/>}
                        </tbody>
                       
                    </table>
                </InfiniteScroll>
            </div>
           
        </div>
    );
}