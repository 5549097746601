import ReactQuill from 'react-quill'

export const RichEditorFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'align',
    'link', 'image', 'video'
];

export const fonts = [
    'Arimo',
    'Comic-Neue',
    'Courier-Prime',
    'Gothic-A1',
    'Lato',
    'Montserrat',
    'Open-Sans',
    'Roboto',
    'Tinos',
    'Inter',
];

export const QuillModules = {
    toolbar: [
        [{ font: fonts }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const Font = ReactQuill.Quill.import('formats/font');
Font.whitelist = fonts;
ReactQuill.Quill.register(Font, true);