import React,{useState} from 'react'
import { ChromePicker } from 'react-color';

export default function FooterDesign({auctionData, setAuctionData, setEditedAuctionData}) {
    const [showBgColorPicker, setShowBgColorPicker] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    
    return (
        <>
            <div className="flex mt-5">
                <div onClick={()=>setShowBgColorPicker(true)} 
                    className="w-[30px] h-[22px] border border-[#282828] bg-[#F9F7F7] rounded-[4px] cursor-pointer"
                    style={{backgroundColor: auctionData.footer['background_color']}}>
                </div>
                <p className="font-medium text-[#343434] text-[12px] pl-[10px] leading-normal">
                    Background colour</p>
            </div>
            {showBgColorPicker && 
            <>
                <div className='fixed top-0 left-0 right-0 w-full h-full' 
                    onClick={()=>setShowBgColorPicker(false)}/>
                <ChromePicker color={auctionData.footer['background_color']} 
                    onChange={(updatedColor) => {setAuctionData(prevData => ({
                        ...prevData,
                        footer: {
                            text_color : auctionData.footer.text_color,
                            background_color: updatedColor.hex
                        }
                    })), setEditedAuctionData(prevData => ({
                        ...prevData,
                        footer: {
                            text_color : auctionData.footer.text_color,
                            background_color: updatedColor.hex
                        }
                    }))}}
                />
            </>
            }
            <div className="flex mt-5">
                <div onClick={()=>setShowColorPicker(true)} 
                    className="w-[30px] h-[22px] border border-[#282828] bg-[#343434] rounded-[4px] cursor-pointer"
                    style={{backgroundColor: auctionData.footer['text_color']}}>
                </div>
                <p className="font-medium text-[#343434] text-[12px] pl-[10px] leading-normal">Text colour</p>
            </div>
            {showColorPicker && 
            <>
                <div className='fixed top-0 left-0 right-0 w-full h-full' 
                    onClick={()=>setShowColorPicker(false)}/>
                <ChromePicker color={auctionData.footer['text_color']} 
                    onChange={(updatedColor) => {setAuctionData(prevData => ({
                        ...prevData,
                        footer: {
                            background_color: auctionData.footer.background_color,
                            text_color: updatedColor.hex
                        }
                    })), setEditedAuctionData(prevData => ({
                        ...prevData,
                        footer: {
                            background_color: auctionData.footer.background_color,
                            text_color: updatedColor.hex
                        }
                    }))}}
                />
            </>
            }
        </>
    )
}
