/* eslint-disable max-len */
import React, { useEffect } from 'react'
import TableRow from './tableRow'
import TbodyShimmer from './tbodyShimmer';

export default function TableBody(props) {

    const { bidders , handleSort, isFilterApplied, searchParams, loading, currentSortColumn} =props;

    const keyword=`${decodeURIComponent(searchParams.get('keyword')).length>20 ? 
        decodeURIComponent(searchParams.get('keyword')).substring(0,20)+'...' : decodeURIComponent(searchParams.get('keyword'))}`

    return (
        <div className='pb-[200px] pt-2 overflow-auto'>
            <div className="z-10 w-fit border rounded-lg border-[#DEDEDE] xl:w-full" style={{transform: "rotateX(180deg)"}}>
                <table className="w-full overflow-auto table-auto " 
                    style={{transform: "rotateX(-180deg)", borderRadius:9}}>
                    <thead>
                        <tr className="bg-[#FFFFFF] h-12 border-b">
                            <th scope="col" className=" pl-12 pr-4 py-3 min-w-[200px] flex">
                                <span className="flex items-center cursor-pointer w-fit" 
                                    onClick={(e) => {handleSort(e,'name')}} id="bidder_name" data-testid="bidder_name">
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1">Name</p>
                                    {currentSortColumn.key==='name' ? 
                                        (currentSortColumn.order==='ascending'?
                                            <img src="images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 " />:
                                            <img src="images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3" />
                                        ):
                                        (<img src="images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3  " />)
                                    }
                                </span>
                            </th>
                           
                            <th scope="col" className=" px-4 py-3 min-w-[300px]">
                                <span className="flex items-center "
                                    onClick={(e) => {handleSort(e,'email_address')}} id='email_address' 
                                    data-testid="bidder_email">
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1  cursor-pointer">
                                        <span className=''> Email </span>
                                    </p>
                                    {currentSortColumn.key==='email_address' ? 
                                        (currentSortColumn.order==='ascending'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />)
                                    }
                                </span>          
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[180px]">
                                <span className="flex items-center" 
                                    onClick={(e) => {handleSort(e,'created_at')}} data-testid=" ">
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] pr-1 py-1  cursor-pointer">
                                        <span className=''> Date Registered</span>
                                    </p>
                                    {currentSortColumn.key==='created_at' ? 
                                        (currentSortColumn.order==='ascending'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />)
                                    }
                                </span>          
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[120px] ">
                                <span className="flex items-center justify-center" id='marketing' >
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                        pr-1 py-1 ">Marketing</p>
                                    <span className='py-1 cursor-pointer flex items-center w-[30px] ml-1'>
                                        <img src='/images/important.svg' alt='icon' /></span>
                                </span>
                            </th>
                            <th scope="col" className="px-4 py-3 sm:min-w-[120px] min-w-9"></th>
                        </tr>
                    </thead>
                    {/* <!-- empty auction list --> */}
                    {(bidders?.length===0 && isFilterApplied!==true && searchParams.get('keyword')=== null && !loading) &&
                    <tbody>
                        <tr>
                            <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] 
                            pl-[100px]  ">
                                <div className="h-[200px] mt-[100px]">
                                    <p className="text-[18px] font-medium  leading-normal text-[#34343">Currently no bidders</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }
                    {/* <!-- empty search  --> */}
                    {(searchParams.get('keyword')!== null && bidders.length===0 && isFilterApplied===false) && 
                    <tr>
                        <td colSpan="5" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]
                         ">
                            <div className="h-[180px] mt-[100px]">
                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                    No search results for ‘{keyword}’</p>
                            </div>
                        </td>
                    </tr>}
                    {(searchParams.get('keyword')!== null && isFilterApplied===true && bidders.length===0) && 
                    <tr>
                        <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                            <div className="h-[180px] mt-[100px]">
                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                    No results found</p>
                            </div>
                        </td>
                    </tr>}
                    {/* <!-- empty filter --> */}
                    {(isFilterApplied===true && bidders.length===0 && searchParams.get('keyword')=== null ) && 
                    <tr>
                        <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                            <div className="h-[180px] mt-[100px]">
                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                    No results for applied filter</p>
                            </div>
                        </td>
                    </tr>}
                    {loading && <TbodyShimmer/>}
                    {!loading && <tbody className="mt-5">
                        <TableRow bidders={bidders}/>
                    </tbody>}
                </table>
            </div>
        </div>
    )
}
