import React from 'react'
import SaleTableBody from './sale'
import TbodyShimmer from '../../listAuction/tbodyShimmer'

export default function SalesTable({sales,loader, fetchHandler, handleSort, 
    currentSortColumn, searchParam,searchValue, loading,salesInfo,
    isSearched, auctionData, isFilterApplied, onChangePaymentInfo}) {
    const keyword=`${decodeURIComponent(searchParam.get('keyword')).length>20 ? 
        decodeURIComponent(searchParam.get('keyword')).substring(0,20)+'...' : decodeURIComponent(searchParam.get('keyword'))}`
    return (
        <div className="md:pb-[70px] pb-[100px] pt-2 overflow-auto">
            <div className="z-10 border-[#DEDEDE] border rounded-lg xl:w-full w-fit">    
                {!loading ?
                    <table aria-label="Outside Table" className="w-full overflow-auto table-auto">
                        <thead>
                            <tr className="bg-[#FFFFFF] border-b rounded-lg border-[#DEDEDE] h-12">
                                <th scope="col" className="rounded-lg px-4 py-3 min-w-[140px]" id='order_no'>
                                    <span data-testid='sort-lot-number' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'order_number')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Order no.</p>
                                        {currentSortColumn.key==='order_number' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 " />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3  " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[130px]">
                                    <span data-testid='sort-lot-number' id="customer_name" 
                                        className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'name')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Customer name</p>
                                        {currentSortColumn.key==='name' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 " />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3  " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[130px]">
                                    <span data-testid='sort-lot-number' className="flex items-center justify-center">
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1">Auction Name</p>
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[140px] ">
                                    <span data-testid='sort-title' id='date' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'created_at')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Date</p>
                                        {currentSortColumn.key==='created_at' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[200px]">
                                    <span className="flex items-center justify-center" id="total"
                                        onClick={(e) => {handleSort(e,'amount')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Total</p>
                                        {currentSortColumn.key==='amount' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>          
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[200px]">
                                    <span className="flex items-center justify-center" id="payment_type"
                                        onClick={(e) => {handleSort(e,'payment')}}>
                                        <p className="text-[16px] font-medium leading-normal cursor-pointer
                                                    text-[#343434] pr-1 py-1">Payment type</p>
                                        {currentSortColumn.key==='payment' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>          
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[180px] ">
                                    <span className="flex items-center justify-center" id="payment_status"
                                        onClick={(e) => {handleSort(e,'payment_status')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                    pr-1 py-1 cursor-pointer">Payment status</p>
                                        {currentSortColumn.key==='payment_status' ? 
                                            (currentSortColumn.order==='asc'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[45px] "></th> 
                            </tr>
                        </thead>
                        {/* <!-- empty auction list --> */}
                        <tbody>
                            {sales.length>0?
                                <>
                                    <SaleTableBody 
                                        sales={sales} auctionData={auctionData} salesInfo={salesInfo} searchParam={searchParam}
                                        onChangePaymentInfo={onChangePaymentInfo}
                                    />
                                </>
                                :
                                <>
                                    {searchValue!=='' && !isFilterApplied && keyword!==null ?
                                        <tr className="">
                                            <td colspan="7" className="sm:text-center text-start sm:pl-0 
                                            xs:pl-[200px] pl-[100px]">
                                                <div className="h-[180px] mt-[100px]">
                                                    <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                                        No search results for `{searchValue.length > 30 ? 
                                                            searchValue.substring(0, 30) + '...' : searchValue}`</p>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        salesInfo.total_orders===0 && !isFilterApplied && <tr className="">
                                            <td colSpan="7" className="sm:text-center text-start sm:pl-0 
                                                xs:pl-[200px] pl-[100px]">
                                                <div className="h-[180px] mt-[100px]">
                                                    <p className="text-[18px] font-medium  leading-normal 
                                                            text-[#34343 mb-4">Currently no sales</p>
                                                </div>
                                            </td>
                                        </tr>}
                                    {isFilterApplied && sales.length==0 && searchValue==='' &&
                                    <tr className="">
                                        <td colspan="7" className="sm:text-center text-start sm:pl-0 
                                    xs:pl-[200px] pl-[100px]">
                                            <div className="h-[180px] mt-[100px]">
                                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                                    No results for applied filter</p>
                                            </div>
                                        </td>
                                    </tr>
                                    }
                                    {searchValue!==''&& isFilterApplied && sales.length==0 && 
                                    <tr className="">
                                        <td colspan="7" className="sm:text-center text-start sm:pl-0 
                                    xs:pl-[200px] pl-[100px]">
                                            <div className="h-[180px] mt-[100px]">
                                                <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                                    No results found</p>
                                            </div>
                                        </td>
                                    </tr>
                                    }
                                </>}
                        </tbody>
                    </table>:
                    <table aria-label="Outside Table" className="w-full overflow-auto table-auto">
                        <TbodyShimmer/>
                    </table>}
            </div>
        </div>
    )
}
