/* eslint-disable security/detect-object-injection */
// registrationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const user = (localStorage.getItem('user'));
const initial = user ? true : false;
const initialState = {
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
    newsletterSubscribed: false,
    emailError: null,
    passwordError: null,
    showFormate: false,
    loggedIn: initial,
    firstUser: false
};

/* The code is creating a slice of the Redux store called `registrationSlice`. */
const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setFieldValue: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        setEmailError: (state, action) => {
            state.emailError = action.payload;
        },
        setPasswordError: (state, action) => {
            state.passwordError = action.payload;
        },
        clearError: (state) => {
            state.emailError = null;
            state.passwordError=null
        },
        clearRegistrationData: (state) => {
            Object.assign(state, initialState);
        },
        passwordFormate: (state, action) => {
            state.showFormate = action.payload;
        },
        verifyOtp: (state, action) => {
            state.loggedIn = true;
            state.email = action.payload
        },
        logout: (state, action) => {
            localStorage.clear()
            state.loggedIn = false
            state.email = '',
            state.password= '',
            state.confirmPassword= '',
            state.termsAccepted= false,
            state.newsletterSubscribed= false,
            state.emailError= null,
            state.passwordError= null,
            state.showFormate= false,
            state.firstUser= false
        },
        setFirstUser: (state, action)=>{
            state.firstUser = action.payload;
        }
    },
});

export const { setFieldValue, setEmailError, setPasswordError, clearError, clearRegistrationData, 
    passwordFormate, verifyOtp, logout, setFirstUser} = registrationSlice.actions;

export default registrationSlice.reducer;
