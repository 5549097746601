import React, { useEffect, useState } from 'react';
import { dataService } from '../../services/dataService';
import sanitizeHtml from 'sanitize-html';

export default function Banner({notes,displayContent,notificationContent,buttonText,toggleFullText,paragraph,istextShow}) {

    function renderHTML(htmlString) {
        const sanitizedHTML = sanitizeHtml(htmlString);
        return { __html: sanitizedHTML };
    }

    
    return (
        Object.keys(notes).length>0 &&
        <div>
            <div className={`fixed ${notes?.type==='Emergency' ? 'bg-[#CA8686]':'bg-[#B8D5DB]' } text-center text-[#282828]
             text-[14px] leading-[17px] font-medium font-Medium w-full top-[-0.25rem] px-2 py-4 mb-5 z-[9999]`}>
                <div className={`justify-center items-center ${!istextShow? "flex" :"block"}`}>
                    <p className={`rendered-content  ${buttonText ==='Read more' ? 'more-lines' : 'md:'} `} 
                        dangerouslySetInnerHTML={renderHTML(notificationContent)}></p>
                    {displayContent && (<span className='inline-block ml-2'>
                        <button onClick={toggleFullText} className='underline min-w-max' >
                            {buttonText}
                        </button></span>
                    )}
                </div>
            </div>
        </div>
    )
}


//  {auction.title==='' ? '-' :
//                  (auction.title.length > 40 ?auction.title.substring(0, 40) + '...' : auction.title)} 