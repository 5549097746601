import AuctionOverView from "../auctionOverView";
import React from 'react'

export default function Overview(){
    return(
        <div
            className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
            id="tabs-home3"
            role="tabpanel" 
            aria-labelledby="tabs-home-tab3">
            <AuctionOverView/>
        </div>
    );
}