/* eslint-disable max-len */
import React, { useCallback, useState } from 'react'
import { useParams } from "react-router-dom"
import Debounce from '../../../../CommonComponent/debounce';
import ExportAllModel from '../exportModal';

export default function BidderHeader({bidderInfo, searchValue, bidderExportCsvUrl, setSearchValue,
    handleSearch, setToastMeassage, searchParams}) {
    
    const { id }=useParams();
    const [showExportModel, setShowExportModel] =useState('')
    const [exportBtnLoading, setExportBtnLoading]=useState(false)
    const titleRegex = /^(?![%\s]).*$/;

    const handleChange = (e,value)=>{
        handleSearch(e,'keyword',value)
    }

    const changeHandler=(e)=>{
        let value=e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')
        if(titleRegex.test(value)) {
            setSearchValue(value)
            
        }else{
            return;
        }
    }

    const optimizedFn = useCallback(
        Debounce((e,value) => handleChange(e,value)),
        [searchParams]
    );

    const exportModelHandler = ()=>{
        setShowExportModel(true)
    }

    const exportHandler=()=>{
        if(!exportBtnLoading){
            setExportBtnLoading(true)
            window.location.href = bidderExportCsvUrl
        }
        setTimeout(()=>{
            setExportBtnLoading(false)
            setShowExportModel(false)
            setToastMeassage(true)
        }, 1500)
        setTimeout(()=>{
            setToastMeassage(false)
        },3000)
    }

    return (
        <div className="mt-6 flex justify-between flex-wrap items-center">
            <p data-testid='lot-count' className="text-[16px] leading-normal font-medium text-[#343434]"> 
                <span>{bidderInfo.total_records_found} </span> 
                {bidderInfo.total_records_found===1 ? 'Bidder' : 'Bidders'}
            </p>
            <div className="flex ml-auto  flex-wrap items-center">
                {/* <!-- search  --> */}
                <div className="relative pr-3 py-[12px]">
                    <input type="text" data-testid="search-bidder" debounceTimeout={500}
                        className="text-[14px] relative w-[270px] h-[32px] pl-8 pr-3 border 
                                border-[#A1A1A9] placeholder:text-[#A1A1A9] focus:border-[#343434] outline-none 
                                font-medium leading-normal text-[#4A4A4A]" placeholder="Search"
                        value={decodeURIComponent(searchValue)} 
                        onChange={(e)=>{e.preventDefault(); optimizedFn(e,e.target.value), changeHandler(e)}}
                    />
                    <img src="/images/search-icon-large.svg" alt="search-icon" 
                        className="absolute top-5 cursor-pointer 
                                left-2.5" />
                </div>
                {/* <!-- export button -->/ */}
                <div className="relative mr-2.5 cursor-pointer">
                    <button onClick={exportModelHandler} 
                        disabled={bidderInfo.total_records_found === 0} 
                        type="button" id="export-bidders" 
                        className="text-[14px] sm:flex hidden text-[#52525A] border w-[89px] items-center 
                            disabled:text-[hsl(240,4%,65%)] disabled:border-[#D4D4D8]
                            h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium justify-center">
                        <img src="/images/export-icon.svg" alt="export-icon" 
                            className={`mr-2 ${bidderInfo.total_records_found === 0 ? 'opacity-50' : ''} `} />
                        Export
                    </button>
                    <button type="button" onClick={exportModelHandler} disabled={bidderInfo.total_records_found === 0}
                        className="text-[14px] sm:hidden block text-[#52525A] border w-[35px] disabled:text-[hsl(240,4%,65%)]
                        h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium disabled:border-[#D4D4D8]">
                        <img src="/images/export-icon.svg" alt="export-icon" 
                            className={`ml-2 ${ bidderInfo.total_records_found === 0 ? 'opacity-50' : ''}  `} />
                    </button>
                </div>
            </div>
            {showExportModel && <ExportAllModel isOpen={showExportModel} setIsOpen={setShowExportModel} 
                handleExport={exportHandler} loader={exportBtnLoading}  type="Bidders" />}
        </div>    
    )
}
