import React, { useState, useEffect } from 'react';
import ItemShimmer from '../../../../CommonComponent/shimmerTable';
import Bidders from './bidders';

export default function BidderTable(props) {
    const {
        bidders,
        loader,
        handleSort,
        currentSortColumn,
        searchParams,
        searchValue,
        loadingBids,
        bidderInfo,
        isSearched,
        auctionData,
        setAcceptBidderModal,
        acceptBidderModal,
        handleBidders,
        loading
    } = props;

    const [showNoBiddersMessage, setShowNoBiddersMessage] = useState(false);

    const keyword =
    decodeURIComponent(searchParams.get('keyword')).length > 20
        ? decodeURIComponent(searchParams.get('keyword')).substring(0, 20) + '...'
        : decodeURIComponent(searchParams.get('keyword'));

    const [bidderLoading, setBidderLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setBidderLoading(false);
        }, 1000);

        // Check if there are no bidders to show the "No bidders" message
        if (bidders.length === 0 && !isSearched && searchValue === '') {
            setShowNoBiddersMessage(true);
        } else {
            setShowNoBiddersMessage(false);
        }
    }, [searchParams, bidders, isSearched, searchValue]);

    return (
        (!loadingBids && !loading)?
            <div className="customScrollbar pt-2 overflow-auto mb-10 mt-4">
                <div className="z-10 border-[#DEDEDE] border rounded-lg xl:w-full w-fit" style={{ transform: 'rotateX(180deg)' }}>
                    <table aria-label="Outside Table" className="w-full overflow-auto table-auto" 
                        style={{ transform: 'rotateX(-180deg)' }}>
                        <thead>
                            <tr className="bg-[#FFFFFF] border-b rounded-lg border-[#DEDEDE] h-12">
                                <th scope="col" className="rounded-lg px-4 py-3 min-w-[130px]">
                                    <span data-testid='sort-paddle-no' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'paddle')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1 cursor-pointer">Paddle no.</p>
                                        {currentSortColumn.key==='paddle' ? 
                                            (currentSortColumn.order==='ascending'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[130px] ">
                                    <span data-testid='sort-bidder-name' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'name')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1 cursor-pointer">Name</p>
                                        {currentSortColumn.key==='name' ? 
                                            (currentSortColumn.order==='ascending'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[170px]">
                                    <span data-testid='sort-bidder-email' className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'email_address')}}>
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1 cursor-pointer">Email</p>
                                        {currentSortColumn.key==='email_address' ? 
                                            (currentSortColumn.order==='ascending'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>          
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[170px]">
                                    <span data-testid="sort-date" className="flex items-center justify-center" 
                                        onClick={(e) => {handleSort(e,'created_at')}}>
                                        <p className="text-[16px] font-medium leading-normal cursor-pointer
                                                text-[#343434] pr-1 py-1">Date registered</p>
                                        {currentSortColumn.key==='created_at' ? 
                                            (currentSortColumn.order==='ascending'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>          
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[120px] ">
                                    <span className="flex items-center justify-center" >
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                        pr-1 py-1 cursor-pointer">Marketing</p>
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[120px] ">
                                    <span className="flex items-center justify-center">
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Status</p>
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[120px] "></th>
                            </tr>
                        </thead>
                        <tbody>
                            {bidders.length > 0 ? (
                                <Bidders
                                    bidders={bidders}
                                    auctionData={auctionData}
                                    loader={loader}
                                    acceptBidderModal={acceptBidderModal}
                                    setAcceptBidderModal={setAcceptBidderModal}
                                    handleBidders={handleBidders}
                                />
                            ) : (
                                <>
                                    {showNoBiddersMessage && bidderInfo.total_bidders==0 ? renderNoBiddersMessage() : null}
                                    {searchValue !== '' && !isSearched ? renderNoSearchResultsMessage(keyword) : null}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
                {(bidderLoading || loading) && <ItemShimmer bidders={bidders} />}
            </div>
            :<ItemShimmer bidders={bidders}/>
    );
    function renderNoBiddersMessage() {
        return (
            <tr className="">
                <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                    <div className="h-[180px] mt-[100px]">
                        <p className="text-[18px] font-medium leading-normal text-[#34343 mb-4]">
                            There are no bidders for this auction.
                        </p>
                    </div>
                </td>
            </tr>
        );
    }
    
    function renderNoSearchResultsMessage(keyword) {
        return (
            <tr className="">
                <td colSpan="7" className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]">
                    <div className="h-[180px] mt-[100px]">
                        <p className="text-[18px] font-medium leading-normal text-[#34343]">
                            No search results for ‘{keyword}’
                        </p>
                    </div>
                </td>
            </tr>
        );
    }
}
