import React,{useState, Fragment} from 'react'
import { Listbox, Transition } from '@headlessui/react';
import { FontList } from '../../../../../CommonComponent/staticValues';
import { useLocation } from 'react-router-dom';

export default function FontDesign({auctionData, setAuctionData, setEditedAuctionData}) {
    const state = useLocation();
    const [headingFontShow, setHeadingFontShow] = useState(state.state===null|| state.state.font.hearder_font===''?false:true)
    const [bodyFontShow, setBodyFontShow] = useState(state.state===null || state.state.font.body_font===''?false:true)

    return (
        <>
            {/* <!-- dropdown for headoing-font--> */}
            <Listbox as="div" className="relative w-full"
                value={auctionData.font['hearder_font']} 
                onChange={(value) => {setAuctionData(prevData=>(
                    {...prevData,font: {
                        body_font: auctionData.font.body_font,
                        hearder_font: value.name
                    }})), setHeadingFontShow(true), setEditedAuctionData(prevData=>(
                    {...prevData,font: {
                        body_font: auctionData.font.body_font,
                        hearder_font: value.name
                    }}))}}>
                <div className="flex mt-4">
                    <p className="text-[12px] font-medium leading-normal text-[#343434]">Heading font</p>
                </div>
                <Listbox.Button type="button" id="heading_font"
                    className={`text-[14px] text-start relative w-full h-11 px-3 border 
                    border-[#A1A1A9] ${headingFontShow?'text-[#343434]':'text-[#A1A1A9]'} 
                    outline-none font-medium leading-normal`}
                    data-te-dropdown-toggle-ref
                    aria-expanded="false"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    {auctionData.font['hearder_font']===''?'Select':auctionData.font['hearder_font']}</Listbox.Button>
                <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                    className="absolute top-[40px] right-[15px]" />
                <Listbox.Options 
                    className="bg-white rounded-[8px] z-30  absolute w-full 
                    [&[data-te-dropdown-show]]:block  shadow-bs1" aria-labelledby="dropdownMenuButton2"
                    data-te-dropdown-menu-ref>
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className=" pr-[15px] py-1 h-[120px] customScrollbar 
                                overflow-y-auto overflow-x-hidden ">
                            {FontList.map((font, index)=>(
                                <Listbox.Option key={font.id} value={font}
                                    as="div" 
                                    className="pl-[18px] pr-[15px] pt-2 
                                text-[12px]  leading-normal font-medium text-[#343434]">
                                    {({ active, selected }) => (
                                        <li
                                            className={`py-2 cursor-pointer rounded-[11px] px-2.5 ${
                                                active ? 'bg-[#E6E6E6]' : 'bg-white text-[#343434]'
                                            }`}
                                        >
                                            {font.name}
                                        </li>
                                    )}
                                </Listbox.Option>
                            ))}
                        </div>
                    </Transition>
                </Listbox.Options>
            </Listbox>
            {/* <!-- dropdown for body font--> */}
            <Listbox as="div" className="relative w-full" value={auctionData.font['body_font']} 
                onChange={(value) => {setAuctionData(prevData=>(
                    {...prevData,font: {
                        hearder_font: auctionData.font.hearder_font,
                        body_font: value.name
                    }})), setBodyFontShow(true), setEditedAuctionData(prevData=>(
                    {...prevData,font: {
                        hearder_font: auctionData.font.hearder_font,
                        body_font: value.name
                    }}))}}>
                <div className="flex mt-4">
                    <p className="text-[12px] font-medium leading-normal text-[#343434]">Body font</p>
                </div>
                <Listbox.Button type="button" id="body_font"
                    className={`text-[14px] text-start relative w-full h-11 px-3 border border-[#A1A1A9] 
                            ${bodyFontShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`}
                    data-te-dropdown-toggle-ref
                    aria-expanded="false"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    {auctionData.font['body_font']===''?'Select':auctionData.font['body_font']}                        
                </Listbox.Button>
                <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                    className="absolute top-[40px] right-[15px]" />
                <Listbox.Options 
                    className="bg-white rounded-[8px] z-30 absolute w-full 
                                    [&[data-te-dropdown-show]]:block 
                        shadow-bs1" aria-labelledby="dropdownMenuButton2"
                    data-te-dropdown-menu-ref>
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                    
                        <div className=" pr-[15px] py-1 h-[120px] 
                                customScrollbar overflow-y-auto overflow-x-hidden">
                            {FontList.map((font, index)=>(

                                <Listbox.Option key={font.id} value={font} 
                                    className="pl-[18px] pr-[15px] pt-2 
                            text-[12px] leading-normal font-medium text-[#A1A1A9]">
                                    {({ active, selected }) => (
                                        <li
                                            className={`py-2 cursor-pointer rounded-[11px] px-2.5 ${
                                                active ? 'bg-[#E6E6E6]' : 'bg-white text-[#343434]'
                                            }`}
                                        >
                                            {font.name}
                                        </li>
                                    )}                             
                                </Listbox.Option>
                            ))}
                        </div>
                    </Transition>
                </Listbox.Options>
            </Listbox>
        </>
    )
}
