/* eslint-disable max-len */
import {React, useState, useRef,useEffect} from 'react'
import MenuLinkModal from './menuLinkModal';
import ToolTip from '../../../../../CommonComponent/tooltip';
import config from '../../../../../config';
import { Oval } from  'react-loader-spinner'

export default function SetUpHeader(props) {
    const {auctionData, handleLogoChange, handleLogoDelete, errorMessages, handleLogoRedirectionChange, addMenuLink, deleteMenuLink,
        addDropdownLink,deleteDropdownLink, newMenuLink, setNewMenuLink, isAddingDropdown, setIsAddingDropdown, menuModal, setMenuModal,
        dropdownLinkError, setDropdownLinkError, startDate,setStartDate,endDate,setEndDate,startHour,setStartHour,startHourShow, 
        setStartHourShow,startMin,setStartMin,startMinShow, setStartMinShow,endHour,setEndHour,endHourShow,setEndHourShow,endMin,setEndMin,
        endMinShow,setEndMinShow,timezoneShow,setTimezoneShow, menuLinkError, setMenuLinkError,logoLoader,setLogoLoader,imageLoader,setImageLoader,mobileModal, isMobileModal} = props;
    
    return (
        <>
            <div className="header-contents" id="web-view-header" >
                <div>
                    <div className="flex pt-4">
                        <p className="font-medium text-[#343434] text-[12px] leading-normal">Logo</p>
                        <ToolTip title="Images must be JPEG or PNG format, optimised for web viewing and no larger than 1MB.">
                            <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                        </ToolTip>
                        
                    </div>
                    {auctionData.logo_image==='' && <div className="w-full cursor-pointer flex justify-center relative bg-[#E6E6E6] py-[22px] px-3 mt-2 rounded-md">
                        <img src="/images/add-image-icon.svg" alt="logo-preview" loading="lazy" />
                        <label htmlFor="logoUpload" className="font-medium text-center pl-2 text-[#343434] text-[10px] leading-normal">{auctionData.logo_image ? "Change logo" : "Add image"}</label>
                        <input
                            type="file"
                            accept="image/jpeg, image/png"
                            id="logoUpload"
                            className="opacity-0 z-10 absolute cursor-pointer top-0 h-[58px] w-full"
                            onChange={(e)=>handleLogoChange(e, "logo")}
                        />
                        {logoLoader&& <Oval
                            height={20}
                            width={50}
                            color="#FFFFFF"
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#000000"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />}
                    </div>}
                    {auctionData.logo_image!==''&&<div className="w-full test flex h-[84px] justify-center items-center relative bg-[#E6E6E6] 
                    px-3 mt-2 rounded-md">
                        <img src={`${config.Storage.CDN}${auctionData.logo_image}`} alt="add-image-icon" className='w-[125px] h-[65px] object-contain' loading="lazy" />
                        {logoLoader && <div className='absolute left-[43%]' ><Oval
                            height={25}
                            width={55}
                            color="#FFFFFF"
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#000000"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        /></div>}
                        <div className="flex justify-end absolute right-[15px] bottom-[15px] z-[11]">
                            <label htmlFor="logoUpload" className="font-medium text-center pr-2 h-auto text-[#343434] text-[10px] leading-normal cursor-pointer">
                                <img src="/images/edit-icon-img.svg" className="cursor-pointer w-3.5" alt="edit" loading="lazy" />
                            </label>
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                id="logoUpload"
                                className="opacity-0 absolute cursor-pointer top-0 w-3 left-0 z-[-1]"
                                onChange={(e)=>handleLogoChange(e,'logo')}
                            />
                            
                            <img src="/images/delete-icon-img.svg" id="delete-icon" className="cursor-pointer" alt="delete" onClick={()=>handleLogoDelete("logo")} loading="lazy" />
                        </div>
                    </div>}
                    {errorMessages.logoError!=='' && <p className='text-[10px] pt-1.5 font-medium leading-normal error-msg text-[#984848]'>{errorMessages.logoError}</p>}
                </div>
                <div className="pt-3">
                    <label for="logo_redirection_url" className="font-medium text-[#343434] text-[12px] leading-normal">Logo redirection</label>
                    <input type="text" id="logo_redirection_url" data-testid="logo_redirection_url" className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] leading-normal text-[#343434] font-medium placeholder:text-[#A1A1A9]" 
                        placeholder="https://" 
                        value={auctionData.logo_redirection_url}
                        onChange={(e)=>handleLogoRedirectionChange(e)} />
                    {errorMessages.logoRedirectionUrlError!=='' && <p className="text-[10px] pt-1.5 font-medium leading-normal error-msg text-[#984848]">Enter a valid URL</p>}
                </div>
                <div>
                    <div className="flex pt-4">
                        <p className="font-medium text-[#343434] text-[12px] leading-normal">Menu Links</p>
                        <ToolTip title="You can add up to 7 menu links, each with 5 dropdown links. Only the links that you add will appear on the page.">
                            <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                        </ToolTip>
                    </div>
                    {/* <!-- after adding menu links this content will show --> */}
                    {auctionData.menu_links.map((item,index)=>(
                        <div className="flex justify-between items-center bg-[#fff] border border-[#DFDFDF] 
                                    h-[58px] w-full shadow-bs5 rounded-[8px] mt-2 px-5" key={index}>
                            <p className="text-[12px] leading-normal font-medium text-[343434] max-w-full break-all pr-2.5">{item.title}</p>
                            <img id='delete-menulink' src="/images/delete-icon.svg" className="cursor-pointer" alt="delete icon" loading="lazy" onClick={()=>deleteMenuLink(index)} />
                        </div>
                    ))}
                    <button type='button' disabled={auctionData.menu_links.length===7} className="w-fit flex items-center mt-2 rounded-md" onClick={()=>{setMenuModal(true);document.body.classList.add('modal-open'); isMobileModal(true);}}>
                        <img src="/images/addlinks-icon.svg" alt="add-links-icon" className={`${auctionData.menu_links.length===7?'cursor-auto opacity-50':'cursor-pointer'}`} 
                            loading="lazy" />
                        <span className=" w-fit px-1 py-3 text-[12px] leading-normal font-medium  text-[#343434]"> 
                            <span id='menu-link' className={`${auctionData.menu_links.length===7?'cursor-auto':'cursor-pointer'}`}>Add menu link</span> </span>
                    </button>
                </div>
            </div>
           
        </>
    )
}
