// aws-exports.js
export default{
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_SELLER_COGNITO_USERPOOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_SELLER_COGNITO_CLIENT_ID,
        AUTHENTICATION_TYPE: 'USER_PASSWORD_AUTH',
        IDENTITY_POOL_ID: process.env.REACT_APP_SELLER_COGNITO_IDENTITY_POOL_ID,
    },
    API: {
        baseURL: process.env.REACT_APP_DOMAIN_NAME_FRONT_END+"/v1",
        mailchimpURL: process.env.REACT_APP_MAILCHIMP_API,
    },
    Storage: {
        bucket: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        CDN: process.env.REACT_APP_CDN_URL,
    },
    Environment: {
        url: process.env.REACT_APP_STAGE,
        auctionUrl: process.env.REACT_APP_BASE_URL_BUYER+"/",
        domain: process.env.REACT_APP_AMPLIFY_DOMAIN_NAME,
        stage: process.env.REACT_APP_STAGE
    },
    SITEKEY: process.env.REACT_APP_SITEKEY,
    SOCKETURL: process.env.REACT_APP_SOCKET_URL,
    MAILCHIMPKEY: process.env.REACT_APP_MAILCHIMP_SECRET_KEY,
    MAILADDRESS: process.env.REACT_APP_MAILCHIMP_ADDRESS
};
