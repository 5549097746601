import { Dialog, Transition } from '@headlessui/react'
import { Children, Fragment } from 'react'

export default function NoteModal(props){

    const {setIsOpenModal, isOpenModal, children}=props;

    return(
        <Transition appear show={isOpenModal} as={Fragment}>
            <Dialog as="div" className="relative z-[99]" onClose={()=>setIsOpenModal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80" />
                </Transition.Child>
                <div className="fixed left-0 top-0 z-[1055] h-full w-full 
                overflow-y-auto overflow-x-hidden outline-none">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl 
                                    bg-white p-6 text-left align-middle shadow-xl transition-all 
                                    min-[740px]:mx-auto min-[740px]:mt-7 min-[740px]:min-h-[calc(100%-3.5rem)] 
                                    min-[740px]:max-w-[658px]">
                                <button
                                    type="button"
                                    className="box-content rounded-none border-none absolute top-4 right-4 z-10"
                                    data-te-modal-dismiss
                                    aria-label="Close" onClick={()=>setIsOpenModal(false)}>
                                    <img src="images/CloseButton-modalcontent.svg" alt="img" />
                                </button>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
        
    );
}