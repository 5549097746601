import React, { useEffect, useState } from 'react'
import FaqModal from './faqModal';
import TermsAndConditionModal from './termsAndConditionModal';
import EditFaqModal from './editFaqModal';
import disableFieldsBasedOnAuctionStatus from '../../../disableFieldsBasedOnAuctionStatus';

export default function SetUpSupport({auctionData, setAuctionData, setEditedAuctionData}) {
    const [isFaqOpen,setIsFaqOpen] = useState(false)
    const [isFaqEditOpen,setIsFaqEditOpen] = useState(false)
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [isModalEditOpen,setIsModalEditOpen] = useState(false)
    const [addSection, setAddSection] = useState(false)
    const [termsAndCondition,setTermsAndCondition] = useState('')
    const [sections, setSections] = useState([
        { question: '', answer: '' }
    ])

    useEffect(() => {
        if (auctionData.terms_and_condition) {
            setTermsAndCondition(auctionData.terms_and_condition)
        }
    }, [auctionData.terms_and_condition])
    
    /**
     * The function `handleAddFAQ` adds FAQ items to the `auctionData` state, closes the FAQ modal, and
     * resets the sections state.
     */
    const handleAddFAQ = () => {
        sections.forEach((item)=>{
            
            if(item.question!=='' && item.answer!==''){
                setAuctionData((prevState) => ({
                    ...prevState,
                    faq: [...prevState.faq, item],
                }));
                
            }
        })
        setIsFaqOpen(false)
        setAddSection(false)
        setSections([
            { question: '', answer: '' }
        ])
    };
    /**
     * The function `handleEditFAQ` updates the `faq` property of `auctionData` with the `sections`
     * array, closes the FAQ edit modal, and resets the `addSection` flag and `sections` array.
     */
    const handleEditFAQ = () => {
        setAuctionData((prevState) => ({
            ...prevState,
            faq: [...sections],
        }));
        
        setIsFaqEditOpen(false)
        setAddSection(false)
        // setSections([
        //     { question: '', answer: '' }
        // ])
    }
    /**
     * Function to return the disabled state 
     * from disabled fields maping based on it's
     * auction status
     * @param {*} field 
     * @returns 
     */
    const returnDisableState = (field)=>{
        let statusCheck = false;
        let status = auctionData?.status ? auctionData.status : '';
        if(status)
            status = status !== 'Accepting bids' ? status.toLowerCase() : 'acceptingBids'
        switch(field) {
        case 'termsAndCondition': {
            statusCheck = disableFieldsBasedOnAuctionStatus[`${status}`]?.termsAndCondition;
            break;
        }
        default:
            break;
        }
        return statusCheck;
    }

    const handleTermAndConditionsChange = () => {
        setAuctionData(prevData=> ({...prevData, 
            terms_and_condition: termsAndCondition}))
        setEditedAuctionData(prevData=> ({...prevData, 
            terms_and_condition: termsAndCondition}))
    }

    return (
        <div className='support-content'>
            <div className='flex justify-between flex-wrap items-center pt-4'>
                <p className='font-medium pb-1 text-[#343434] text-[12px] leading-normal'>FAQs</p>
                <div className='ml-auto'>
                    <button id='view-faq' type='button' onClick={()=>setIsFaqOpen(true)}
                        className='font-medium text-[#343434] border border-[#A1A1A9] rounded-lg px-[16px] 
                    py-[5px] text-[14px] leading-normal'
                        data-te-toggle='modal'
                        data-te-target='#exampleModalXl2'
                        data-te-ripple-init
                        data-te-ripple-color='light'>
                        View
                    </button>
                    <button id='edit-faq' type='button' disabled={auctionData.faq.length===2} onClick={()=>setIsFaqEditOpen(true)}
                        className='font-medium text-[#343434] border ml-2 border-[#A1A1A9] rounded-lg 
                    px-[16px] py-[5px] text-[14px] leading-normal disabled:bg-[#D4D4D8] disabled:text-[#FFFFFF] 
                    disabled:border-transparent'
                        data-te-toggle='modal'
                        data-te-target='#exampleModalXl2edit'
                        data-te-ripple-init
                        data-te-ripple-color='light'>
                        Edit
                    </button>
                </div>
            </div>
            <div className='flex justify-between flex-wrap items-center pt-4'>
                <p className='font-medium pb-1 text-[#343434] text-[12px] leading-normal'>Terms and conditions</p>
                <div className='ml-auto'>
                    <button id='view-terms' type='button' onClick={()=>setIsModalOpen(true)} 
                        className='font-medium text-[#343434] border border-[#A1A1A9] rounded-lg px-[16px] 
                    py-[5px] text-[14px] leading-normal'
                        data-te-toggle='modal'
                        data-te-target='#exampleModalLg'
                        data-te-ripple-init
                        data-te-ripple-color='light'>
                        View
                    </button>
                    <button type='button' id='terms-button'
                        disabled={ returnDisableState('termsAndCondition')} 
                        onClick={()=>{setIsModalOpen(true);setIsModalEditOpen(true)}}
                        className='font-medium text-[#343434] border ml-2 border-[#A1A1A9] rounded-lg px-[16px] 
                    py-[5px] text-[14px] leading-normal disabled:bg-[#D4D4D8] disabled:text-[#FFFFFF] disabled:border-transparent'
                    >
                        Edit
                    </button>
                </div>
            </div>
            <FaqModal auctionData={auctionData} setAuctionData={setAuctionData} 
                isFaqOpen={isFaqOpen} setIsFaqOpen={setIsFaqOpen} sections={sections} 
                setSections={setSections} handleAddFAQ={handleAddFAQ} addSection={addSection} 
                setAddSection={setAddSection}/> 
            <EditFaqModal auctionData={auctionData} setAuctionData={setAuctionData}
                isFaqEditOpen={isFaqEditOpen} setIsFaqEditOpen={setIsFaqEditOpen} sections={sections} 
                setSections={setSections} handleEditFAQ={handleEditFAQ} addSection={addSection} 
                setAddSection={setAddSection}/>  
            <TermsAndConditionModal auctionData={auctionData} setAuctionData={setAuctionData}
                isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} isModalEditOpen={isModalEditOpen}
                setIsModalEditOpen={setIsModalEditOpen} setEditedAuctionData={setEditedAuctionData}
                setSections={setSections} handleAddFAQ={handleAddFAQ} addSection={addSection} 
                setAddSection={setAddSection} termsAndCondition={termsAndCondition} setTermsAndCondition={setTermsAndCondition}
                onTermsAndConditionChange={handleTermAndConditionsChange}/>
        </div> 
    )
}
