import React, { useCallback, useEffect, useState } from 'react';
import Debounce from '../../CommonComponent/debounce';
import Filter from './filter';

export default function TableHeader(props) {

    const { searchParams, handleSearch, message, salesInfo,setIsOpen,exportHandler,sales,handleFilter,
        date, setDate,toDate,setToDate, selectedStatus, handleExport,
        selectedType,sendToDate,setsendToDate,sendFromDate,setsendFromDate,
        setSelectedStatus,
        setSelectedType,isFilterApplied,setSearchParams,
        setIsFilterApplied} =props;
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword')!==null? 
        decodeURIComponent(searchParams.get('keyword')):'')
   
    const titleRegex = /^(?![\s]).*$/;


    /**
     * The handleChange function checks if a value matches a regular expression, and if it does, it
     * sets the search value and calls another function with the search value as a parameter.
     * @returns If the value does not pass the `titleRegex` test, nothing is being returned.
     */
    const handleChange = (e,value)=>{
        handleSearch(e,'keyword',value)

    }
    useEffect(() => {
        setSearchValue(
            searchParams.get('keyword')
                ? decodeURIComponent(searchParams.get('keyword'))
                : ''
        );
    }, [location.search]);

    const changeHandler=(e)=>{
        if(titleRegex.test(e.target.value)) {
            setSearchValue(e.target.value)
        }else{
            return;
        }
    }

    const optimizedFn = useCallback(
        Debounce((e,value) => handleChange(e,value)),
        [searchParams]
    );

    const getFilter=(status,type, fromDate, toDate)=>{
        handleFilter(status,type, fromDate, toDate)
    }

    const resetHandler=(e)=>{
        setIsFilterApplied(false);
        let params = Object.fromEntries(searchParams);
        delete params.start_date
        delete params.end_date
        delete params.payment_status
        delete params.payment_type
        delete params.filter
        setSelectedStatus('')
        setSelectedType('')
        setDate('')
        setsendFromDate('')
        setsendToDate('')
        setToDate('')
        setSearchParams({...params});
    }

    return (
        <>
            <div className="flex justify-between">
                <p className="sm:text-[34px] text-[28px] font-medium leading-normal text-[#343434] pt-2">All Sales</p>
            </div>
            {/* <!-- search filter export content --> */}
            <div className="mt-10 flex justify-between flex-wrap items-center">
                <p className="text-[16px] leading-normal font-medium text-[#343434]"> 
                    <span>{salesInfo?.total_records} </span>
                    {salesInfo.total_records===1 ?  'Sale' :  'Sales' }
                </p>
                <div className="flex ml-auto  flex-wrap items-center">
                    {/* <!-- search  --> */}
                    <div className="relative pr-3 py-[12px]">
                        <input data-testid="search" type="text" debounceTimeout={500}
                            onChange={(e)=>{e.preventDefault(), optimizedFn(e,e.target.value), changeHandler(e)}}
                            className="text-[14px] relative w-[270px] h-[32px] pl-8 pr-3  border border-[#A1A1A9] 
                        placeholder:text-[#A1A1A9] focus:border-[#343434] outline-none font-medium leading-normal text-[#4A4A4A]" 
                            placeholder="Search sales" value={searchValue}/>
                        <img src="/images/search-icon-large.svg" alt="search-icon" 
                            className="absolute top-5 cursor-pointer left-2.5" />
                    </div>
                    {/* <!-- filter --> */}

                    < Filter
                        searchParams={searchParams} 
                        setSearchParams={setSearchParams}
                        getFilter={getFilter}
                        selectedStatus={selectedStatus} 
                        selectedType={selectedType}
                        setSelectedStatus={setSelectedStatus}
                        setSelectedType={setSelectedType}
                        date={date}
                        setDate={setDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        sendToDate={sendToDate}
                        setsendToDate={setsendToDate}
                        sendFromDate={sendFromDate}
                        setsendFromDate={setsendFromDate}
                    />

                    {/* <!-- export button --> */}
                    {sales.length!==0 && <div className="relative cursor-pointer" onClick={()=>setIsOpen(true)}>
                        <button type="button" id='export-allsales' 
                            className="text-[14px] sm:block hidden text-[#52525A] border w-[89px] h-[32px] 
                            border-[#A1A1A9] rounded-[6px] leading-normal  font-medium pr-[20px] pl-[34px]"  
                        >
                            Export
                        </button>
                        <button type="button" 
                            className="text-[14px] sm:hidden block text-[#52525A] border 
                            w-[35px] h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium "  
                        >   
                        </button>
                        <img src="/images/export-icon.svg" alt="export-icon" className="absolute top-[7px] left-2.5" />
                    </div>}
                </div>
            </div>
            {/* <!-- filter applied content --> */}
            {isFilterApplied && <div className="filter-applied ">
                <div className="flex justify-end items-center">
                    <p className="text-[14px] text-[#343434] leading-normal pr-2 font-medium">Filter applied</p>
                    <button className="underline text-[14px] text-[#343434] leading-normal font-medium 
                    border-l border-[#343434] pl-2" onClick={(e)=>resetHandler(e)} >Reset</button>
                </div>
            </div>}
            {/* <!-- success message for export csv file --> */}
            {/* {message!=="" && 
            <div className="mt-[15px] px-5 success-msg-csv py-3 bg-[#A8D9C8] border border-[#489892] rounded-[6px]">
                <div className="flex items-center">
                    <img src="/images/verify-account.svg" alt="img" className="pr-3" />
                    <div>
                        <p className="text-[12px] leading-normal font-bold text-[#343434]">{message}</p>
                    </div>
                </div>
            </div>} */}
        </>
    )
}
