import React, { useState, useEffect } from 'react';
import { dataService } from '../../../../services/dataService';
// import Shimmer from './shimmer';
import { useParams } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';


function DashboardAuction({auctionData}) {
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    async function fetchDashboardUrl(auctionData) {
        const auction_id= auctionData.auction_id;
        try {
            setIsLoading(true);
            const response = await dataService.AuctionInsights(auction_id);

            const embedUrl = response.data.data.embed_url;

            setDashboardUrl(embedUrl);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard URL:', error);
            setError(`Failed to load dashboard: ${error.message || 'Unknown error'}`);
            setIsLoading(false);
        }
    }



    useEffect(() => {
        if(Object.keys(auctionData).length > 0){
            fetchDashboardUrl(auctionData);
        }
    }, [auctionData]);

   
    return (
        <>
            {isLoading==true ? (
                <div className="flex justify-center items-center h-[500px]">
                    <Triangle
                        height="80"
                        width="80"
                        color="#000"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>  
            ) : (
                <div style={{ width: '100%', height: '1600px' }} className='border-[#DEDEDE] border rounded-lg'>
                    <iframe
                        title="QuickSight Dashboard"
                        width="100%"
                        height="100%"
                        src={dashboardUrl}
                        frameBorder="0"
                        data-testid="quick-sight-dashboard"
                        allowFullScreen
                    />
                </div>
            )}
        </>
    );
}

export default DashboardAuction;