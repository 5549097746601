/* eslint-disable max-len */
import React from 'react';
import SalesRow from './tableRow';
import SalesShimmer from './salesShimmer';

export default function ListingBody({ handleSort, currentSortColumn, handleOnClickPageNumber,searchParams,
    loading,sales, setLoader,salesInfo, id,saleLoading, onChangePaymentInfo}) {

    // function formatDate(timestamp) {
    //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    //     const date = new Date(timestamp * 1000);
        
    //     // Get day, month, and year
    //     const day = date.getDate();
    //     const month = months[date.getMonth()];
    //     const year = date.getFullYear();
        
    //     // Format the date
    //     const formattedDate = `${day} ${month} ${year}`;
        
    //     return formattedDate;
    // }
    
    // console.log(searchParams.get('keyword')!== null && sales.length===0 && !loading , 'jjj')
  

    return (
        <div> 
            <div className="customScrollbar pt-2 overflow-x-auto mb-[135px] pb-[20px] mt-2">
                <div className="z-10 border-[#DEDEDE] border rounded-lg xl:w-fit 2xl:w-full w-fit" 
                    style={{ transform: 'rotateX(180deg)' }}>
                    <table aria-label="Outside Table" className="w-full overflow-auto table-auto" 
                        style={{ transform: 'rotateX(-180deg)' }}>
                        <thead>
                            <tr className="bg-[#FFFFFF] border-b rounded-lg border-[#DEDEDE] h-12">
                                <th scope="col" className="rounded-lg px-4 py-3 min-w-[50px]">
                                    <span data-testid='check' className="flex items-center justify-start">
                                        <input type='checkbox' />
                                    </span>
                                </th>
                                <th scope="col" className="rounded-lg px-4 py-3 min-w-[130px]">
                                    <span data-testid='order_no' className="flex items-center justify-start" 
                                    >
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1 ">Order no.</p>
                                       
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[180px]">
                                    <span id='customer_name' className="flex items-center justify-start" 
                                        onClick={(e) => {handleSort(e,'name')}}
                                    >
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1 cursor-pointer">Customer name</p>
                                        {currentSortColumn.key==='name' ? 
                                            (currentSortColumn.order==='ascending'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>

                                <th scope="col" className=" px-4 py-3 min-w-[260px]">
                                    <span id='auction_name' className="flex items-center justify-start" 
                                        onClick={(e) => {handleSort(e,'auction_title')}}
                                    >
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1 cursor-pointer">Auction name</p>
                                        {currentSortColumn.key==='auction_title' ? 
                                            (currentSortColumn.order==='ascending'?
                                                <img src="/images/dropdown-icon.png" alt="img" 
                                                    className=" dropdown-downwords3 cursor-pointer" />:
                                                <img src="/images/dropdown-upwords.svg" alt="img" 
                                                    className="dropdown-upwords3 cursor-pointer" />
                                            ):
                                            (<img src="/images/dropdown-icon.png" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer " />)
                                        }
                                    </span>
                                </th>
                               
                                <th scope="col" className=" px-4 py-3 min-w-[180px]">
                                    <span data-testid='created_at' className="flex items-center justify-start" 
                                    >
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                            pr-1 py-1">Date</p>
                                       
                                    </span>          
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[155px] rounded-lg">
                                    <span data-testid='order_amount' className="flex items-center justify-startr" 
                                    >
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1">Result</p>
                                      
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[155px] rounded-lg">
                                    <span data-testid='payment' className="flex items-center justify-start">
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1">Payment type</p>
                                    </span>
                                </th>
                                <th scope="col" className=" px-4 py-3 min-w-[170px] rounded-lg">
                                    <span data-testid='payment_status' className="flex items-center justify-center">
                                        <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1">Payment status</p>
                                    </span>
                                </th>
                                <th scope="col" className="px-4 py-3 sm:min-w-[100px] min-w-9"></th>
                            </tr>
                        </thead>
                        {/* {(searchParams.get('keyword')=== null && !loading) &&
                            <tbody>
                                <tr>
                                    <td colSpan="8" className="sm:text-center text-start sm:pl-0 xs:pl-[200px]
                                     pl-[100px] border-[#DEDEDE] border ">
                                        <div className="h-[200px] mt-[100px]">
                                            <p className="text-[18px] font-medium  leading-normal text-[#34343">No sales</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }  */}
                        {(searchParams.get('keyword')!== null && sales.length===0 && !loading ) && 
                        <tr>
                            <td
                                colspan="8"
                                className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]"
                            >
                                <div className="h-[180px] mt-[100px]">
                                    <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                        No search results for 
                                        ‘{decodeURIComponent(searchParams.get('keyword')).length>30 ? 
                                            decodeURIComponent(searchParams.get('keyword')).slice(0,30)+'...' : 
                                            decodeURIComponent(searchParams.get('keyword'))}’</p>
                                </div>
                            </td>
                        </tr>
                        }
                        {
                            (searchParams.get('keyword')=== null && !loading && sales.length===0) && <tr className="">
                                <td colSpan="8" className="sm:text-center text-start sm:pl-0 
                                        xs:pl-[200px] pl-[100px]">
                                    <div className="h-[180px] mt-[100px]">
                                        <p
                                            className="text-[18px] font-medium  leading-normal 
                                                        text-[#34343 mb-4"
                                        >
                                            No sales found
                                        </p>
                                               
                                    </div>
                                </td>
                            </tr>
                        }   
                        {!loading?
                            <tbody>
                                {(sales?.length > 0 && !saleLoading) ? (
                                    <SalesRow
                                        onChangePaymentInfo={onChangePaymentInfo}
                                        sales={sales}
                                    />
                                ) : (
                                    <>
                                        {/* {(searchValue !=='' && !saleLoading) ? 
                                            renderNoSearchResultsMessage(searchValue) : null}
                                        {(searchValue==="" && !bidderLoading) ? renderNoBiddersMessage() : null} */}
                                    </>
                                )}
                            </tbody>
                            :<SalesShimmer />}
                    </table>
                </div>
            </div>
        </div>
    );
}
