/* eslint-disable max-len */
import { Menu } from "@headlessui/react"
import { Link, useParams } from "react-router-dom"
import { dataService } from "../../../../../services/dataService"
import { useEffect, useState } from "react"
import moment from "moment"
import BidderDetailsShimmer from "./bidderDetailsShimmer"

export default function BidderDetails() {
    const {bidderId} = useParams()
    const [bidderData, setBidderData] = useState({})
    const [loading, setLoading]=useState(true)

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to make an API call to retrieve bidder details and update the
    `bidderData` state with the fetched data. */
    useEffect(()=>{
        BidderDetailsApiCall()
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    },[])

    /**
     * The function `BidderDetailsApiCall` makes an API call to retrieve bidder details and sets the
     * fetched data to the `bidderData` state.
     */
    const BidderDetailsApiCall = async() => {
        const bidderDataFromAPI = await dataService.BidderDetails(bidderId)
        setBidderData(bidderDataFromAPI.data)
    }

    return (
        <div class="sm:px-10 px-5 sm:py-8 pt-[44px] pb-20">
            <div class="flex justify-between items-center sm:flex-row flex-col">
                <p class="sm:text-[34px] text-[20px]  font-medium leading-normal text-[#343434] sm:pt-0">Bidder Information</p>
                <div className="h-fit sm:shadow-none shadow-bs7 ">
                    <span className="flex justify-end ">
                        <Menu as="div" className="flex items-center relative">
                            <Menu.Button className="relative" data-te-dropdown-ref>
                                <img src="/images/three-dots-icon-black.svg" alt="img" className="cursor-pointer"   type="button"
                                    id="dropdownMenuButton1"
                                    data-te-dropdown-toggle-ref
                                    aria-expanded="false"/>
                            </Menu.Button>
                            <Menu.Items
                                className="absolute top-[30px] right-0 z-50 float-left m-0 w-[145px] py-2 min-w-max list-none 
                            overflow-hidden 
                                rounded-[10px] border-none bg-white bg-clip-padding text-left text-base shadow-bs3 
                                [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton2">
                                <Menu.Item>
                                    <a className="cursor-pointer block w-full whitespace-nowrap bg-transparent pl-5 pr-7 
                                    py-1.5 text-[12px] 
                                        font-medium text-[#343434]"
                                    >Block Bidder</a>
                                </Menu.Item>         
                            </Menu.Items>
                        </Menu>
                    </span>  
                </div> 
            </div>
            {loading ? <BidderDetailsShimmer/> : <div>
                <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                    <p className="pt-[20px] pb-[22px] border-b border-[#CECECE] sm:px-8 px-4 text-[16px] font-medium 
                    leading-normal text-[#343434]">Details</p>
                    <div className="flex flex-wrap my-[27px] w-full xxs:mx-4">
                        <div className="lg:w-[30%] xxs:w-[50%] w-full px-4 mb-[24px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Name</p>
                            <p className="text-[16px] pt-1 font-medium leading-normal text-[#343434] break-all">
                                {bidderData?.first_name+' '+bidderData?.last_name}
                            </p>
                        </div>
                        <div className="lg:w-[38%] xxs:w-[50%] w-full px-4 mb-[24px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Email</p>
                            <p className="text-[16px] pt-1  font-medium leading-normal text-[#343434] break-all">
                                {bidderData?.email_address}
                            </p>
                        </div>
                        <div className="lg:w-[30%] xxs:w-[50%] w-full px-4 mb-[24px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Account created</p>
                            <p className="text-[16px] pt-1  font-medium break-words leading-normal text-[#343434]">
                                {moment(bidderData?.created_at).format('DD MMM YYYY / HH:mm')}
                            </p>
                        </div>
                        <div className="lg:w-[30%] xxs:w-[50%] w-full px-4 mb-[24px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Marketing communication</p>
                            <p className="text-[16px] pt-1  font-medium leading-normal text-[#343434]">
                                {bidderData.marketing ? 'Subscribed' : 'Unsubscribed'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                    <p className="pt-[20px] pb-[22px] border-b border-[#CECECE] sm:px-8 px-4 text-[16px] font-medium 
                    leading-normal text-[#343434]">Address</p>
                    {bidderData?.address?.address_line1 !== '' ? <div className="m-[25px] rounded-[8px] border-[#DEDEDE] border">
                        <p className="pt-[22px] sm:px-8 px-4 text-[14px]
                    leading-normal text-[#343434]">{bidderData?.address?.address_line1}</p>
                        <p className="sm:px-8 px-4 text-[14px]
                    leading-normal text-[#343434]">{bidderData?.address?.address_line2}</p>
                        <p className="sm:px-8 px-4 text-[14px]
                    leading-normal text-[#343434]">{bidderData?.address?.["town/city"]}</p>
                        <p className="sm:px-8 px-4 text-[14px]
                    leading-normal text-[#343434]">{bidderData?.address?.county}</p>
                        <p className="sm:px-8 px-4 text-[14px]
                    leading-normal text-[#343434]">{bidderData?.address?.country}</p>
                        <p className="pb-[22px] sm:px-8 px-4 text-[14px]
                    leading-normal text-[#343434]">{bidderData?.address?.postal_code}</p>
                    </div> : <div className="flex justify-center items-center m-[25px] h-[193px]"><p>No addresses added</p></div>}
                </div>
            </div>}
        </div>
    )
}