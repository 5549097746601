/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Oval } from  'react-loader-spinner'
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config/index';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { timeDifference, timeZoneMap } from '../../../CommonComponent/staticValues';

export default function PublishAuctionModal({publishModalOpen, setPublishModalOpen, handlePublishAuction, loader, noLot,loading,handleFetch,
    selectedId, auctionData, lotInfo, isRequiredFieldEmpty, setIsRequiredFieldEmpty, isPublished, setIsPublished, domain, notConnect}) {
    const Navigate=useNavigate();
    const [startDate,setStartDate] = useState('')
    const [startMin,setStartMin] = useState('')
    const [endDate,setEndDate] = useState('')
    const [endMin,setEndMin] = useState('')
    const [end,setEnd] = useState(false);
    
    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to format the start and end dates of an auction and set them in the
    component's state variables (`startDate`, `startMin`, `endDate`, `endMin`). */
    const convertTimestampToDayMonthYear = (timestamp, time_zone) => {
        const isSafari = navigator.userAgentData && navigator.userAgentData.brands.some(brand => brand.brand === 'Safari');

        if(timestamp==0){
            return '-';
        }
        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            timeZone: timeZoneMap[time_zone ? time_zone : 'Etc/GMT']
        };
        const formatter = new Intl.DateTimeFormat("en-GB", options);

        const start_date = new Date(timestamp);
        const formattedDate = formatter.format(start_date);
        const [date1, time] =formattedDate.includes('at') ? formattedDate.split("at ") : formattedDate.split(", ");
        if(date1.includes('1970') || date1==""){
            return '-'
        }
    
        return `${date1} / ${time} ${time_zone === '' ? 'GMT' : time_zone.split(' - ')[0]} ${timeDifference[time_zone === '' ? 'GMT - Greenwich Mean Time' : time_zone]}`;
    };
    
    const calculateTimeRemaining = () => {
        const now = new Date();
        let timeDifference = new Date(auctionData?.start_date) - now;
        if (timeDifference <= 0) {
            timeDifference = new Date(auctionData?.end_date) - now;
            setEnd(true);
            if(timeDifference <= 0){
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        }
    
        const pad = (num) => String(num).padStart(2, "0");
        const seconds = Math.floor((timeDifference / 1000) % 60);
        const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
        return { days, hours, minutes, seconds };
    };
    
    const [timeRemaining, setTimeRemaining] = useState();
    const [copied, setCopied] = useState(false);

    const handleCopyLink = () => {
        // Copy the auction URL to the clipboard
        navigator.clipboard.writeText(`https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}`)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false)
                }, 800);
            })
            .catch((error) => {
                console.error('Failed to copy URL: ', error);
            });
    };
    const shareOnSocialMedia = (type) => {
        // Define the URL and content you want to share
        const shareURL = `https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}`; // URL to be shared
        const shareText = 'Check out this live auction!'; // Custom sharing text
        const shareTitle = 'Live Auction Details'; // Custom sharing title
        if(type=='facebook'){        
            const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}&quote=${encodeURIComponent(shareText)}`;
            window.open(facebookShareURL, '_blank');
        }
        else if(type=='linkedin'){
            const linkedInShareURL = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareURL)}&title=${encodeURIComponent(shareTitle)}&summary=${encodeURIComponent(shareText)}`;
            window.open(linkedInShareURL, '_blank');
        }
        else{
            const twitterShareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareURL)}`;
            window.open(twitterShareURL, '_blank');
        }
    };
    useEffect(() => {
        let timer;
        if(isPublished){
            timer = setInterval(() => {
                setTimeRemaining(calculateTimeRemaining());
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isPublished]);

    const handleEdit=()=>{
        if(notConnect=="verification"){
            Navigate('/account')
        }else if(notConnect=="stripe"){
            Navigate('/account?active=payment-setup')
        }else{
            if(auctionData.template_name===""){
                localStorage.setItem('selectedTemplateId', '2')
            } else{
                if(auctionData.template_name==='Classic')
                {
                    localStorage.setItem('selectedTemplateId', '1')
                }else{
                    if(auctionData.template_name==='Single lot' || auctionData.template_name==='Single Lot'){
                        localStorage.setItem('selectedTemplateId', '2')
                    } else{
                        (localStorage.setItem('selectedTemplateId', '3'))
                    }
                }
            }
            if((auctionData.auction_image =="" || auctionData.title=="" || auctionData.description=="" || auctionData.currency=="" ) || 
                (auctionData.start_date =="" || auctionData.end_date=="" || auctionData.time_zone=="" || auctionData.start_date ==null || auctionData.end_date==null ) || 
                (((auctionData.extension_type =="Cascade" || auctionData.extension_type =="Individual lots") 
                && auctionData.extension_time_between_lots=="") || auctionData.extension_time=="" || auctionData.registration_type=="" 
                || ((auctionData.add_buyer_fees =="Add percentage"  && auctionData.percentage=="") || ( auctionData.add_buyer_fees=="Add fixed fee" 
                && auctionData.fees=="" ) ))){
                Navigate(`/auctions/edit/${auctionData._id}/${selectedId}/${auctionData.template_name===''?'single-lot':(auctionData.template_name==='Classic'?'classic':((auctionData.template_name==='Single lot' || auctionData.template_name==='Single Lot')?'single-lot':'standalone'))}`,{state: auctionData===undefined?'':auctionData})
            }else{
                setIsRequiredFieldEmpty(false);
                setPublishModalOpen(false);
                setIsPublished(false)
                Navigate(`/auctions/${auctionData._id}/${selectedId}/lots`)
            }
        }
        
    }

    return (
        <Transition appear show={publishModalOpen|| isRequiredFieldEmpty || isPublished} as={Fragment}>
            <Dialog as="div" className="relative z-[99]" onClose={()=>{setPublishModalOpen(false); handleFetch()}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80" />
                </Transition.Child>
                <div className="fixed left-0 top-0 z-[1055] h-full w-full 
                    overflow-y-auto overflow-x-hidden outline-none">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl 
                                    bg-white sm:pt-[58px] pt-[46px] pb-[33px] md:px-[70px] sm:px-10 px-5 text-left 
                                    align-middle shadow-xl transition-all 
                                    min-[640px]:mx-auto min-[640px]:mt-7 min-[640px]:min-h-[calc(100%-3.5rem)] 
                                    min-[640px]:max-w-[740px]">
                                <button
                                    type="button" data-testid="close-modal"
                                    className="box-content rounded-none border-none absolute top-4 right-4 z-10"
                                    data-te-modal-dismiss
                                    aria-label="Close" 
                                    onClick={()=>{setPublishModalOpen(false), setIsPublished(false), 
                                    setIsRequiredFieldEmpty(false)}}>
                                    <img src="/images/CloseButton-modalcontent.svg" alt="img" loading="lazy"/>
                                </button>
                                {!isRequiredFieldEmpty && !isPublished &&
                                <div className="relative">
                                    <h2 className='text-[22px] leading-normal text-[#343434] pb-2.5 text-center'>
                                        You are about to publish your auction.</h2>
                                    <p className="md:px-10 sm:px-5 text-center sm:pb-[31px] pb-4 leading-normal sm:text-[16px] 
                                    text-[14px] text-[#A1A1A9] font-medium">Edits to the currency, auction rules, 
                                        and Terms & Conditions are not possible once the auction is published.</p>
                                    <div className='border border-[#E6E6E6] sm:pt-5 pt-4 sm:pb-7 pb-5 px-[30px] rounded-lg 
                                    flex flex-wrap mb-6'>
                                        <div className='sm:w-1/2 w-full sm:pb-5 pb-3'>
                                            <label className='text-[#343434] text-[10px] leading-normal font-medium pb-2'>
                                                Title</label>
                                            <p className='text-[16px] text-[#343434] leading-normal font-medium'>
                                                {auctionData.title===''?'-':auctionData.title}</p>
                                        </div>
                                        <div className='sm:w-1/2 w-full sm:pb-5 pb-3'>
                                            <label className='text-[#343434] text-[10px] leading-normal font-medium pb-2'>
                                                Number of lots</label>
                                            <p className='text-[16px] text-[#343434] leading-normal font-medium'>
                                                {lotInfo.total_lots}</p>
                                        </div>
                                        <div className='sm:w-1/2 w-full sm:pb-5 pb-3'>
                                            <label className='text-[#343434] text-[10px] leading-normal font-medium pb-2'>
                                                Bidding Start Date/Time</label>
                                            <p className='text-[16px] text-[#343434] leading-normal font-medium'>
                                                {convertTimestampToDayMonthYear(auctionData.start_date, auctionData.time_zone)}</p>
                                        </div>
                                        <div className='sm:w-1/2 w-full sm:pb-5 pb-3'>
                                            <label className='text-[#343434] text-[10px] leading-normal font-medium pb-2'>
                                                Bidding End Date / Time</label>
                                            <p className='text-[16px] text-[#343434] leading-normal font-medium'>
                                                {convertTimestampToDayMonthYear(auctionData.end_date, auctionData.time_zone)}</p>
                                        </div>
                                        <div className='w-full'>
                                            <label className='text-[#343434] text-[10px] leading-normal font-medium pb-2'>
                                                Auction URL</label>
                                            <p className='text-[16px] text-[#343434] leading-normal font-medium break-all' id="published_url">
                                                {`https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}`}</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <button id="cancel-auction" 
                                            className="px-[31px] py-[12px] border border-[#A1A1A9] 
                                            bg-[#fffff]  text-[#282828] leading-normal 
                                            font-medium text-[14px] rounded-[6px] hover:bg-[#A1A1A9] hover:text-[#FFFFFF" 
                                            onClick={()=>{setPublishModalOpen(false), setIsPublished(false), 
                                            setIsRequiredFieldEmpty(false)}}>
                                            Cancel
                                        </button>
                                        <a className='ml-[20px]'>
                                            <button data-testid="confirm_publish"
                                                disabled={loader || loading}
                                                className="px-[31px] py-[12px] bg-[#282828]  text-[#FFFFFF] 
                                                leading-normal font-medium text-[14px] rounded-[6px] flex justify-center 
                                                disabled:bg-[#D4D4D8] hover:bg-[#3F3F45]" 
                                                onClick={()=>handlePublishAuction(selectedId)}>
                                                Publish
                                                {loader&& 
                                                <Oval
                                                    height={20}
                                                    width={50}
                                                    color="#FFFFFF"
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#000000"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                />
                                                }
                                            </button>
                                        </a>
                                    </div>
                                </div>}
                                {!isRequiredFieldEmpty && isPublished &&
                                <div className='relative'>
                                    <div className='flex justify-center pb-[5px]'>
                                        <img src='/images/publishSuccess.svg' alt='icon' />
                                    </div>
                                    <h2 data-testid="publishedMsg" className='text-[22px] leading-normal text-[#343434] text-center'>
                                        Your auction is 
                                        <span className='text-[#B98B47]'> published</span></h2>  
                                    <p className='text-[14px] text-[#282828] leading-normal sm:pt-6 pt-4 pb-[5px] text-center'>
                                        Bidding {end ? "ends" : 'starts'} in</p> 
                                    <div className='flex justify-center text-center'>
                                        <div className='border-r stroke-[#67676c] stroke-1 sm:pr-7 sm:mr-7 pr-4 mr-4'>
                                            <p className='text-[#282828] sm:text-[34px] text-[25px] font-medium pb-[5px] min-w-[50px] text-center'>
                                                {timeRemaining?.days===undefined?<Skeleton/>:String(timeRemaining?.days).padStart(2,'0')}</p>
                                            <span className='text-[#717179] text-[12px] font-medium leading-normal'>DAYS</span>
                                        </div>
                                        <div className='border-r stroke-[#67676c] stroke-1 sm:pr-7 sm:mr-7 pr-4 mr-4'>
                                            <p className='text-[#282828] sm:text-[34px] text-[25px] font-medium pb-[5px] min-w-[50px] text-center'>
                                                {timeRemaining?.hours===undefined?<Skeleton/>:String(timeRemaining?.hours).padStart(2,'0')}</p>
                                            <span className='text-[#717179] text-[12px] font-medium leading-normal'>HRS</span>
                                        </div>
                                        <div className='border-r stroke-[#67676c] stroke-1 sm:pr-7 sm:mr-7 pr-4 mr-4'>
                                            <p className='text-[#282828] sm:text-[34px] text-[25px] font-medium pb-[5px] min-w-[50px] text-center'>
                                                {timeRemaining?.minutes===undefined?<Skeleton/>:String(timeRemaining?.minutes).padStart(2,'0')}</p>
                                            <span className='text-[#717179] text-[12px] font-medium leading-normal'>MINS</span>
                                        </div>
                                        <div className=''>
                                            <p className='text-[#282828] sm:text-[34px] text-[25px] font-medium pb-[5px] min-w-[50px] text-center'>
                                                {timeRemaining?.seconds===undefined?<Skeleton/>:String(timeRemaining?.seconds).padStart(2,'0')}</p>
                                            <span className='text-[#717179] text-[12px] font-medium leading-normal'>SECS</span>
                                        </div>
                                    </div>


                                    <div className='p-5 flex border rounded-lg border-[#E6E6E6] mt-10 mb-2.5'>
                                        <img src={`${config.Storage.CDN}${auctionData.auction_image}`} 
                                            alt='icon' loading='lazy' className='w-[106px] h-[86px] object-cover mr-[17px]' />
                                        <div>
                                            <p data-testid='auction-name' className='text-[#343434] font-medium leading-normal text-[16px]'>
                                                {auctionData.title}</p>
                                            <p data-testid="time" className='text-[#343434] font-medium leading-normal text-[10px]'>
                                                {startDate === '' ? '-' : (`${startDate} / 
                                                    ${startMin} ${auctionData.time_zone === '' ? 'GMT' : 
                                                        auctionData.time_zone.split(' - ')[0]}`)} - 
                                                {endDate === '' ? '-' : (`${endDate} / 
                                                    ${endMin} ${auctionData.time_zone === '' ? 'GMT' : 
                                                        auctionData.time_zone.split(' - ')[0]}`)}</p>
                                        </div>
                                    </div>   
                                    <div className='border border-[#E6E6E6] flex justify-between sm:px-2.5 px-5 
                                    rounded-lg sm:flex-row flex-col sm:pb-0 pb-[15px]'>
                                        <p className='text-[#343434] text-[14px] font-medium leading-normal sm:pl-3 py-[15px]'>
                                            Share the auction</p>
                                        <div className='flex items-center'>                                            
                                            <img src='/images/facebookShare.svg' alt='icon' loading='lazy' className='pr-5 
                                                cursor-pointer' onClick={()=>shareOnSocialMedia('facebook')} />
                                            <img src='/images/twitterShare.svg' alt='icon' loading='lazy' className='pr-5 
                                                cursor-pointer' onClick={()=>shareOnSocialMedia('twitter')} />
                                            <img src='/images/linkedInShare.svg' alt='icon' loading='lazy' className='pr-5 
                                                cursor-pointer' onClick={()=>shareOnSocialMedia('linkedin')} />
                                            <div className={`border-l border-[#E6E6E6] flex ${copied?'text-[#41ad86]':'text-[#343434]'} text-[10px] 
                                            font-medium leading-normal w-[100px] items-center pl-2.5 h-full cursor-pointer`} onClick={()=>handleCopyLink()}>
                                                <img src='/images/copyLink.svg' alt='icon' loading='lazy' className='pr-2.5' />
                                                {copied ? 'Link Copied!' : 'Copy link'}
                                            </div>
                                        </div>
                                    </div>    
                                    <div className='flex justify-center'>
                                        <Link to={`https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}`} 
                                            className='w-[145px] h-[46px] border border-[#A1A1A9] 
                                        rounded-md mt-[38px] mb-4 text-center py-2.5' target='_blank' id="view_preview" data-testid="view_preview">
                                            View auction</Link>
                                    </div>                   
                                </div>}
                                {isRequiredFieldEmpty && !isPublished &&
                                <div className=''>
                                    <div className='flex justify-center sm:mb-5 mb-4'>
                                        <img src='/images/RequiredFieldIcon.svg' alt='icon' loading='lazy'/>
                                    </div>
                                    <h3 className='text-[#343434] text-center text-[22px] leading-normal font-medium pb-2.5'>
                                        Required {notConnect==null ? 'fields' : 'actions'} are missing</h3>
                                    {notConnect=="stripe" && <>
                                        <div className='border border-[#E6E6E6] text-[#717179] text-[10px] flex px-7 py-[12px] mx-[110px] rounded-[6px]' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Stripe or PayPal connected
                                        </div>
                                        <p className='text-[#A1A1A9] text-center sm:text-[16px] text-[14px] leading-normal 
                                            font-medium mt-5'>Set up your payment method in your account settings.</p>
                                    </>}
                                    {notConnect=="verification" && <>
                                        <div className='border border-[#E6E6E6] text-[#717179] text-[10px] flex px-7 py-[12px] mx-[110px] rounded-[6px]' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />ID verification
                                        </div>
                                        <p className='text-[#A1A1A9] text-center sm:text-[16px] text-[14px] leading-normal 
                                            font-medium mt-5'>Return to your account settings and complete verification</p>
                                    </>}
                                    {notConnect==null && <><div className='border border-[#E6E6E6] px-7 py-[12px] mx-[110px] rounded-[6px]' >
                                        {(auctionData.auction_image =="" || auctionData.title=="" || auctionData.description=="" || auctionData.currency=="" ) && <div className=' text-[#717179] text-[10px] flex ' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Auction Details
                                        </div>}
                                        {(auctionData.start_date =="" || auctionData.end_date=="" || auctionData.time_zone=="" || auctionData.start_date ==null || auctionData.end_date==null ) && <div className=' text-[#717179] text-[10px] flex ' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Auction Dates
                                        </div>}
                                        {(((auctionData.extension_type =="Cascade" || auctionData.extension_type =="Individual lots") 
                                        && auctionData.extension_time_between_lots=="") || auctionData.extension_time=="" || auctionData.registration_type=="" 
                                        || ((auctionData.add_buyer_fees =="Add percentage"  && auctionData.percentage=="") || ( auctionData.add_buyer_fees=="Add fixed fee" 
                                        && auctionData.fees=="" ) )) && <div className=' text-[#717179] text-[10px] flex ' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Auction Rules
                                        </div>}
                                        {(noLot || lotInfo.total_lots==0) && <div className=' text-[#717179] text-[10px] flex ' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Lot Images
                                        </div>}
                                        {(lotInfo.total_lots==0) && <div className=' text-[#717179] text-[10px] flex ' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Lot Title
                                        </div>}
                                        {(lotInfo.total_lots==0) && <div className=' text-[#717179] text-[10px] flex ' >
                                            <img src='/images/cross-icon-small.svg' alt='icon' loading='lazy' className='mr-2' />Lot Starting Price
                                        </div>}
                                    </div> <p className='text-[#A1A1A9] text-center sm:text-[16px] text-[14px] leading-normal 
                                            font-medium mt-5'>
                                        Return to edit your auction to finalise these details.</p></>}
                                    <div className="flex justify-center sm:pt-10 sm:pb-[40px] pt-8 pb-8">
                                        <button id="cancel-auction" 
                                            className="px-[31px] py-[12px] border border-[#A1A1A9] 
                                            bg-[#fffff]  text-[#282828] leading-normal 
                                            font-medium text-[14px] rounded-[6px]" 
                                            onClick={()=>{setPublishModalOpen(false), setIsPublished(false), 
                                            setIsRequiredFieldEmpty(false)}}>
                                            Cancel
                                        </button>
                                        <a className='ml-[20px]'>
                                            <button data-testid="edit-auction-details"
                                                onClick={()=>{handleEdit()}}
                                                disabled={loader}
                                                className="px-[40px] py-[12px] bg-[#282828]  text-[#FFFFFF] 
                                                leading-normal font-medium text-[14px] rounded-[6px] flex justify-center 
                                                disabled:bg-[#D4D4D8]">
                                                {notConnect=="verification" ? 'Verify ID' : (notConnect=="stripe" ? 'Payment Setup' : 'Edit')}
                                                {loader&& 
                                                <Oval
                                                    height={20}
                                                    width={50}
                                                    color="#FFFFFF"
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#000000"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                />
                                                }
                                            </button>
                                        </a>
                                    </div>
                                </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}