/* eslint-disable security/detect-object-injection */
import { createSlice } from '@reduxjs/toolkit';

const user = (localStorage.getItem('user'));
const initial = user ? true : false;
const initialState={
    email: '',
    password: '',
    emailError: null,
    passwordError: null,
    loggedIn: initial
}

/* The code is creating a Redux slice using the `createSlice` function from the `@reduxjs/toolkit`
library. */
const loginSlice= createSlice({
    name: 'login',
    initialState,
    reducers: {
        setFieldValue: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
        },
        setEmailError: (state, action) => {
            state.emailError = action.payload;
        },
        setPasswordError: (state, action) => {
            state.passwordError = action.payload;
        },
        clearError: (state) => {
            state.emailError = null;
            state.passwordError=null
        },
        clearLoginData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const { setFieldValue, setEmailError, setPasswordError,clearError, clearLoginData } = loginSlice.actions;

export default loginSlice.reducer;