/* eslint-disable max-len */
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

function BidsShimmer() {
    return (
        Array.from({ length: 5 }, (_, index) =>
            <div className="border border-[#DEDEDE] table-row  h-14 " key={index}>
                <td className="px-4 py-4 text-[16px] leading-normal font-medium">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[100px]' />
                    </SkeletonTheme>
                </td>
                <td className="px-4 py-3 text-[16px] text-center leading-normal">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[77px] w-[95px]' />
                    </SkeletonTheme>
                </td>
                <td className=" px-4 py-4 text-[16px] leading-normal">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[150px]' />
                    </SkeletonTheme>
                </td>
                <td className="px-4 py-3 text-[16px] leading-normal">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[150px]' />
                    </SkeletonTheme>
                </td>
                <td className="px-4 py-3 text-[16px] leading-normal">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[160px]' />
                    </SkeletonTheme>
                </td>
                <td className="px-4 py-3 text-[16px] leading-normal rounded-[10px]">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[110px]' />
                    </SkeletonTheme>
                </td>
                <td className="px-4 py-3 text-[16px]  leading-normal rounded-[10px]">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[80px]' />
                    </SkeletonTheme>
                </td>
                <td className="px-4 py-3 text-[16px]  leading-normal rounded-[10px]">
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0">
                        <Skeleton count={1} className='h-[30px] max-w-[180px]' />
                    </SkeletonTheme>
                </td>
            </div>
        )
    )
}

export default BidsShimmer;