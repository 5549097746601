import Bid from "./bid"
import TableShimmer from "./tableShimmer"

export default function Table(props){

    const {bidsInfo, handleSort, currentSortColumn, auctionData, pagination, loading, 
        bids, topBid, topBidder, underBidder, bidsLength, searchParams } = props;


    return(
        <div className="z-10 border-[#DEDEDE] border rounded-lg w-full overflow-auto">
            <table aria-label="Outside Table" className="w-full overflow-auto table-auto">
                <thead>
                    <tr className="bg-[#FFFFFF] border-b rounded-lg border-[#DEDEDE] h-12">
                        <th scope="col" className="rounded-lg px-4 py-3  min-w-[130px]">
                            <span data-testid="paddle number" className="flex items-center " 
                                onClick={(e) => {handleSort(e,'paddle_number')}}>
                                <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                        pr-1 py-1 cursor-pointer">Paddle no.</p>
                                {currentSortColumn.key==='paddle_number' ? 
                                    (currentSortColumn.sort_order==='asc'?
                                        <img src="/images/dropdown-icon.png" alt="img" 
                                            className=" dropdown-downwords3 " />:
                                        <img src="/images/dropdown-upwords.svg" alt="img" 
                                            className="dropdown-upwords3" />
                                    ):
                                    (<img src="/images/dropdown-icon.png" alt="img" 
                                        className="dropdown-upwords3  " />)
                                }
                            </span>
                        </th>
                        <th scope="col" className=" px-4 py-3 min-w-[180px] ">
                            <span data-testid="Bidder Name" className="flex items-center " 
                                onClick={(e) => {handleSort(e,'name')}}>
                                <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                        pr-1 py-1 cursor-pointer">Bidder name</p>
                                {currentSortColumn.key==='name' ? 
                                    (currentSortColumn.sort_order==='asc'?
                                        <img src="/images/dropdown-icon.png" alt="img" 
                                            className=" dropdown-downwords3 cursor-pointer" />:
                                        <img src="/images/dropdown-upwords.svg" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />
                                    ):
                                    (<img src="/images/dropdown-icon.png" alt="img" 
                                        className="dropdown-upwords3 cursor-pointer " />)
                                }
                            </span>
                        </th>
                        <th scope="col" className=" px-4 py-3 min-w-[180px]">
                            <span className="flex items-center " data-testid="Amount"
                                onClick={(e) => {handleSort(e,'bid_amount')}}>
                                <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                        pr-1 py-1 cursor-pointer">Amount</p>
                                {currentSortColumn.key==='bid_amount' ? 
                                    (currentSortColumn.sort_order==='asc'?
                                        <img src="/images/dropdown-icon.png" alt="img" 
                                            className=" dropdown-downwords3 cursor-pointer" />:
                                        <img src="/images/dropdown-upwords.svg" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />
                                    ):
                                    (<img src="/images/dropdown-icon.png" alt="img" 
                                        className="dropdown-upwords3 cursor-pointer " />)
                                }
                            </span>          
                        </th>
                        <th scope="col" className=" px-4 py-3 min-w-[180px]">
                            <span className="flex items-center " data-testid="bid date"
                                onClick={(e) => {handleSort(e,'created_at')}}>
                                <p className="text-[16px] font-medium leading-normal cursor-pointer
                                            text-[#343434] pr-1 py-1">Bid date</p>
                                {currentSortColumn.key==='created_at' ? 
                                    (currentSortColumn.sort_order==='asc'?
                                        <img src="/images/dropdown-icon.png" alt="img" 
                                            className=" dropdown-downwords3 cursor-pointer" />:
                                        <img src="/images/dropdown-upwords.svg" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer" />
                                    ):
                                    (<img src="/images/dropdown-icon.png" alt="img" 
                                        className="dropdown-upwords3 cursor-pointer " />)
                                }
                            </span>          
                        </th>    
                    </tr>
                </thead>
                <tbody>
                    {loading ? Array.from({ length: 5 }, (_, index) => <TableShimmer key={index} />) : (
                        bidsLength > 0 ? (
                            <Bid bids={bids?.all_bidders} bidsInfo={bidsInfo} auctionData={auctionData} pagination={pagination}
                                topBid={topBid} topBidder={topBidder} underBidder={underBidder} searchParams={searchParams} />
                        ) : (
                            <>
                                <tr>
                                    <td
                                        colspan="7"
                                        className="text-center sm:pl-0"
                                    >
                                        <div className="h-[180px] mt-[100px]">
                                            <img src="/images/bid.svg" className="mx-auto" />
                                            <p className="text-[18px] font-medium  leading-normal text-[#34343] mt-3">
                                                Currently no bids</p>
                                        </div>
                                    </td>
                                </tr>
                    
                            </>
                        )
                    )
                    }
                </tbody>
            </table>
        </div>
    )
}