import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';

const RecaptchaModal = ({ isVisible, onClose, onCaptchaChange, onCaptchaError }) => {
    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded">
                <h2 className="text-center mb-4">Verify your identity by completing the CAPTCHA</h2>
                <ReCAPTCHA
                    sitekey={config.SITEKEY}
                    onChange={onCaptchaChange}
                    onErrored={onCaptchaError}
                />
            </div>
        </div>
    );
};

export default RecaptchaModal;
