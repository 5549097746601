import axios from 'axios';
import config from '../config/index.js';
import { authHeader } from '../redux/helper.jsx';

/**
 * The function VerifyRecaptcha is an asynchronous function that sends a POST request to verify a
 * reCAPTCHA token and returns an object with an error flag and the response data.
 * @param token - The `token` parameter is a string that represents the user's response to the
 * reCAPTCHA challenge. It is typically generated by the reCAPTCHA widget on the client-side and passed
 * to the server for verification.
 * @returns an object with two properties: "error" and "data". The "error" property indicates whether
 * an error occurred during the execution of the function. If an error occurred, the value of "error"
 * will be true. The "data" property contains the response data from the API call.
 */
async function VerifyRecaptcha(token) {
    try{
        const data = await axios.post(`${config.API.baseURL}/users-management/verify-captcha`, token)
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}

/**
 * The function `VerifyOtp` is an asynchronous function that sends a POST request to a specified API
 * endpoint for OTP validation and returns the response data or an error object.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body when making a POST request to the OTP validation endpoint. It should include the necessary
 * information required for OTP validation, such as the user's phone number and the OTP code.
 * @returns The function `VerifyOtp` is returning an object with two properties: `error` and `data`.
 * The `error` property indicates whether an error occurred during the execution of the function. If
 * `error` is `false`, it means that the OTP validation was successful and the `data` property contains
 * the response data from the API call. If `error` is `true`, it means
 */
async function VerifyOtp(body) {
    try{
        const data = await axios.post(`${config.API.baseURL}/users-management/otp-validation`, body)
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}
async function ViewProfile(body) {
    const headers= await authHeader();
    try{
        const data = await axios.get(`${config.API.baseURL}/users-management/${body.email}`,{
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}
async function UpdateProfile(body) {
    const headers= await authHeader();
    try{
        const data = await axios.patch(`${config.API.baseURL}/users-management/${body.email_address}`,body,{
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}
async function ResetPasswordLink(body){
    try{
        const data = await axios.post(`${config.API.baseURL}/users-management/forgot_password`, body)
        return { error: false, data: data.data };
    }
    catch (error) {
        return { error: true, message: error };
    }
}
async function ConfirmPassword(body){
    try{
        const data = await axios.post(`${config.API.baseURL}/users-management/reset_password`, body)
        return { error: false, data: data.data }
    }
    catch(error){
        return { error: true, data: error.response };
    }
}
async function UpdatePassword(email_address, body) {
    const headers= await authHeader();
    try{
        const data = await axios.patch(`${config.API.baseURL}/users-management/password-update/${email_address}`, body, {
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}
async function getKycToken() {
    const headers= await authHeader();
    try {
        const category = await axios.get(`${config.API.baseURL}/users-management/generate`, {
            headers: headers,
        });
        return { error: false, data: category.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function getKybToken() {
    const headers= await authHeader();
    try {
        const category = await axios.get(`${config.API.baseURL}/users-management/kyb-generate`, {
            headers: headers,
        });
        return { error: false, data: category.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function updatePricing(email_address,body) {
    const headers= await authHeader();
    try {
        const category = await axios.patch(`${config.API.baseURL}/users-management/update-plan/${email_address}`, body,{
            headers: headers,
        });
        return { error: false, data: category.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function connectStripe() {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/users-management/stripe`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function disconnectStripe(body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/users-management/stripe`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function connectPaypal() {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/paypal/paypal-connect`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function disconnectPaypal(body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/paypal/paypal-disconnect`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function AuctionList(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/auctions${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function AddNotes(endpoint,body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/auctions${endpoint}`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function CreateAuction(body) {
    const headers= await authHeader();
    try {
        const response = await axios.post(`${config.API.baseURL}/auctions`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function LotList(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/auctions/lots${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function viewAuction(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/auctions/view${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function editAuction(id,body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/auctions/update/${id}`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function CreateLot(body) {
    const headers= await authHeader();
    try {
        const response = await axios.post(`${config.API.baseURL}/auctions/lots`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function ImportLot(body) {
    const headers= await authHeader();
    try {
        const response = await axios.post(`${config.API.baseURL}/auctions/import`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}

async function deleteLot(body) {
    const headers= await authHeader();
    try {
        const response = await axios.delete(`${config.API.baseURL}/auctions/lots`,{
            headers: headers,
            data: body
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function updateLot(body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/auctions/lots`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function CloneAuction(body) {
    const headers= await authHeader();
    try {
        const response = await axios.post(`${config.API.baseURL}/auctions/clone`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function DeleteAuction(id) {
    const headers= await authHeader();
    try {
        const response = await axios.delete(`${config.API.baseURL}/auctions/${id}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function publishAuction(id) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/auctions/update/${id}?published=true`,{},{
            headers: headers
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function UnpublishAuction(auction_id,body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/auctions/${auction_id}`,body,{
            headers: headers
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function reorderLot(body) {
    const headers= await authHeader();
    try {
        const response = await axios.post(`${config.API.baseURL}/auctions/reorder-lots`,body,{
            headers: headers
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function BidderList(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/bids${endpoint}&export=true`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function handleBidders(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/buyers/approval${endpoint}`,{},{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function SubDomainSetup(endpoint,body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/subdomain/subdomain${endpoint}`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function BidderDetails(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/bids/${endpoint}`,{
            headers: headers,
            
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}
async function SalesList(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/orders/seller${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function getOrderDetails(id) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/orders/sales?order_id=${id}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function getAllBidsOfLot(id,endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/lot-bid-history/${id}?${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}




async function AllBidderList(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/seller-bidder-management${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function AllSalesList(endpoint) {
    const headers= await authHeader();
    try {
        const response = await axios.get(`${config.API.baseURL}/seller-bidder-management/seller-orders${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function getAllBids(id,endpoint) {
    const headers= await authHeader();
    try {
        // eslint-disable-next-line max-len
        const response = await axios.get(`${config.API.baseURL}/lot-bid-history/seller/bids?auction_id=${id}&${endpoint}`,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function changePaymentInfo(orderId, {paymentType, paymentStatus}) {
    const headers = await authHeader();
    try {
        const response = await axios.put(`${config.API.baseURL}/orders/update`, {
            order_id: orderId,
            payment_status: paymentStatus,
            payment_type: paymentType
        }, {
            headers
        })
        return { error: false, res: response }
    } catch (e) {
        return { error: true, message: e }
    }
}

async function BannerNotification() {
    try {
        const response = await axios.get(`${config.API.baseURL}/site-banner/${encodeURIComponent("All Sellers")}`);
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}

async function NewsNotication(body) {
    const headers= await authHeader();
    try {
        const response = await axios.patch(`${config.API.baseURL}/newsletter`,body,{
            headers: headers,
        });
        return { error: false, data: response.data };
    } catch (e) {
        return { error: true, message: e };
    }
}


async function Accesslogs() {
    const headers= await authHeader();
    try{
        const data = await axios.post(`${config.API.baseURL}/users-management/save-log`,{},{
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}

async function getQuickSightEmbedUrl() {
    const headers= await authHeader();
    try{
        const data = await axios.get(`${config.API.baseURL}/quicksight-dashboards`,{
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}
// getQuickSightEmbedUrl


async function AuctionInsights(auction_id) {
    const headers= await authHeader();
    try{
        const data = await axios.get(`${config.API.baseURL}/quicksight-dashboards/auction-view?auction_id=${auction_id}`,{
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}

async function GetTemplate(name) {
    const headers= await authHeader();
    try{
        const data = await axios.get(`${config.API.baseURL}/mailchimp/${name}`,{
            headers: headers,
        })
        return { error: false, data: data };
    } catch (error) {
        return { error: true, data: error.response };
    }
}

export const dataService = {
    VerifyRecaptcha,
    VerifyOtp,
    ViewProfile,
    UpdateProfile,
    ResetPasswordLink,
    ConfirmPassword,
    UpdatePassword,
    getKycToken,
    updatePricing,
    getKybToken,
    connectStripe,
    disconnectStripe,
    AuctionList,
    AddNotes,
    CreateAuction,
    LotList,
    CreateLot,
    reorderLot,
    viewAuction,
    ImportLot,
    deleteLot,
    updateLot,
    editAuction,
    CloneAuction,
    DeleteAuction,
    publishAuction,
    BidderList,
    handleBidders,
    SubDomainSetup,
    BidderDetails,
    SalesList,
    getOrderDetails,
    getAllBidsOfLot,
    getAllBids,
    AllBidderList,
    UnpublishAuction,
    changePaymentInfo,
    AllSalesList,
    BannerNotification,
    NewsNotication,
    connectPaypal,
    disconnectPaypal,
    Accesslogs,
    getQuickSightEmbedUrl,
    AuctionInsights,
    GetTemplate
}