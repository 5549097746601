import { Auth } from "aws-amplify";
import { clearData } from "../pages/account/profileSlice";
import { logout } from "../pages/auth/registrationSlice";

const checkUser = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        if(!user){
            localStorage.clear();
            Auth.signOut();
            window.location.href = "/login";
        }
    } catch (err) {
        console.log(err, 'error...');
        localStorage.clear();
        Auth.signOut();
        window.location.href = "/login";
    }
};


/**
 * The function `authHeader` returns an object containing an Authorization header with a JWT token.
 * @returns an object containing the Authorization header with the JWT token.
 */
export async function authHeader() {
// Call checkUser in a try-catch block
    checkUser()

    const idToken = await Auth.currentSession()
    const headers = {
        Authorization: `Bearer ${idToken.getIdToken().getJwtToken()}`,
    }
    return headers;
}