import React from 'react'
import { motion } from 'framer-motion'
import { Oval } from  'react-loader-spinner'

export default function Button(props) {
    const {text, loader, onclick, disabled, type} = props;
    return (
        <motion.button
            onHoverStart={e => {}}
            onHoverEnd={e => {}}
            type={type} 
            disabled={disabled}
            className="mt-3 w-full cursor-pointer text-[#FFFFFF] text-[14px] 
            bg-[#282828] leading-normal rounded-md border border-[#FFF] px-8 py-3  font-medium 
            disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] hover:bg-[#3F3F45] flex justify-center items-center 
            disabled:cursor-auto" 
            id="login_btn" onClick={onclick}>
            {text}
            {loader&& 
            <Oval
                height={20}
                width={50}
                color="#FFFFFF"
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#000000"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
            }
        </motion.button>
    )
}
