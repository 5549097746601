import React, { useCallback, useEffect, useState } from 'react';
import Debounce from '../../CommonComponent/debounce';

export default function TableHeader(props) {

    const { searchParams, handleSearch, message, biddersInfo,setIsOpen,exportHandler,bidders } =props;
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword')!==null? 
        decodeURIComponent(searchParams.get('keyword')):'')
   
    const titleRegex = /^(?![\s]).*$/;

    /**
     * The handleChange function checks if a value matches a regular expression, and if it does, it
     * sets the search value and calls another function with the search value as a parameter.
     * @returns If the value does not pass the `titleRegex` test, nothing is being returned.
     */
    const handleChange = (e,value)=>{
        handleSearch(e,'keyword',value)

    }
    useEffect(() => {
        setSearchValue(
            searchParams.get('keyword')
                ? decodeURIComponent(searchParams.get('keyword'))
                : ''
        );
    }, [location.search]);

    const changeHandler=(e)=>{
        if(titleRegex.test(e.target.value)) {
            setSearchValue(e.target.value)
        }else{
            return;
        }
    }

    const optimizedFn = useCallback(
        Debounce((e,value) => handleChange(e,value)),
        [searchParams]
    );

    return (
        <>
            <div className="flex justify-between">
                <p className="sm:text-[34px] text-[28px] font-medium leading-normal text-[#343434]">All Bidders</p>
            </div>
            {/* <!-- search filter export content --> */}
            <div className="mt-10 flex justify-between flex-wrap items-center">
                <p className="text-[16px] leading-normal font-medium text-[#343434]"> 
                    <span>{biddersInfo?.total_buyers} </span>
                    {biddersInfo.total_buyers===1 ?  'Bidder' :  'Bidders' }
                </p>
                <div className="flex ml-auto  flex-wrap items-center">
                    {/* <!-- search  --> */}
                    <div className="relative py-[12px]">
                        <input data-testid="search-bidder" id="search-value" type="text" debounceTimeout={500}
                            onChange={(e)=>{e.preventDefault(); optimizedFn(e,e.target.value); changeHandler(e)}}
                            className="text-[14px] relative w-[270px] h-[32px] pl-8 pr-3  border border-[#A1A1A9] 
                        placeholder:text-[#A1A1A9] focus:border-[#343434] outline-none font-medium leading-normal text-[#4A4A4A]" 
                            placeholder="Search"
                            value={searchValue}
                        />
                        <img src="/images/search-icon-large.svg" alt="search-icon" 
                            className="absolute top-5 cursor-pointer left-2.5" />
                    </div>

                    <div className="relative mr-2.5 cursor-pointer ml-2">
                        <button 
                        // disabled={plan_type==='Free' || bidderInfo.total_records_found === 0} 
                            onClick={()=>setIsOpen(true)}  disabled={bidders?.length===0}
                            type="button" id="export-bidders" 
                            className="text-[14px] sm:flex hidden text-[#52525A] border w-[89px] items-center 
                            disabled:text-[hsl(240,4%,65%)] disabled:border-[#D4D4D8]
                            h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium justify-center">
                            <img src="/images/export-icon.svg" alt="export-icon"
                                className={`mr-2 ${bidders?.length===0 && 'opacity-50'} `}
                            />
                            Export
                        </button>
                        <button type="button" onClick={()=>setIsOpen(true)}  disabled={bidders?.length===0}
                            className="text-[14px] sm:hidden block text-[#52525A] border w-[35px] 
                        disabled:text-[hsl(240,4%,65%)]
                        h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium
                         disabled:border-[#D4D4D8]">
                            <img src="/images/export-icon.svg" alt="export-icon" 
                                className={`ml-2  ${bidders?.length===0 && 'opacity-50'}`}
                            />
                        </button>
                    </div>


                </div>
                
            </div>
            {/* <!-- success message for export csv file --> */}
            {/* {message!=="" && 
            <div className="mt-[15px] px-5 success-msg-csv py-3 bg-[#A8D9C8] border border-[#489892] rounded-[6px]">
                <div className="flex items-center">
                    <img src="/images/verify-account.svg" alt="img" className="pr-3" />
                    <div>
                        <p className="text-[12px] leading-normal font-bold text-[#343434]">{message}</p>
                    </div>
                </div>
            </div>} */}
        </>
    )
}
