/* eslint-disable indent */
import React, { useState } from "react";
import sanitizeHtml from 'sanitize-html';
import { dummyAuctoinDescription } from "../../config/constant";

const Description = ({
    style = {},
    description = '',
    isSingleLotView = false,
    emptyDescriptionPlaceholder = dummyAuctoinDescription,
    className
}) => {
    const auctionDescription = description.trim() ? description : emptyDescriptionPlaceholder
    const [isFullTextShown, setIsFullTextShown] = useState(false);
    const descriptionContent = isFullTextShown ? auctionDescription : auctionDescription.slice(0, 300);
    const shouldDisplayButton = auctionDescription?.length > 300;
    const buttonText = isFullTextShown ? 'Read Less' : 'Read More';
    /**
     * The function "renderHTML" takes an HTML string, sanitizes it, and returns an object with a
     * property "__html" containing the sanitized HTML.
     * @param htmlString - The `htmlString` parameter is a string that represents an HTML code or
     * markup. It can contain tags, attributes, and content that make up an HTML document or a portion
     * of it.
     * @returns An object with a property named "__html" that contains the sanitized HTML string.
     */
    function renderHTML(htmlString) {
        const sanitizedHTML = sanitizeHtml(htmlString, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe', 'img']),
            allowedAttributes: {
                iframe: ['*'], 
                img: ['*'],
                span: ['*'],
                '*': ['style', 'class']
            },
            allowedSchemesByTag: {
                img: ['http', 'https', 'data']
            },
            allowedStyles: {
                '*': {
                    '*': true
                }
            }
        });
        return { __html: sanitizedHTML };
    }
    return (
        <div className="ql-editor">
            <div
                data-testid='auction-description'
                id='updated_auction-description'
                className={`
                    ${className}
                    ${shouldDisplayButton
                        ? 'h-auto overflow-hidden transition-max-height duration-1000 ease-in-out'
                        : ''}
                    ${isSingleLotView
                        ? 'px-[30px] md:px-[100px] lg:px-[300px]'
                        : ''}`}
                /* 
                * Used webkitmask image instead of text-transparent because 
                * transparent text makes line-through effect non-visible
                */
                style={{
                    ...style,
                    WebkitMaskImage: !isFullTextShown && shouldDisplayButton
                    ? 'linear-gradient(to bottom, black 50%, transparent 100%)'
                    : 'none',
                    maskImage: !isFullTextShown && shouldDisplayButton
                        ? 'linear-gradient(to bottom, black 50%, transparent 100%)'
                        : 'none',
                }}
                dangerouslySetInnerHTML={renderHTML(descriptionContent)}/>
            {shouldDisplayButton && (
                <div className="flex justify-center">
                    <button onClick={() => setIsFullTextShown(!isFullTextShown)} className='text-[9px]' >
                        {buttonText}
                    </button>
                </div>
            )}
        </div>
    )
}

export const AuctionDescription = React.memo(Description, (prevProp, nextProp) => {
    return prevProp.description === nextProp.description
})