import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function OverViewShimmer() {
    return (
        <>
            <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                <p className="py-5 border-b border-[#CECECE] pl-[35px] px-4 text-[16px] font-medium leading-normal 
                text-[#343434]">Information</p>
                {/* <!-- information content --> */}
                <div className="flex flex-wrap mt-8 mb-[15px] w-full">
                    <div className=" xxs:w-[30%] w-full pl-[35px] mb-10">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Thumbnail</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                    
                    </div>
                    <div className=" xs:w-[70%] w-full md:pr-[54px] pr-5 xs:pl-0 pl-[35px]  mb-10">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Description</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>  
                    </div>
                    <div className="md:w-[30%] xs:w-[30%] w-full pl-[35px] mb-10">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Currency</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%] xs:w-[50%] w-full xs:pl-0 pl-[35px]  mb-10">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Start Date / Time</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%]  w-full pl-[35px] md:pl-0 mb-10">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">End Date / Time</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                </div>
            </div>
            {/* <!-- rules content --> */}
            <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                <p className="py-5 border-b border-[#CECECE] pl-[35px] text-[16px] font-medium leading-normal 
                text-[#343434]">Rules</p>
                <div className="flex flex-wrap mt-8 mb-[10px] w-full px-[35px]">
                    <div className="md:w-[30%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Type</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Extension type</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Extension time (mins)</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[30%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Time between lots</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Registration type</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Buyer fees</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>  
                    </div>
                </div>
            </div>
            {/* <!-- privacy content --> */}
            <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                <p className="py-5 border-b border-[#CECECE] pl-[35px] text-[16px] font-medium leading-normal 
                text-[#343434]">Privacy</p>
                <div className="flex flex-wrap mt-8 mb-[10px]  w-full px-[35px]">
                    <div className="md:w-[30%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Type</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                    <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                        <p className="text-[10px] font-medium leading-normal text-[#343434]">Passcode</p>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] max-w-[155px] pt-4 ' />
                        </SkeletonTheme>                      
                    </div>
                </div>
            </div>
        </>
    )
}
