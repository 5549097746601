/* eslint-disable security/detect-unsafe-regex */
import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { dataService } from '../../../../services/dataService';
import Purchases from './purchases';
import Information from './information';
import ShippingAddress from './shippingAddress';
import PaymentDetails from './paymentDetails';
import BuyerInformation from './buyerInformation';
import { Menu } from "@headlessui/react";

export default function OrderDetails() {
    const [loading, setLoading] = useState(true)
    const [orderDetail, setOrderDetail] = useState({})
    const [currency, setCurrency] = useState('')
    const [total,setTotal] = useState('')
    const [totalBidAmount,setTotalBidAmount] = useState('')
    const [totalBuyerFees,setTotalBuyerFees] = useState('')
    const params = useParams()

    /**
     * The function `getOrderDetails` retrieves order details, calculates fees and totals, and updates
     * state variables.
     */
    const getOrderDetails = async() =>{
        const data = await dataService.getOrderDetails(params.id)
        setOrderDetail(data.data.data)
        setCurrency(data.data.data.purchases[0])
        let subTotal= 0;
        let calFees=0;
        let total=0;
        (data?.data.data).purchases.forEach(element => {
            total+=element.bid_amount;
            if (element.fees !== null && element.fees!=="") {
                calFees += Number(element.fees)
                subTotal += element.bid_amount + (Number(element.fees) || 0);
            } else if (element.percentage !== null) {
                calFees+=(element.percentage * element.bid_amount) / 100;
                subTotal += element.bid_amount + (element.percentage * element.bid_amount) / 100;
            } else {
                subTotal += element.bid_amount;
            }
        });
        setTotalBuyerFees(calFees)
        setTotalBidAmount(total);
        setTotal(subTotal)
        setLoading(false)
    }

    useEffect(()=>{
        getOrderDetails()
    },[])

    return (
        <div className='md:mx-10 mx-[25px] md:my-10 my-[25px] pb-[90px]'>
            <div className='flex justify-between items-center md:pb-10 pb-5'>
                <h1 className='text-[#343434] md:text-[34px] sm:text-[30px] text-[24px] font-[500] 
            leading-normal flex items-center group'>
                    Purchase details
                </h1>
                <Menu as="div" className="relative" >
                    <Menu.Button>
                        <img src="/images/three-dots-icon-black.svg" alt="img" 
                            className="cursor-pointer three-dots-icon" type="button"
                            id="action-lot" />
                    </Menu.Button>
                    <Menu.Items>
                        <div className="dropdown-cont absolute overflow-hidden rounded-[10px] float-left 
                            m-0 w-[150px] py-2 min-w-max right-0 z-[50] list-none  border-none bg-white bg-clip-padding 
                            text-left text-base shadow-bs3 top-0">
                            <Menu.Item>
                                <button type='button' 
                                    className="block w-full whitespace-nowrap bg-transparent pl-5 pr-7 py-2 
                                    text-[12px] font-medium text-[#343434] text-left"
                                >Change payment info</button>
                            </Menu.Item>
                            <Menu.Item>
                                <button type='button' 
                                    className="block w-full whitespace-nowrap bg-transparent pl-5 pr-7 py-2 
                                    text-[12px] font-medium text-[#343434] text-left"
                                >Send payment request</button>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Menu>
            </div>
            <Information orderDetail={orderDetail} loading={loading} />
            <Purchases orderDetail={orderDetail} loading={loading} totalBidAmount={totalBidAmount} 
                totalBuyerFees={totalBuyerFees} currency={currency} total={total}/>
            <ShippingAddress orderDetail={orderDetail} loading={loading} />
            <PaymentDetails orderDetail={orderDetail} loading={loading} />
            <BuyerInformation orderDetail={orderDetail} loading={loading} />
        </div>
    )
}
