import React from 'react'
import { Dialog } from '@headlessui/react'
import sanitizeHtml from "sanitize-html"
import ReactQuill from 'react-quill'
import "quill-paste-smart"
import { QuillModules, RichEditorFormats } from '../../../../../config/quill'

export default function TermsAndConditionModal({
    auctionData,
    isModalOpen,
    setIsModalOpen,
    isModalEditOpen,
    termsAndCondition,
    setTermsAndCondition,
    setIsModalEditOpen,
    onTermsAndConditionChange
}) {

    return (
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-[#282828cc]"
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <Dialog.Panel
                data-te-modal-dialog-ref
                className="pointer-events-none relative flex min-h-[calc(100%-1rem)] mx-5 w-auto translate-y-[-50px] 
                items-center transition-all duration-300 ease-in-out min-[740px]:mx-auto min-[740px]:mt-7 
                min-[740px]:min-h-[calc(100%-3.5rem)] min-[740px]:max-w-[658px]">
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white 
                    bg-clip-padding text-current shadow-lg outline-none">
                    <Dialog.Title
                        className="flex flex-shrink-0 border-b border-[#A1A1A9] items-center justify-between rounded-t-md p-4">
                        <p className="text-[22px] text-[#343434] leading-normal font-medium pl-3">Terms and conditions</p>
                        {/* <!--Close button--> */}
                        <button onClick={() => {setIsModalOpen(false);setIsModalEditOpen(false)}}
                            type="button"
                            className="box-content rounded-none border-none"
                            data-te-modal-dismiss
                            aria-label="Close">
                            <img src="/images/CloseButton-modalcontent.svg" alt="img" />
                        </button>
                    </Dialog.Title>
                    {/* <!--Modal body--> */}
                    {!isModalEditOpen ? <div className="relative p-4  sm:mx-8 mx-2.5  mt-2 ">
                        <p 
                            className="text-[14px] pr-6 h-[330px] overflow-y-auto overflow-x-hidden customScrollbar 
                            text-[#343434] font-medium leading-normal" 
                            dangerouslySetInnerHTML={{ __html: sanitizeHtml(termsAndCondition) }}>
                        </p>
                        <div className="flex justify-center mt-2 mb-4" 
                            style={{filter: "drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.06)) !important;"}}>
                            <button id='edit-terms' type='button' 
                                disabled={auctionData.status=="Published" || auctionData.status=="Accepting bids"}
                                onClick={()=>setIsModalEditOpen(true)}
                                className="border disabled:bg-[#D4D4D8] disabled:border-[hsl(240,8%,79%)] 
                                disabled:text-[hsl(240,4%,65%)]
                                border-[#A1A1A9]  rounded-md text-[14px] text-[#52525A] leading-normal 
                                mt-2.5 font-medium px-[20px] py-[12px] w-[135px]"
                                data-te-toggle="modal"
                                data-te-target="#exampleModalLg2"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                data-te-modal-dismiss
                                aria-label="Close">Edit</button>
                        </div>
                    </div>:
                        (
                            <div id="terms-conditions" className="relative p-4 sm:mx-12 mx-4">
                                <ReactQuill
                                    value={termsAndCondition}
                                    theme="snow"
                                    modules={QuillModules}
                                    formats={RichEditorFormats}
                                    placeholder="Terms and Conditions"
                                    onChange={setTermsAndCondition}
                                />
                                <div className="flex drop-shadow-md justify-center   mt-2 mb-4">
                                    <button id='confirm-terms'
                                        className="bg-[#282828] rounded-md text-[14px] text-[#fff] 
                                            leading-normal mt-2.5 font-medium px-[20px] py-[12px] w-[135px]" 
                                        onClick={()=>{
                                            setIsModalOpen(false)
                                            setIsModalEditOpen(false)
                                            onTermsAndConditionChange()
                                        }}>
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Dialog.Panel>
        </Dialog>    
    )
}
