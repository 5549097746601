import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

export default function Topbar({isTopbarIsOpen, setIsTopBarIsOpen,isBanner}) {
    const path = useLocation();


    const handleTopbar = () =>{
        setIsTopBarIsOpen(!isTopbarIsOpen);
    }

    // ${isTopbarIsOpen ? 'top-[36px]': 'top-[4px]'} 

    return (
        <div className={`${isTopbarIsOpen ? 'siderbar open' : 'sidebar'} `}>
    
            <div className={` ${!isTopbarIsOpen ? 'flex' : 'hidden'} lg:min-w-60 md:block hidden lg:max-w-60 h-screen
             bg-[#282828] fixed bottom-0 
        ${isTopbarIsOpen ? (isBanner ? 'top-0': 'top-[36px]'):'top-0'} 
          max-w-[200px] min-w-[200px]
       
       ` } >
                <div className={`pt-7 pb-7 sticky top-0 bg-[#282828] ${!isTopbarIsOpen ? 'hidden' : 'flex'}`}>
                    <Link to="/" className='w-[100%]'>
                        <img src="/images/indy-auction-logo-white.svg" alt="logo" className="ml-10 w-[140px] h-[25px] mx-auto" 
                            loading="lazy" />
                    </Link>
                </div>
                <div className={`flex flex-col justify-between  overflow-y-auto overflow-x-hidden scrollbar-ver 
                ${isTopbarIsOpen ? 'flex' : 'hidden'}
                h-[calc(100vh-190px)]`}>
                    <ul className="mx-6">
                        <li className={`px-4 hover:bg-[#343434] hover:rounded-[11px]
                    ${path.pathname === '/' || path.pathname==='/dashboard'? 'bg-[#343434]':''} 
                    rounded-[11px]`} >
                            <Link to="/" className="flex items-center py-3.5">
                                <img alt="dashboard" 
                                    src={path.pathname === '/' || path.pathname==='/dashboard' ? 
                                        "/images/dashboard-icon.svg":
                                        "/images/dashboard-inactive-icon.svg"} loading="lazy"/>
                                <span className={`${path.pathname === '/' || path.pathname==='/dashboard' ? 
                                    'text-[#FFFFFF]': 'text-[#A1A1A9]'} 
                            text-start text-[14px] font-medium pl-3.5  `}>Dashboard</span>
                            </Link>
                        </li>
                        <li id="account" 
                            className={`${path.pathname==='/account' || path.pathname==='/account/edit' ?'bg-[#343434]':''} 
                    px-4 hover:bg-[#343434] hover:rounded-[11px] rounded-[11px]`}>
                            <Link to='/account' className="flex items-center my-[15px] py-3.5">
                                <img alt="account" loading="lazy" 
                                    src={path.pathname==='/account' ? "/images/account-active-icon.svg":
                                        "/images/accounts-dashboard.svg"}  />
                                <span className={`${path.pathname==='/account' ? 'text-[#FFFFFF]': 'text-[#A1A1A9]'} 
                            text-start text-[14px] font-medium pl-3.5`}>Account</span>
                            </Link>
                        </li>
                        <li id='auctions'
                            className={`${path.pathname==='/auctions' || 
                        path.pathname.includes('/auctions') || path.pathname==='/auctions/create' 
                        || path.pathname==='/auctions/lots' ?'bg-[#343434]':''} 
                    px-4 hover:bg-[#343434] hover:rounded-[11px] rounded-[11px]`}>
                            <Link to="/auctions" className="flex items-center my-[15px] py-3.5">
                                <img 
                                    src={path.pathname==='/auctions' || 
                                path.pathname.includes('/auctions') || path.pathname==='/auctions/create' 
                                || path.pathname==='/auctions/lots' ? 
                                        "/images/auction-active.svg":
                                        "/images/auction-logo-dashboard.svg"} 
                                    alt="auction" loading="lazy" />
                                <span className={`${path.pathname==='/auctions' || path.pathname.includes('/auctions') || 
                            path.pathname==='/auctions/create' 
                            || path.pathname==='/auctions/lots' ? 
                                    'text-[#FFFFFF]': 'text-[#A1A1A9]'} 
                            text-start text-[14px] font-medium pl-3.5`}>Auctions</span>
                            </Link>
                        </li>
                        <li id='allsales'
                            className={`${path.pathname==='/allsales' || 
                        path.pathname.includes('/allsales') || path.pathname==='/allsales' 
                        || path.pathname==='/allsales' ?'bg-[#343434]':''} 
                    px-4 hover:bg-[#343434] hover:rounded-[11px] rounded-[11px]`}>
                            <Link to="/allsales" className="flex items-center my-[15px] py-3.5">
                                <img 
                                    src={path.pathname==='/allsales' || 
                                path.pathname.includes('/allsales') || path.pathname==='/allsales' 
                                || path.pathname==='/allsales' ? 
                                        "/images/sales.svg":
                                        "/images/sale.svg"} 
                                    alt="auction" loading="lazy" />
                                <span className={`${path.pathname==='/allsales' || path.pathname.includes('/allsales') || 
                            path.pathname==='/allsales' 
                            || path.pathname==='/allsales' ? 
                                    'text-[#FFFFFF]': 'text-[#A1A1A9]'} 
                            text-start text-[14px] font-medium pl-3.5`}>All Sales</span>
                            </Link>
                        </li>
                        {/* <li className="px-4 hover:bg-[#343434] hover:rounded-[11px] rounded-[11px]">
                        <a className="flex items-center my-[15px] py-3.5">
                            <img src="/images/allorders-dashboard.svg" alt="allorders" loading="lazy" />
                            <span className="text-[#A1A1A9] text-start text-[14px] font-medium pl-3.5">All Orders</span>
                        </a>
                    </li> */}
                        <li className={`${path.pathname==='/allbidders' ?'bg-[#343434]':''} 
                    px-4 hover:bg-[#343434] hover:rounded-[11px] rounded-[11px]`}>
                            <Link to="/allbidders" className="flex items-center my-[15px] py-3.5">
                                <img src={path.pathname==='/allbidders' ? "/images/allBiddersHigh.svg" 
                                    : "/images/all-bidders-dashboard.svg"} alt="allbidders" loading="lazy" />
                                <span  className={`${path.pathname==='/allbidders' ? 'text-[#FFFFFF]': 'text-[#A1A1A9]'} 
                            text-start text-[14px] font-medium pl-3.5`}>All Bidders</span>
                            </Link>
                        </li>
                        <li id='pricing' className={`${path.pathname==='/pricing' ?'bg-[#343434]':''} 
                    px-4 hover:bg-[#343434] hover:rounded-[11px] rounded-[11px]`}>
                            <Link to='/pricing' className="flex items-center my-[15px] py-3.5">
                                <img  loading="lazy" 
                                    src= {path.pathname==='/pricing' ? "/images/pricing-active.svg" : 
                                        "/images/pricing-logo-dashboard.svg"} 
                                    alt="profile-icon" />
                                <span className={`${path.pathname==='/pricing' ? 'text-[#FFFFFF]': 'text-[#A1A1A9]'} 
                            text-start text-[14px] font-medium pl-3.5`}>Pricing</span>
                            </Link>
                        </li>
                    </ul>
              
                </div>

                {/* openclose/ */}

                <div className={`flex flex-col justify-center  overflow-y-auto overflow-x-hidden scrollbar-ver px-4 
                 ${isTopbarIsOpen ? 'hidden' : 'flex'}
                h-[calc(100vh-410px)]`}>
                    <ul className="mr-[7px]">
                        <li className={`rounded-[11px] flex justify-end pt-2`} >
                            <Link to="/" className={`${path.pathname === '/' || path.pathname==='/dashboard'? 'bg-[#343434]':''} 
                            flex items-center py-3.5 hover:bg-[#343434] rounded-[11px] p-4`}>
                                <img alt="dashboard" 
                                    src={path.pathname === '/' || path.pathname==='/dashboard' ? 
                                        "/images/dashboard-icon.svg":
                                        "/images/dashboard-inactive-icon.svg"} loading="lazy"/>
                               
                            </Link>
                        </li>
                        <li id="account" 
                            className={`rounded-[11px]  flex justify-end pt-4`}>
                            <Link to='/account' className={`flex items-center py-3.5 hover:bg-[#343434] rounded-[11px] p-4
                            ${path.pathname==='/account' || path.pathname==='/account/edit' ?'bg-[#343434]':''} `}>
                                <img alt="account" loading="lazy" 
                                    src={path.pathname==='/account' ? "/images/account-active-icon.svg":
                                        "/images/accounts-dashboard.svg"}  />
                               
                            </Link>
                        </li>
                        <li id='auctions'
                            className={`rounded-[11px]  flex justify-end pt-4`}>
                            <Link to="/auctions" className={`${path.pathname==='/auctions' || 
                        path.pathname.includes('/auctions') || path.pathname==='/auctions/create' 
                        || path.pathname==='/auctions/lots' ?'bg-[#343434]':''}  
                          flex items-center py-3.5 hover:bg-[#343434] rounded-[11px] p-4`}>
                                <img 
                                    src={path.pathname==='/auctions' || 
                                path.pathname.includes('/auctions') || path.pathname==='/auctions/create' 
                                || path.pathname==='/auctions/lots' ? 
                                        "/images/auction-active.svg":
                                        "/images/auction-logo-dashboard.svg"} 
                                    alt="auction" loading="lazy" />
                            </Link>
                        </li>
                      
                        <li className={`rounded-[11px]  flex justify-end pt-4`}>
                            <Link to="/allbidders" className={`${path.pathname==='/allbidders' ?'bg-[#343434]':''}
                                  flex items-center py-3.5 hover:bg-[#343434] rounded-[11px] p-4`}>
                                <img src={path.pathname==='/allbidders' ? "/images/allBiddersHigh.svg" 
                                    : "/images/all-bidders-dashboard.svg"} alt="allbidders" loading="lazy" />
                               
                            </Link>
                        </li>
                        <li id='pricing' className={`rounded-[11px]  flex justify-end pt-4`}>
                            <Link to='/pricing' className={` ${path.pathname==='/pricing' ?'bg-[#343434]':''}
                             flex items-center py-3.5 hover:bg-[#343434] rounded-[11px] p-4 `}  >
                                <img  loading="lazy" 
                                    src= {path.pathname==='/pricing' ? "/images/pricing-active.svg" : 
                                        "/images/pricing-logo-dashboard.svg"} 
                                    alt="profile-icon" />
                               
                            </Link>
                        </li>
                    </ul>

                </div>
                <div className={`mt-[300px] ${!isTopbarIsOpen ? 'flex' : 'hidden'} `}>
                </div>
                <ul className={`mx-6 flex justify-between items-center relative  ${!isTopbarIsOpen ? 'hidden' : 'flex'}`}>
                    <li className="pr-4 pl-3 cursor-pointer">
                        <a href='https://support.indy.auction/' target='_blank' 
                            className="flex items-center relative py-3.5" rel="noreferrer">
                            <img src="/images/help-center.svg" alt="helpcenter" loading="lazy" />
                            <span className="text-[#A1A1A9] text-start text-[14px] font-medium pl-3.5">Help Centre</span>
                        </a>
                    </li> 
                   
                </ul>
                <ul className={`mx-6 flex justify-end pr-[9px] 
                    items-center relative  ${!isTopbarIsOpen ? 'flex' : 'hidden'}`}>
                    <li className="pr-4 pl-3 cursor-pointer">
                        <a href='https://support.indy.auction/' target='_blank' 
                            className="flex items-center relative py-5" rel="noreferrer">
                            <img src="/images/help-center.svg" alt="helpcenter" loading="lazy" />
                        </a>
                    </li> 
                   
                </ul>
                <ul className='relative'>
                    <li className="flex justify-between items-center z-50 absolute
                     bottom-[16px] right-[-1.25rem]  rounded-full parentCircle border hover:border-black">
                        {/* <div className="cursor-pointer circle hover:shadow-2xl" 
                            onClick={handleTopbar} 
                            data-te-toggle="modal"
                            data-te-target="#exampleModalCenter"
                            data-te-ripple-init
                            data-te-ripple-color="light"> */}
                        {/* <img src="/images/rounded-icon-white.svg" alt="img" className=""
                            loading="lazy"/> */}
                        <button class=" p-4 rounded-full shadow-s2 
                            relative bg-white"  onClick={handleTopbar}>
                            {isTopbarIsOpen ? (
                                <img src="/images/arrowmark-small-black.svg" alt="Arrow Left" 
                                    className="absolute top-[12px] left-3 "/>
                            ) : (
                                <img src="/images/arrowmark-small-black.svg" alt="Arrow Right" 
                                    className="absolute top-[12px] left-3.5 rotate-180" />
                                
                            )} 
                                
                        </button>
                      
                    </li>
                </ul>
            </div> 

        
        </div>
    )
}