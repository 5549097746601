/* eslint-disable max-len */
import React, {useState, useCallback, useEffect} from 'react'
import BidderHeader from './bidderHeader';
import { useSearchParams } from 'react-router-dom';
import { useOutletContext, useLocation } from "react-router-dom";
import BidderTable from './bidderTable';
import { dataService } from '../../../../services/dataService';
import Paginate from '../../listAuction/paginate';
import ToastMeassage from '../../../../CommonComponent/toastMeasseage';

export default function Bidders(){
    const [searchParams, setSearchParams] = 
    useSearchParams({ page:  1, per_page: 10 });
    const [bidderInfo, setBidderInfo] = useState([])
    const [loadingBids, setLoading] = useState(true)
    const [bidders, setBidders] = useState([])
    const [isSearched, setIsSearched] = useState(false);
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword')!==null?searchParams.get('keyword'):'')
    const outletContext = useOutletContext();
    const { auctionData, handleSearch, setSuccessMessage,loading } = outletContext;
    const [currentSortColumn, setCurrentSortColumn] = useState({key:'created_at', order: 'ascending'});
    const [lotPagination,setLotPagination]= useState([]);
    const [loader, setLoader] = useState(false)
    const [showToastMeassage, setToastMeassage]=useState(false)
    const [bidderExportCsvUrl, setBidderExportCsvUrl]=useState('')
    const location = useLocation();
    const [acceptBidderModal, setAcceptBidderModal] = useState({
        isModalOpen: false,
        type: '',
        id: '',
        auction_id: ''
    })


    const fetchHandler=useCallback(async(id)=>{
        setLoading(true);
        let endpoint = `?auction_id=${id}&page=${Number(searchParams.get('page'))}&per_page=10`        
        if(searchParams.get('sort')!==null){
            endpoint += `&key=${String(searchParams.get('key'))}&order=${String(searchParams.get('order'))}`
        }
        if(searchParams.get('keyword')!==null){
            endpoint += `&keyword=${searchParams.get('keyword')}`
        }
        const response = await dataService.BidderList(endpoint);
        setBidders(response.data.data);
        setLotPagination(response.data)
        setBidderExportCsvUrl(response.data?.csv_url)
        setLoading(false)
        setBidderInfo(response.data);
        setIsSearched(false)
    },[location])

    const handleSort=(e,key)=>{
        let params = Object.fromEntries(searchParams);
        if(searchParams.get('sort') === false || searchParams.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `ascending`
            setCurrentSortColumn({
                key: key,
                order: 'ascending'
            });
        }else {
            if(searchParams.get('key').includes(key)) 
            {
                if(searchParams.get('order')==='ascending') {
                    params['order'] = `descending`
                    setCurrentSortColumn({
                        key: key,
                        order: 'descending'
                    });
                }else{
                    params['order'] = `ascending`
                    setCurrentSortColumn({
                        key: key,
                        order: 'ascending'
                    });
                }
            }
            else {
                params['key'] = key
                params['order'] = `ascending`
                setCurrentSortColumn({
                    key: key,
                    order: 'ascending'
                });
            }
        }
        setSearchParams({ ...params})
    }
    const handleBidders = async(auction_id,id)=>{
        setLoader(true)
        let endpoint = `?auction_id=${auction_id}&status=${acceptBidderModal.type==='approve'?'Approved':'Rejected'}&buyer_id=${id}`
        const data = await dataService.handleBidders(endpoint)
        if(data.error===false){
            setAcceptBidderModal({
                isModalOpen: false,
                type: '',
                id: '',
                auction_id: ''
            })
            window.scrollTo(0, 0);
            setSuccessMessage(`Bidder has been ${acceptBidderModal.type==='approve'?'approved':'rejected'}`);
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
            fetchHandler(auctionData._id)
        }
        setLoader(false)
    }
    /**
     * The function updates the search parameters with a new page number when a page number is clicked.
     */
    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParams)
        setSearchParams({ ...params, page: pageNum.selected + 1 })
    }
    useEffect(()=>{
        if(Object.keys(auctionData).length > 0){
            fetchHandler(auctionData._id)
        }
    },[fetchHandler,auctionData])
    return(
        <div>
            <BidderHeader bidderInfo={bidderInfo} loadingBids={loadingBids} bidders={bidders} setToastMeassage={setToastMeassage} isSearched={isSearched}
                searchValue={searchValue} setSearchValue={setSearchValue} auctionData={auctionData} searchParams={searchParams}
                currentSortColumn={currentSortColumn} handleSearch={handleSearch} bidderExportCsvUrl={bidderExportCsvUrl}/>
            {showToastMeassage && <ToastMeassage message={'Export complete'} />}

            <BidderTable bidders={bidders} searchValue={searchValue} setSearchValue={setSearchValue} loading={loading}
                auctionData={auctionData} searchParams={searchParams} currentSortColumn={currentSortColumn} 
                handleSort={handleSort} isSearched={isSearched} loadingBids={loadingBids} acceptBidderModal={acceptBidderModal} 
                setAcceptBidderModal={setAcceptBidderModal} handleBidders={handleBidders} bidderInfo={bidderInfo} loader={loader} />
            {(lotPagination.current_page <= lotPagination.total_pages) &&
            <div className='pt-[80px]'>
                <Paginate totalPageNumber={Math.ceil(lotPagination.total_records_found/10)}
                    selectedPageNumber={Number(searchParams.get('page')) || 1}
                    handleOnClickPageNumber={handleOnClickPageNumber} />
            </div>
            }
        </div>
    )
}