import { useOutletContext } from "react-router-dom";
import LotTabHeader from "./lotTabHeader";
import LotTable from "./lotTable";
import { dataService } from "../../../../services/dataService";
import { useEffect, useState } from "react";
import ToastMeassage from "../../../../CommonComponent/toastMeasseage";

export default function Lots(){
    const outletContext = useOutletContext();
    const { lots, auctionData, lotInfo, fetchHandler, setSuccessMessage, handleSearch, handleSort, currentSortColumn, 
        searchParams, loadLots, isSearched, handleImportFile, uploadedFileName, setUploadedFileName, 
        showImportModal, setShowImportModal, importLoader, setImportLoader, setSearchParams,
        isReorderLotModalOpen, setIsReorderLotModalOpen, lotDetailsFromSocket, isSocket, loading} = outletContext;
    const [searchValue, setSearchValue] = useState(searchParams.get('search')!==null? 
        decodeURIComponent(searchParams.get('search')):'')
    const [deleteLotModal,setDeleteLotModal] = useState(false);
    const [loader,setLoader]=useState(false);
    const [showExoprtToastMsg, setShowExportToastMsg]=useState(false)

    useEffect(() => {
        setSearchValue(
            searchParams.get('search')
                ? decodeURIComponent(searchParams.get('search'))
                : ''
        );
    }, [location.search]);

    const handleDelete=async(lot_number)=>{
        setLoader(true);
        const body={
            "auction_id": auctionData.auction_id,
            "lot_number": parseInt(lot_number),
        }
        const response = await dataService.deleteLot(body);
        setLoader(false);
        setDeleteLotModal(false);
        if(response.error===false){
            setSuccessMessage('Lot successfully deleted');
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
            fetchHandler();
        }
    }


    return(
        <div
            className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
            id="tabs-profile3"
            role="tabpanel"
            data-te-tab-active
            aria-labelledby="tabs-profile-tab3">
            {/* <!-- search filter export content --> */}
            <LotTabHeader handleSearch={handleSearch} searchParams={searchParams} loadLots={loadLots} 
                setShowExportToastMsg={setShowExportToastMsg} setSearchParams={setSearchParams}
                searchValue={searchValue} setSearchValue={setSearchValue} lotInfo={lotInfo} auctionData={auctionData} 
                lots={lots} handleImportFile={handleImportFile} uploadedFileName={uploadedFileName} 
                setUploadedFileName={setUploadedFileName} showImportModal={showImportModal} 
                setShowImportModal={setShowImportModal} importLoader={importLoader} setImportLoader={setImportLoader} 
                setIsReorderLotModalOpen={setIsReorderLotModalOpen} isReorderLotModalOpen={isReorderLotModalOpen}/>
            {showExoprtToastMsg && <ToastMeassage message={'Export complete'}/>}

            {/* <!-- table content --> */}
            <LotTable lots={lots} fetchHandler={fetchHandler} handleSearch={handleSearch} handleSort={handleSort} 
                currentSortColumn={currentSortColumn} searchParams={searchParams} 
                searchValue={searchValue} setSearchValue={setSearchValue} loadLots={loadLots} isSearched={isSearched}
                auctionData={auctionData} lotDetailsFromSocket={lotDetailsFromSocket}
                handleDelete={handleDelete} loader={loader} isSocket={isSocket} loading={loading}
                deleteLotModal={deleteLotModal} setDeleteLotModal={setDeleteLotModal} lotInfo={lotInfo} />
        </div>
    )
}