import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import SetUpForMobile from "./setUpForMobile";
import Banner from "../../../components/layout/notificationbannar";
import { dataService } from "../../../services/dataService";

export default function Header(props){

    const { priceError,lotData,createLothandler,loading,auctionData,setLotData, setPriceError }=props;
    const {id,uid}=useParams();
    const [isOpen, setIsOpen] = useState(true)
    const Navigate=useNavigate();

    const [notes , setNotes] = useState({});
    const [isBanner, setIsBanner] = useState(true);
    const [ismdBanner, setIsmdBanner] = useState(true);
    const [istextShow , setIstextShow] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const numberOfChars=()=>{
        if(screenWidth > 1130){
            return 200
        }else if(screenWidth>1102){
            return 130
        }else if(screenWidth> 774){
            return 60
        }else if(screenWidth > 418){
            return 50;
        }else{
            return 20;
        }
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = notes?.notification;

    
    const allPs = tempDiv.querySelectorAll('p');  // Get all <p> tags
    const firstP = allPs[0];  // Get the first <p> tag
    const secondPExists = allPs.length > 1;

    const displayContent= notes?.notification?.length> numberOfChars() || secondPExists;
    const notificationContent= istextShow ? notes?.notification : firstP?.outerHTML.substring(0, numberOfChars()-6);
    const buttonText = istextShow ? 'Read less' : 'Read more';


    const handleResize = () => {
        setScreenWidth(window.innerWidth);
        console.log(window.innerWidth, notes?.notification?.length);

    };
  
    // Attach event listener when the component renders
    window.addEventListener('resize', handleResize);


   


    const viewBanner=async()=>{
        const response=await dataService.BannerNotification();
        if(response.error===false){
            setNotes(response.data.data);
            setIsBanner(false)
            setIsmdBanner(false)
        }
        else{
            setIsBanner(true)
            setIsmdBanner(true)
        }
    }

    const toggleFullText= () =>{
        setIstextShow(!istextShow);
    }

    useEffect(()=>{
        viewBanner()
    },[])
    

    return(
        <>
            <Banner viewBanner={viewBanner} notes={notes} setNotes={setNotes} toggleFullText={toggleFullText} 
                displayContent={displayContent} notificationContent={notificationContent} buttonText={buttonText}
                istextShow={istextShow} />
            <nav className={`h-[68px] md:px-10 px-5 flex border-b border-[#D4D4D8] justify-between 
                overflow-x-hidden sticky  ${ismdBanner ? 'top-0': 'top-[45px] z-[999]'} 
         ${isBanner ? 'md:top-0': 'md:top-[45px] z-[999]'}  z-[101] bg-[#FFFFFF]`}>
                <div className="flex items-center">
                    <img src="/images/arrow-left-dark.svg" className="cursor-pointer"  alt="img" 
                        onClick={()=>Navigate(`/auctions/${id}/${uid}/lots`)}/>
                    <p className="md:block hidden pl-5 py-5">Lot creator</p>
                </div>
                <div className='flex justify-end items-center'>
                    <button id="preview-btn"  onClick={()=>setIsOpen((prevState) => !prevState)} 
                        className={`md:hidden block border border-[#282828] rounded-md text-[14px] text-[#282828] 
                leading-normal font-medium px-[20px] py-[12px] ${isOpen ? "w-[110px]" : "w-[165px]"} 
                md:w-[135px] bg-[#FFFFFF] mr-2`}>
                        {!isOpen?"Continue Editing":"Preview"}</button>
                    <button data-testid='save-lot' id="save-btn" onClick={(e)=>{e.preventDefault(); createLothandler()}}
                        disabled={priceError || lotData.title1==='' || lotData.description==='' || loading
                    || lotData.starting_price==='' || lotData.images.length===0 || 
                    (lotData.low_estimate!=="" && lotData.high_estimate=="") || 
                    (lotData.low_estimate=="" && lotData.high_estimate!=="")}
                        className=" bg-[#282828] rounded-md text-[14px] text-[#fff] disabled:cursor-auto
                        leading-normal font-medium px-[20px] py-[12px] w-[110px] md:w-[135px]
                        disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] flex justify-center"
                    >Save
                        {loading&& <Oval
                            height={20}
                            width={50}
                            color="#FFFFFF"
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#000000"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />}
                    </button>
                </div>
            </nav>
            <SetUpForMobile setIsOpen={setIsOpen} isOpen={isOpen}
                lotData={lotData}
                auctionData={auctionData}
                setLotData={setLotData}
                priceError={priceError}
                setPriceError={setPriceError} 
                isBanner={isBanner}
                ismdBanner={ismdBanner}

            />
        </>
    );
}