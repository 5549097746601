import React from 'react'

export default function LayoutFooter({auctionData}) {
    return (
        <footer className="bg-[#E6E6E6]" style={{backgroundColor: auctionData.footer['background_color']}}>
            <div className="flex justify-between px-7 items-center">
                <p className="text-[8px] py-8 text-[#282828] font-medium leading-normal" 
                    style={{color: auctionData.footer['text_color']}}>
                    Powered by <a href="#" className="underline cursor-default">indy.auction</a> </p> 
                <div className="flex">
                    <a href="#" className="cursor-default"><img className="mr-3" src="/images/insta-icon.png" alt="img" 
                        loading="lazy" /></a>
                    <a href="#" className="cursor-default"><img className="mr-3" src="/images/facebook-icon.png" alt="img" 
                        loading="lazy" /></a>
                    <a href="#" className="cursor-default"><img className="mr-3" src="/images/twitter-icon.png" alt="img" 
                        loading="lazy" /></a>
                    <a href="#" className="cursor-default"><img  src="/images/linkedin-icon.png" alt="img" loading="lazy" /></a>
                </div>
            </div>
        </footer>    
    )
}
