import React from 'react'

export default function ErrorMessage({uploadErrorMessage}) {
    return (
        <div class="mt-[15px] mb-6 px-5 err-msg-import py-3 bg-[#CA8787] border border-[#9848482] rounded-[6px]">
            <div class="flex items-center">
                <img src="/images/closeicon-errormsg.svg" alt="img" class="pr-3" />
                <div>
                    <p class="text-[12px] leading-normal font-bold text-[#343434]">{uploadErrorMessage}</p>
                </div>
            </div>
        </div>    
    )
}
