import React from 'react'
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle'

export default function LiveTiming({auctionData}) {
    return (
        <div className="flex  pt-[17px] justify-center font-medium leading-normal"
            style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}} >
            <div className="px-[20px] border-r border-[#717179]">
                <p className="text-[23px]">00</p>
                <p className="text-[8px] text-center">DAYS</p>
            </div>
            <div className="px-[20px] border-r border-[#717179]">
                <p className="text-[23px]">00</p>
                <p className="text-[8px] text-center">HRS</p>
            </div>
            <div className="px-[20px] border-r border-[#717179]">
                <p className="text-[23px]">00</p>
                <p className="text-[8px] text-center">MINS</p>
            </div>
            <div className="px-[20px] ">
                <p className="text-[23px]">00</p>
                <p className="text-[8px] text-center">SECS</p>
            </div>
        </div>    
    )
}
