import { Tooltip } from 'react-tippy';

export default function ToolTip({ title, children }){

    return(
        <Tooltip
            title={title}
            position="bottom"
            trigger="click"
            arrow="true"
            arrowSize="regular"
        >
            {children}
        </Tooltip>
    );
}