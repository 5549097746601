import React from 'react'
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle'

export default function LotSection({index, auctionData}) {
    return (
        <div className="md:mt-20 sm:mt-10 mt-5 xl:w-1/4 md:w-1/3 sm:w-[50%] w-full px-5" >
            <div className="w-full relative flex justify-center items-center bg-[#E6E6E6] px-5 h-[126px] ">
                <img src="/images/lot-list-img.png" alt="lot-list" loading="lazy" />
            </div>
            <p className="pt-3 text-[11px] sm:text-left text-center leading-normal 
                font-medium text-[#343434]" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                {index+1}. Title of the lot goes here</p>
            <p className="pt-2 m-0 text-[8px] sm:text-left text-center leading-normal 
                font-medium text-[#343434]" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                Title 2 of the lot is here</p>
            <p className="pt-2 m-0 text-[8px] sm:text-left text-center leading-normal 
                font-medium text-[#717179]" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Estimate</p>
            <p className=" m-0 text-[11px] sm:text-left text-center leading-normal font-medium text-[#343434]"
                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>£0</p>
            <p className="pt-1 m-0 text-[8px] sm:text-left text-center leading-normal 
                font-medium text-[#717179]" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Starting Bid</p>
            <p className=" m-0 text-[11px] sm:text-left text-center leading-normal font-medium text-[#343434]"
                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>£0</p>
            <div className="flex items-center rounded-md mt-2 w-full  justify-center border border-[#A1A1A9]">
                <p className="pr-2 text-[9px] py-[5px] leading-normal font-medium text-[#52525A]"
                    style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Select max bid</p>
                <img src="/images/dropdown-icon.png" alt="dropdown-icon" loading="lazy" />
            </div>
        </div>    
    )
}
