/* eslint-disable react/jsx-indent */
export default function Custom404() {
    return (
        <div className="text-center h-[100vh] flex justify-center items-center relative w-full">
            {/* <h1 className="text-[22px] font-[500] leading-normal text-[#343434]">404 - Page Not Found</h1> */}
            {/* <img src="/images/7906236_3804933.svg" alt="404" className="h-[550px]"/> */}
            <div>
                <p className=" lg:text-[285px] lg:leading-[320px] md:text-[200px] md:leading-[205px] 
             text-[150px] leading-[155px] opacity-5 font-bold">404</p>
                <span className="text-black "><p className=" lg:text-[150px] lg:leading-[155px]
                 md:text-[100px] md:leading-[105px] text-[100px] leading-[105px]
                font-bold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">404</p>
                    
                <p className=" text-[24px] leading-[30px] font-bold 
                absolute top-[60%] translate-x-[-50%] left-[50%] 
                translate-y-[-60%]
                ">Auction Not Found</p>
                </span>
                <p className=" text-[24px] leading-[30px] font-normal absolute text-center top-[90%] left-[50%]
                translate-x-[-50%] translate-y-[-90%] lowercase"><b>Indy.</b>auction</p>
            </div>
        </div>
    )
}