import React, { useCallback, useEffect, useState } from 'react';
import {Link} from  'react-router-dom';
import Modal from './modal';
import Filter from './filter';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Debounce from '../../../CommonComponent/debounce';
import ToastMeassage from '../../../CommonComponent/toastMeasseage';

export default function ListingHeader(props) {

    const { auctionData,searchParams, handleSearch, handleFilter, setIsFilterApplied,isFilterApplied, 
        handleExport, date, setDate, toDate, setToDate, selected, setSelected,handleFetch,errorMessage,
        setSearchParams, setMessage, message, isOpen, setIsOpen,auction,loader,profile, loading } =props;
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword')!==null? 
        decodeURIComponent(searchParams.get('keyword')):'')

    const [sendToDate, setsendToDate] = useState('');
    const [sendFromDate, setsendFromDate] = useState('');


    const titleRegex = /^(?![\s]).*$/;

    // useEffect(()=>{
    //     if(searchParams.get('status')!==null){
    //         setSelected(decodeURIComponent(searchParams.get('status')))
    //     }
    // },[])

    const handleChange = (e,value)=>{
        handleSearch(e,'keyword',value)
    }

    const changeHandler=(e)=>{
        let value=e.target.value;
        if(titleRegex.test(value)) {
            setSearchValue(value)
            
        }else{
            return;
        }
    }

    const optimizedFn = useCallback(
        Debounce((e,value) => handleChange(e,value)),
        [searchParams]
    );

    const getFilter=(status, fromDate, toDate)=>{
        handleFilter(status, fromDate, toDate)
    }

    const resetHandler=(e)=>{
        setIsFilterApplied(false);
        let params = Object.fromEntries(searchParams);
        delete params.start_date
        delete params.end_date
        delete params.status
        setSelected('')
        setDate('')
        setsendFromDate('')
        setsendToDate('')
        setToDate('')
        setSearchParams({...params});
    }

    return (
        <>
            <div className="flex justify-between">
                <p className="sm:text-[34px] text-[28px] font-medium leading-normal text-[#343434]">Auctions</p>
                {loading? 
                    <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                        <Skeleton count={1} className='h-[40px] min-w-[155px] pt-4 ' />
                    </SkeletonTheme> : 
                    <Link to="/auctions/create">
                        <button 
                            className="px-[31px] py-[12px] bg-[#282828] sm:mx-0 mx-3 text-[#FFFFFF]  leading-normal 
                    font-medium text-[14px] rounded-[6px] disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] 
                    hover:bg-[#3F3F45]" id='create-auction'
                            disabled={ (profile.plan_type==='Free' || profile.free_user===undefined || 
                            profile.free_user===true) && auction.total_auctions>=1 }>
                            Create auction
                        </button>
                    </Link> }
            </div>
            {/* <!-- search filter export content --> */}
            <div className="mt-10 flex justify-between flex-wrap items-center">
                <p className="text-[16px] leading-normal font-medium text-[#343434]"> 
                    <span>{auction?.total_records_found} </span>
                    {auction.total_records_found===1 ?  'Auction' :  'Auctions' }
                </p>
                <div className="flex ml-auto  flex-wrap items-center">
                    {/* <!-- search  --> */}
                    <div className="relative pr-3 py-[12px]">
                        <input id='search-value' type="text" debounceTimeout={500}
                            onChange={(e)=>{e.preventDefault(), optimizedFn(e,e.target.value), changeHandler(e)}}
                            className="text-[14px] relative w-[270px] h-[32px] pl-8 pr-3  border border-[#A1A1A9] 
                        placeholder:text-[#A1A1A9] focus:border-[#343434] outline-none font-medium leading-normal text-[#4A4A4A]" 
                            placeholder="Search auctions" value={searchValue}/>
                        <img src="/images/search-icon-large.svg" alt="search-icon" 
                            className="absolute top-5 cursor-pointer left-2.5" />
                    </div>
                    {/* <!-- filter --> */}

                    <Filter searchParams={searchParams} 
                        getFilter={getFilter}
                        selected={selected}
                        setSelected={setSelected}
                        date={date}
                        setDate={setDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        sendToDate={sendToDate}
                        setsendToDate={setsendToDate}
                        sendFromDate={sendFromDate}
                        setsendFromDate={setsendFromDate}
                    />

                    {/* <!-- export button --> */}
                    {auctionData.length!==0 && <div className="relative cursor-pointer" onClick={()=>setIsOpen(true)}>
                        <button type="button" id='export-auctions' 
                            className="text-[14px] sm:block hidden text-[#52525A] border w-[89px] h-[32px] 
                            border-[#A1A1A9] rounded-[6px] leading-normal  font-medium pr-[20px] pl-[34px]"  
                        >
                            Export
                        </button>
                        <button type="button" 
                            className="text-[14px] sm:hidden block text-[#52525A] border 
                            w-[35px] h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium "  
                        >   
                        </button>
                        <img src="/images/export-icon.svg" alt="export-icon" className="absolute top-[7px] left-2.5" />
                    </div>}
                </div>
            </div>
            {/* <!-- filter applied content --> */}
            {isFilterApplied && <div className="filter-applied ">
                <div className="flex justify-end items-center">
                    <p className="text-[14px] text-[#343434] leading-normal pr-2 font-medium">Filter applied</p>
                    <button className="underline text-[14px] text-[#343434] leading-normal font-medium 
                    border-l border-[#343434] pl-2" onClick={(e)=>resetHandler(e)} >Reset</button>
                </div>
            </div>}
            {message!=="" && 
            <div className="mt-[15px] px-5 success-msg-csv py-3 bg-[#A8D9C8] border border-[#489892] rounded-[6px]">
                <div className="flex items-center">
                    <img src="/images/verify-account.svg" alt="img" className="pr-3" />
                    <div>
                        <p className="text-[12px] leading-normal font-bold text-[#343434]">{message}</p>
                    </div>
                </div>
            </div>}
            {errorMessage!=="" && <ToastMeassage message={errorMessage}/>}
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} handleExport={handleExport} loader={loader}/>
        </>
    )
}
