/* eslint-disable max-len */
/* eslint-disable security/detect-unsafe-regex */
import React from 'react'
import { currencySymbolMapping } from '../../../../CommonComponent/staticValues';
import config from '../../../../config';

export default function Purchases({orderDetail,loading, totalBidAmount, totalBuyerFees, currency, total}) {
    return (
        <div className='border border-[#DEDEDE] rounded-lg mb-7'>
            <h6 className='text-[#343434] text-[16px] font-[500] leading-normal md:py-5 py-3.5 
                    md:px-10 px-5 border-b border-[#CECECE]'>Your purchases ({orderDetail?.purchases?.length})</h6>
            <div className='md:pt-8 pt-5 md:pb-[30px] pb-5 md:px-10 px-5'>
                {Object.keys(orderDetail).length>0 && orderDetail.purchases.length>0 && 
                <div className='max-h-[759px]
                            overflow-y-auto overflow-x-hidden customScrollbar  ' >
                    {orderDetail.purchases.map((item,index)=>
                        <div key={index} className='flex justify-between items-center md:pt-8 pt-5 
                                md:pb-[30px] pb-5 md:px-10 px-5 flex-wrap border 
                                border-[#DEDEDE] rounded-lg mb-[15px]'>
                            <div className='md:w-[64%] w-full flex flex-wrap'>
                                <div className='md:w-[48%] w-[47%] mr-2.5 mb-7'>
                                    <p className='text-[#4A4A4A] text-[10px] font-[500] leading-normal pb-2.5'>
                                        Lot number</p>
                                    {!loading?
                                        <p className='text-[#4A4A4A] text-[16px] font-[500] leading-normal' 
                                            data-testid="lot_number">
                                            {item.lot_number}</p>
                                        :  <p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                                </div>
                                <div className='md:w-[48%] w-[47%] mb-7'>
                                    <p className='text-[#4A4A4A] text-[10px] font-[500] leading-normal pb-2.5'>Lot</p>
                                    {!loading?
                                        <p className='text-[#4A4A4A] text-[16px] font-[500] leading-normal' 
                                            data-testid="lot_name">
                                            {item.lot_title}</p>
                                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                                </div>
                                <div className='md:w-[48%] w-[47%] mr-2.5 mb-7'>
                                    <p className='text-[#4A4A4A] text-[10px] font-[500] leading-normal pb-2.5'>
                                        Bidding amount</p>
                                    {!loading?
                                        <p className='text-[#4A4A4A] text-[16px] font-[500] leading-normal' 
                                            data-testid="bidding_amount">
                                            {Number(item.bid_amount).toLocaleString(item.currency ==='INR' ? 'en-IN' : 'en-US', {
                                                style: 'currency',
                                                currency: item.currency,
                                            })}</p>
                                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                                </div>
                                <div className='md:w-[48%] w-[47%] mb-7'>
                                    <p className='text-[#4A4A4A] text-[10px] font-[500] leading-normal pb-2.5'>
                                        Buyer fees</p>
                                    {!loading?
                                        <p className='text-[#4A4A4A] text-[16px] font-[500] leading-normal'>
                                            {(item.percentage=='' || item.percentage == null) && 
                                            (item.fees == null || item.fees == '') ?  '-'
                                                :(item.fees == null || item.fees == ''
                                                    ? `${item.percentage}%`
                                                    : 
                                                    Number(item.fees).toLocaleString(item.currency ==='INR' ? 'en-IN' : 'en-US', {
                                                        style: 'currency',
                                                        currency: item.currency,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }))}</p>
                                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>} 
                                </div>
                            </div>
                            <div className='md:w-[25%] w-full md:ml-[40px]'>
                                {!loading?
                                    <img src={`${config.Storage.CDN}${item.lot_image}`} alt='icon' 
                                        data-testid="lot_image" 
                                        className='sm:w-auto w-full sm:h-[200px]'/>
                                    :<p className='w-[37%] animate-pulse h-[200px] bg-[#f0efeff3]'></p>}
                            </div>
                        </div>
                    )}
                </div>}
                <div className='flex justify-between md:flex-row flex-col'>
                    <div className='md:w-[32%] w-full md:mb-0 mb-2.5 mr-2.5 border border-[#DEDEDE] 
                            rounded-[10px] py-[18px] px-8 shadow-bs9'>
                        <label className='text-[12px] text-[#52525A] leading-normal font-[600] pb-1.5'>
                            TOTAL BIDDING AMOUNT</label>
                        {!loading?
                            <p className='text-[#282828] text-[34px] leading-normal font-[700] break-all'>
                                {Number(totalBidAmount).toLocaleString(currency?.currency ==='INR' ? 'en-IN' : 'en-US', {
                                    style: 'currency',
                                    currency: currency?.currency,
                                })}</p>
                            :<p className='md:w-[32%] w-full md:mb-0 mb-2.5 animate-pulse h-10 bg-[#f0efeff3]'></p>}
                    </div>
                    <div className='md:w-[32%] w-full md:mb-0 mb-2.5 mr-2.5 border border-[#DEDEDE] 
                            rounded-[10px] py-[18px] px-8 shadow-bs9'>
                        <label className='text-[12px] text-[#52525A] leading-normal font-[600] pb-1.5'>
                            TOTAL BUYER FEES</label>
                        {!loading?
                            <p className='text-[#282828] text-[34px] leading-normal font-[700] break-all' 
                                data-testid="buyer_fees">
                                {Number(totalBuyerFees).toLocaleString(currency?.currency ==='INR' ? 'en-IN' : 'en-US', {
                                    style: 'currency',
                                    currency: currency?.currency,
                                })}</p>
                            :<p className='md:w-[32%] w-full md:mb-0 mb-2.5 animate-pulse h-10 bg-[#f0efeff3]'></p>}
                    </div>
                    <div className='md:w-[32%] w-full md:mb-0 mb-2.5 border border-[#DEDEDE] rounded-[10px] 
                            py-[18px] px-8 shadow-bs9'>
                        <label className='text-[12px] text-[#52525A] leading-normal font-[600] pb-1.5'>
                            TOTAL</label>
                        {!loading?
                            <p className='text-[#282828] text-[34px] leading-normal font-[700] break-all'>
                                {Number(total).toLocaleString(currency?.currency ==='INR' ? 'en-IN' : 'en-US', {
                                    style: 'currency',
                                    currency: currency?.currency,
                                })}</p>
                            :<p className='md:w-[32%] w-full md:mb-0 mb-2.5 animate-pulse h-10 bg-[#f0efeff3]'></p>}
                    </div>
                </div>
            </div>
        </div>
    )
}
