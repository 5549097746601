export default function NewPasswordFooter(){
    return(
        <>
            <div className=" items-center pt-[16px] pb-20  sm:hidden flex flex-wrap justify-center">
                <p className="text-[#FFF] font-medium  xs:text-[16px] text-[14px] leading-normal ">Don’t have an account?</p>
                <a href="seller-registration-page.html"> <button id="signin-btn" 
                    className="ml-2 text-[#FFF] rounded-md border cursor-pointer border-[#FFF] px-8 py-1.5 text-sm 
                        font-medium">Get started</button></a>
            </div>
            <div className="md:fixed md:block hidden  bottom-8">
                <p className="xl:text-[50px] lg:text-[40px] md:text-[30px] text-[#FFF] font-medium leading-normal"> 
                    Seamless <br/> self-managed <br/> auction software</p>
            </div>
            <footer className="sm:fixed mx-auto sm:mx-0 bottom-6 sm:right-10 ">
                <div className="flex  items-center sm:justify-end justify-center ">
                    <a href="#"><img  src="images/instagram-icon.svg" alt="instagram" loading="lazy"/></a> 
                    <a href="#"><img className="px-4" src="images/facebook-icon.svg" alt="facebook" loading="lazy"/></a> 
                    <a href="#"><img className="pr-4" src="images/twitter-icon.svg" alt="twitter" loading="lazy"/></a>
                    <a href="#"><img  src="images/linkedin-icon.svg" alt="linkedin"/></a>
                </div>
                <p className="text-[#FFF] sm:text-end text-center sm:pt-3 pt-5 text-xs font-medium">
                    © Copyright 2024 Indy.auction. All Rights Reserved</p>
            </footer>
        </>
    );
}