import React, { useEffect } from 'react'
import LayoutNavbar from '../commonLayout/layoutNavbar'
import { useLocation } from 'react-router-dom';
import LayoutFooter from '../commonLayout/layoutFooter';
import { formatDate } from '../../../../CommonComponent/dateConverter';
import LiveTiming from '../commonLayout/liveTiming';
import config from '../../../../config';
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle';
import { currencySymbolMapping } from '../../../../CommonComponent/staticValues';
import { AuctionDescription } from '../../description';

export default function SingleLotPreview({auctionData,startHour,startMin,endHour,endMin,endDate,startDate}) {
    const path=useLocation();

    useEffect(() => {
        localStorage.setItem('storedRoute', path.pathname);
    });
    return (
        <div 
            className="md:mt-9 mt-5 md:mx-10 mx-5 w-full overflow-x-hidden bg-[#FFF] 
        rounded-t-lg h-fit xl:w-[74%] lg:w-[70%] md:w-[60%]"
            style={{backgroundColor: auctionData.content_area['background_color'],color: auctionData.content_area['text_color']}}>
            {/* Main navigation container */}
            <LayoutNavbar auctionData={auctionData}/> 
            <div className=" mt-5 mx-auto pb-[13px] border-[#D4D4D8]">
                <p className="text-[34px] leading-normal font-medium py-3 text-center" 
                    style={{fontFamily: selectFontStyle(auctionData.font['hearder_font'])}}>
                    {auctionData.title===''?'Optional auction title here':auctionData.title}</p>
                <div className="flex justify-center items-center"
                    style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                    <AuctionDescription
                        description={auctionData.description}
                        className='text-[14px] lgl:px-[250px] px-10 leading-normal break-normal'
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                    />
                </div>
            </div> 
            {/* <!-- image section --> */}
            <div className="flex w-[100%] justify-center overflow-auto customScrollbar">
                <div className=" flex h-[350px] min-w-[400px]  justify-center items-center bg-[#E6E6E6]">
                    <div className="flex justify-center items-center flex-col">
                        <img src="/images/lead-auction-img.png" alt="lead-auction-img" />
                        <p className="text-[8px] text-[#52525A] pt-2 text-center font-semibold leading-normal">
                        </p>
                    </div>
                </div> 
                <div className=" mx-[34px] flex h-[350px] min-w-[400px]  justify-center items-center bg-[#E6E6E6]">
                    <div className="flex justify-center items-center flex-col">
                        <img 
                            src={auctionData.auction_image!==''?
                                `${config.Storage.CDN}${auctionData.auction_image}`
                                :"/images/lead-auction-img.png"} 
                            alt="lead-auction-img" 
                            className={auctionData.auction_image!==''?'min-w-[400px] h-[350px]':'w-auto h-auto'} loading="lazy" />
                        {auctionData.auction_image===''&&<p className="text-[8px] text-[#52525A] pt-2 text-center font-semibold 
                            leading-normal">Lead auction image</p>}
                    </div>
                </div> 
                <div className=" flex h-[350px] min-w-[400px]  justify-center items-center bg-[#E6E6E6]">
                    <div className="flex justify-center items-center flex-col">
                        <img src="/images/lead-auction-img.png" alt="lead-auction-img" />
                        <p className="text-[8px] text-[#52525A] pt-2 text-center font-semibold leading-normal">
                        </p>
                    </div>
                </div> 
            </div>
            <div className="lgl:px-[84px] md:px-[60px] sm:px-8 px-5 mt-5 sm:mt-[42px]">
                {/* <!-- zoom image , arrow mark --> */}
                <div className="flex justify-between items-center ">
                
                    <img src="/images/arrow-left-dark.svg" alt="img" className="cursor-pointer" />
                
                    <div className="flex">
                        <img src="/images/zoom-image.svg" alt="zoomimage" />
                        <p className="text-[12px] leading-normal pl-2.5  font-medium">Zoom image</p>
                    </div>
                    <img src="/images/arrow-right-dark.svg" alt="img" className=" cursor-pointer" />
                </div>
                {/* <!-- main content --> */}
                <div className="w-full flex lgl:flex-nowrap flex-wrap  mt-6">
                    <div className="lgl:w-[60%] lg:w-[50%] w-full">
                        <p className="text-[8px]  font-medium sm:text-left text-center leading-normal"
                            style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                            {`${startDate ? formatDate(startDate) : '12 Jul 2023'} / ${
                                startHour!=='' ? String(startHour).padStart(2, '0') : '08'
                            }:${
                                startMin !== '' ? String(startMin).padStart(2, '0') : '00'
                            } ${auctionData.time_zone !== '' ? auctionData.time_zone.split(' - ')[0] : 'BST'} - ${
                                endDate!=='' ? formatDate(endDate) : '22 Jul 2023'
                            } / ${
                                endHour!=='' ? String(endHour).padStart(2, '0') : '20'
                            }:${
                                endMin !== '' ? String(endMin).padStart(2, '0') : '00'
                            } ${auctionData.time_zone !== '' ? auctionData.time_zone.split(' - ')[0] : 'BST'}`}
                        </p>

                        <p className="text-[23px]   leading-normal sm:text-left text-center font-medium">
                            1. Title goes here</p>
                        <p className="text-[8px]   leading-normal sm:text-left text-center font-medium">
                            Title goes here</p>
                        {/* <div className="flex items-center sm:justify-start justify-center mt-3">
                            <img src="/images/location-img.svg" alt="img" />
                            <p className="text-[12px] leading-normal pl-1.5  font-medium">
                                Location:<span className=""> United Kingdom</span> </p>
                        </div> */}
                        {/* <!-- social media links --> */}
                        <div className="flex sm:justify-start justify-center  mt-2">
                            <div className="relative">
                                <img src="/images/circle-icon.svg" alt="img" />
                                <img src="/images/like-symbol.svg" className="absolute top-[21px] left-[21px]" alt="img" />
                            </div>
                            <img src="/images/calender-large.svg" alt="add-calender-icon" className="cursor-default" />
                            <img src="/images/share-icon-large.svg" alt="share-icon" className="cursor-default" />
                        </div>
                        {/* <!-- terms condition --> */}
                        <div className="flex mt-2 sm:justify-start justify-center items-center" 
                            style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}} >
                            <p className="text-[12px]  text-center  leading-normal">FAQs +</p>
                            <p className="text-[12px] pl-2  text-center leading-normal">
                                Terms and Conditions +</p>
                        </div>
                        {/* <!-- description --> */}
                        <div className="mt-10" >
                            <p className="text-[16px] font-bold leading-normal " 
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}} >Description</p>
                            <p className="text-[16px] pt-2.5 font-medium leading-normal" 
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse fermentum 
                                mollis neque vel posuere. Duis nec metus a turpis hendrerit dignissim. Quisque eget 
                                velit sed erat ornare tristique at sit amet orci. <br/> <br/>

                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse 
                                fermentum mollis neque vel posuere. Duis nec metus a turpis hendrerit dignissim. Quisque 
                                eget velit sed erat ornare tristique at sit amet orci. <br/> <br/>

                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse fermentum mollis neque 
                                vel posuere. Duis nec metus a turpis hendrerit dignissim. Quisque eget velit sed erat ornare 
                                tristique at sit amet orci. 
                            </p>
                        </div>
                        {/* <!-- shipping details --> */}
                        {/* <!-- description --> */}
                        <div className="mt-8 lg:mb-20 mb-6">
                            <p className="text-[16px] font-bold leading-normal " 
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Shipping details</p>
                            <p className="text-[16px] pt-2.5 font-medium leading-normal "
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse fermentum mollis neque 
                                vel posuere. Duis nec metus a turpis hendrerit dignissim. Quisque eget velit sed erat ornare 
                                tristique at sit amet orci. <br/> 
                            </p>
                        </div>
                    </div>
                    <div className="lgl:w-[40%] lg:w-[50%] lgl:mb-0 mb-14 w-full lgl:ml-[85px] ml-0">
                        <div className="border  border-[#DFDFDF]">
                            <p className="text-[16px] border-b  border-[#DFDFDF] py-2.5 text-center font-medium 
                            leading-normal " style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                                Place your bid</p>
                            <LiveTiming auctionData={auctionData}/>
                            {/* <!-- estimate --> */}
                            <div 
                                className="flex mx-[34px] pb-3 border-b border-[#DFDFDF] 
                            justify-between font-medium leading-normal" 
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                                <div>
                                    <p className="text-[12px] ">Estimate</p>
                                    <p className="text-[14px] ">
                                        {(auctionData.currency==='' || auctionData.currency===undefined) ? '£' : 
                                            currencySymbolMapping[auctionData.currency]}
                                        5,000-6,000</p>
                                </div>
                                <div>
                                    <p className="text-[12px] ">Current Bid</p>
                                    <p className="text-[14px] ">
                                        {(auctionData.currency==='' || auctionData.currency===undefined) ? '£' : 
                                            currencySymbolMapping[auctionData.currency]}00</p>
                                </div>
                            </div>
                            <div className="mx-[34px] my-5">
                                <button 
                                    className="cursor-default  bg-[#282828] rounded-md text-[9px] text-[#fff] leading-normal 
                                font-medium px-[10px] py-[8px] w-full"
                                    style={{backgroundColor: auctionData.buttons['background_color'], 
                                        color: auctionData.buttons['text_color']}}>Register to bid</button>
                            </div>
                        </div>
                        <div className="relative mt-4">
                            <button type="button" disabled className="text-[14px] bg-[#fff] text-start relative w-full h-11 px-3 
                            border border-[#A1A1A9] text-[#A1A1A9]  outline-none font-medium leading-normal">Select</button>
                            <img src="/images/auction-show-dropdown.svg" alt="dropdown-icon" 
                                className="absolute top-[20px] right-[15px]" />
                        </div>
                    </div>
                </div>
            </div>
            <LayoutFooter auctionData={auctionData}/>
        </div>    
    )
}
