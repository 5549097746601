import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function ChooseLayoutShimmer() {
    return (
        <div className="h-customh10 flex justify-center ">
            <div className=" md:my-[60px] my-[29px]">
                <p className="sm:text-[34px] text-[25px] leading-normal text-center text-[#343434] font-medium">
                    Choose your auction template</p>
                <ul className="flex justify-center flex-wrap md:mt-10 mt-3">
                    <div className='flex md:flex-row flex-col'>
                        <div className='lg:mr-[-64px] sm:mb-0 mb-7 h-[550px] lg:pr-11'>
                            <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="flex xl:w-[400px] flex-col">
                                <p className='xl:w-[400px] h-[450px] lg:pr-11 pr-5'>
                                    <Skeleton count={1} className='h-[450px]' />
                                    <p class="text-center sm:text-[22px] text-[20px] text-[#343434] font-medium 
                                leading-normal pt-2.5">Classic</p>
                                    <p class="text-center sm:text-[16px] text-[15px] text-[#343434] font-medium leading-normal">
                                        A timeless design to complement any <br/> website.</p>
                                </p>
                            </SkeletonTheme>
                        </div>
                        <div className='lg:mr-[-64px] sm:mb-0 mb-7 h-[550px] flex lg:pr-11'>
                            <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="flex xl:w-[400px]">
                                <p className='xl:w-[400px] h-[450px] lg:pr-11 pr-5'>
                                    <Skeleton count={1} className='h-[450px]' />
                                    <p class="text-center sm:text-[22px] text-[20px] text-[#343434] font-medium 
                                    leading-normal pt-2.5">Single Lot</p>
                                    <p class="text-center sm:text-[16px] text-[15px] text-[#343434] font-medium leading-normal">
                                        A simple and powerful layout for single-lot <br/> auctions.</p>
                                </p>
                            </SkeletonTheme>
                        </div>
                        <div className='lg:mr-[-64px] sm:mb-0 mb-7 h-[550px] flex'>
                            <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="flex xl:w-[400px]">
                                <p className='xl:w-[400px] h-[450px]'>
                                    <Skeleton count={1} className='h-[450px]' />
                                    <p class="text-center sm:text-[22px] text-[20px] text-[#343434] font-medium 
                                    leading-normal pt-2.5">Standalone</p>
                                    <p class="text-center sm:text-[16px] text-[15px] text-[#343434] font-medium leading-normal">
                                        A homepage-inspired design, ideal for <br/> standalone auctions.</p>
                                </p>
                            </SkeletonTheme>
                        </div>
                    </div>
                </ul>
            </div>
        </div>    
    )
}
