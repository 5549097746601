export default function Footer(props){

    const {auctionData}=props;

    return(
        <footer className="bg-[#E6E6E6]" style={{backgroundColor: auctionData.footer['background_color']}}>
            <div className="flex justify-between  px-7 items-center">
                <p className="text-[12px] py-8 text-[#282828] font-medium leading-normal"
                    style={{color: auctionData.footer['text_color']}}
                >Powered by <a href="#" className="underline cursor-default">indy.auction</a> </p> 
                <div className="flex">
                    <a href="#" className="cursor-default">
                        <img className="mr-3" src="/images/instagram-blackwhite.png" alt="img"/></a>
                    <a href="#" className="cursor-default">
                        <img className="mr-3" src="/images/facebook-blackwhite.png" alt="img"/></a>
                    <a href="#" className="cursor-default">
                        <img className="mr-3" src="/images/twitter-blackwhite.png" alt="img"/></a>
                    <a href="#" className="cursor-default">
                        <img  src="/images/linkedin-blackwhite.png" alt="img"/></a>
                </div>
            </div>
        </footer>
    )

}