import { useState } from "react";
import DeleteLotModal from "./deleteLotModal";
import LotSingle from "./lotSingle";

export default function Lot(props){
    const { 
        lots,
        handleDelete,
        auctionData,
        loader,
        deleteLotModal,
        setDeleteLotModal, lotDetailsFromSocket,
        onDragStart,
        onDragEnter,
        onDragEnd,
        isSocket
    } = props;
    const [lotNumber,setLotNumber]=useState('')
    const [lotTitle,setLotTitle]=useState('')

    return(
        <>
            {deleteLotModal && <DeleteLotModal handleDelete={handleDelete} setDeleteLotModal={setDeleteLotModal} 
                lotNumber={lotNumber} loader={loader} lotTitle={lotTitle}
                deleteLotModal={deleteLotModal} />}
            {lots.map((lot,index)=>(
                <LotSingle lot={lot} key={index} index={index} lotDetailsFromSocket={lotDetailsFromSocket} 
                    setLotNumber={setLotNumber} setLotTitle={setLotTitle} auctionData={auctionData} 
                    onDragStart={onDragStart} onDragEnter={onDragEnter} onDragEnd={onDragEnd} 
                    setDeleteLotModal={setDeleteLotModal} isSocket={isSocket} />
            ))}
        </>
    );
}
