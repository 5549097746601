/* eslint-disable security/detect-possible-timing-attacks */
import { useState } from "react";
import NewPasswordFooter from "./newPasswordFooter";
import NewPasswordHeader from "./newPasswordHeader";
import SetNewPasswordForm from "./setNewPasswordForm";
import SuccessMessage from "./successMessage";
import { dataService } from "../../../services/dataService";
import CryptoJS from "crypto-js";

export default function SetNewPassword(){

    const [password, setPassword]=useState('')
    const [confirmPassword,setConfirmPassword]=useState('');
    const [passwordError, setPasswordError]=useState(null);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [success, setSuccess]=useState(false);
    const [passwordValid, setPasswordValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        symbol: false,
    });
    const [loader, setLoader] = useState(false)

    /**
     * The clickHandler function is used to handle a click event, validate a password, encrypt it, and
     * then confirm the password.
     */
    const clickHandler=async(e)=>{
        e.preventDefault();
        setLoader(true)
        let valid = true;
        if(password!==confirmPassword){
            setPasswordError('Password do not match');
            valid=false
            setLoader(false)
        }
        if(valid){
            const secretPass = "XkhZG4fW2t2W";
            const edata = CryptoJS.AES.encrypt(
                JSON.stringify(confirmPassword),
                secretPass
            ).toString();
            const body = {
                "token": localStorage.getItem("verify_token"),
                "password": confirmPassword,
                "encrypted_password": edata
            }
            const data = await dataService.ConfirmPassword(body);
            if(data.data.status===401){
                setPasswordError('The link has expired. Request a new password Reset');
                setLoader(false)
            }
            else{
                setSuccess(true);
                setLoader(false)
            }
        }
        
    }

    /**
     * The function `handlePasswordChange` is used to handle changes in the password input field and
     * update the password state and validation flags accordingly.
     */
    const handlePasswordChange=(e)=>{
        const { id, value }=e.target;
        if(id=='password'){
            setPasswordValid({
                length: value.length >= 8 && value.length <= 16,
                uppercase: /[A-Z]/.test(value),
                lowercase: /[a-z]/.test(value),
                number: /\d/.test(value),
                symbol: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|='"`']/g.test(value),
            });
            setPassword(value);
            if(confirmPassword.length!==0){
                if(value!==confirmPassword){
                    setPasswordError('Password do not match');
                }else{
                    setPasswordError(null);
                }
            }
        }else{
            if(password!==value){
                setPasswordError('Password do not match');
            }else{
                setPasswordError(null);
            }
            setConfirmPassword(value);
        }
        
    }

    return(
        <div className="bg-custom-image5 h-screen  bg-cover bg-no-repeat">
            <div className="sm:px-10 px-6 py-8">
                <NewPasswordHeader/>
                {!success ? <SetNewPasswordForm clickHandler={clickHandler}
                    showPassword={showPassword}
                    password={password}
                    passwordError={passwordError}
                    handlePasswordChange={handlePasswordChange}
                    setShowPassword={setShowPassword}
                    confirmPassword={confirmPassword}
                    showConfirmPassword={showConfirmPassword}
                    setShowConfirmPassword={setShowConfirmPassword}
                    passwordValid={passwordValid}
                    setPasswordError={setPasswordError}
                    loader={loader} /> : <SuccessMessage/>}
                <NewPasswordFooter />
            </div>
        </div>
    );
}