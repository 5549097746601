import React from 'react'

export default function PaymentDetails({orderDetail,loading}) {
    return (
        <div className='border border-[#DEDEDE] rounded-lg mb-7'>
            <h6 className='text-[#343434] text-[16px] font-[500] leading-normal md:py-5 py-3.5 
                    md:px-10 px-5 border-b border-[#CECECE]'>Payment details</h6>
            <div className='flex justify-between md:pt-8 pt-5 md:pb-[30px] pb-5 md:px-10 px-5 flex-wrap'>
                <div className='w-[32%] mr-2.5 mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Payment type</label>
                    {!loading?
                        <p className='text-[16px] text-[#343434] font-[500] leading-normal' data-testid="payment_info">
                            {Object.keys(orderDetail).length>0 && 
                                ((orderDetail?.payment_method_types[0]).charAt(0).toUpperCase() + 
                                (orderDetail?.payment_method_types[0]).slice(1))==='Card'?'Stripe':
                                ((orderDetail?.payment_method_types[0]).charAt(0).toUpperCase() + 
                                (orderDetail?.payment_method_types[0]).slice(1))}</p>
                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
                <div className='w-[32%] mr-2.5 mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Payment status</label>
                    {!loading?
                        <div className='flex items-center'>
                            <p 
                                className={`text-[12px] 
                                    ${orderDetail?.payment_status=='Paid'?'text-[#489892] bg-[#A8D9C8]':
            'text-[#B98B47] bg-[#E3C79D]'} 
                                    font-[500] leading-normal 
                            rounded-[42px] h-8 w-24 flex justify-center items-center`}>
                                {orderDetail?.payment_status}</p>
                        </div>
                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
                <div className='w-[32%] mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>
                        Payment reference number</label>
                    {!loading?
                        <p className='text-[16px] text-[#343434] font-[500] leading-normal'>
                            {orderDetail?.payment_intent}</p>
                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
            </div>
        </div>  
    )
}
