/* eslint-disable security/detect-object-injection */
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from './listingHeaderSales'
import ListingBody from './listingBody';
import { dataService } from '../../services/dataService';
import { useLocation, useSearchParams } from 'react-router-dom';
import Paginate from './paginate';
import { round } from 'lodash';
import ModalBidder from '../AllBidders/modalBidders';
import ToastMeassage from '../../CommonComponent/toastMeasseage';

export default function AllSales() {
    // const [searchParams, setSearchParams] = 
    // useSearchParams({ page:  1, 
    
    // });

    const [date, setDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('')
    const [selectedType, setSelectedType] = useState('')

    const [searchParams, setSearchParams] = 
        useSearchParams({ page:  1});
    const [sales,setSales]= useState([]);
    const [loading, setLoading]=useState(false);
    const [salesInfo,setSalesInfo]= useState([]);
    const [message, setMessage]=useState("");
    const [isFilterApplied, setIsFilterApplied]=useState(false);
    const [profile,setProfile]=useState({})
    const [currentSortColumn, setCurrentSortColumn] = useState({key:null, sort_order: 'ascending'});
    const location = useLocation()
    const [sendToDate, setsendToDate] = useState('');
    const [sendFromDate, setsendFromDate] = useState('');

    const [ isOpen,setIsOpen] = useState(false);
    const [showExportModel, setShowExportModel] =useState('')
    const [exportBtnLoading, setExportBtnLoading]=useState(false)
    const [toastMeasseage, setToastMeassage] = useState("");
    const [loader,setLoader]=useState(false);
    const [bidderExportCsvUrl, setBidderExportCsvUrl]=useState('');

    const formatDates = (inputDate) => {
        const date = new Date(inputDate * 1000);
       
        // const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate
       
    };

    const handleExport = () => {
        let params = Object.fromEntries(searchParams);
        params['export'] = 'True'
        setSearchParams({...params})
        setLoader(true)

    }

    const handleFetch=useCallback(async()=>{
        setLoading(true)
        const params = Object.fromEntries(searchParams)
        let endpoint=""
        if(searchParams.get('page')=== null){
            endpoint = `?page=1`
        }else{
            endpoint = `?page=${Number(searchParams.get('page'))}`
        }

        if((searchParams.get('keyword')=== null && sales.length===0)){
            setLoading(true);
        }
        if((searchParams.get('export')!== null)){
            endpoint += `&export=True`
        }
        if(searchParams.get('keyword')){
            endpoint += `&search=${searchParams.get('keyword')}`
        }
        if(searchParams.get('filter')){
            endpoint += `&filter=${searchParams.get('filter')}`
            setIsFilterApplied(true)
        }
        if(searchParams.get('sort_by')!==null){
            endpoint += `&sort_by=${String(searchParams.get('sort_by'))}&sort_order=${String(searchParams.get('sort_order'))}`
        }if(searchParams.get('payment_status')!==null){
            endpoint += `&payment_status=${searchParams.get('payment_status').replaceAll("+", ' ')}`
            setSelectedStatus({ status: searchParams.get('payment_status') })
        }if(searchParams.get('payment_type')!==null){
            endpoint += `&payment_type=${searchParams.get('payment_type').replaceAll("+", ' ')}`
            setSelectedType({ type: searchParams.get('payment_type') })
        }
        if(searchParams.get('start_date')!==null){
            endpoint += `&start_date=${searchParams.get('start_date')}&end_date=${searchParams.get('end_date')}`
            setDate(formatDates(Number(searchParams.get('start_date'))))
            setToDate(formatDates(Number(searchParams.get('end_date'))))
        }
        const response=await dataService.AllSalesList(endpoint)
        if(response.error===false){
            setSales(response.data.data);
            setSalesInfo(response.data);
            setLoading(false);
            if(response.data?.download_link !== null){
                window.open(response.data.download_link, '_blank');
                delete params.export
                
                setIsOpen(false);
                setMessage("Export complete");
                delete params.export
                setSearchParams({...params})
                setLoader(false);
                setTimeout(() => {
                    setMessage("");
                }, 3000);
            }
        }else{
            setLoading(false);
        }
         
        
    },[location])

    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParams)
        setSearchParams({ ...params, page: pageNum.selected + 1 })
    }

    const handleFilter=(status,type, fromDate, toDate)=>{
        let params = Object.fromEntries(searchParams);
        params['filter'] = 'True'
        if(status==='' && fromDate==='' && toDate==='' && type==""){
            return;
        }
        if(status.status!==undefined){
            params['payment_status']=status.status.replaceAll("%20", ' ');
            setIsFilterApplied(true);
        }else{
            delete params.status;
        }
        if(type.type!==undefined){
            params['payment_type']=type.type.replaceAll("%20", ' ');
            setIsFilterApplied(true);
        }else{
            delete params.type;
        }
        if(fromDate!==''){
            const fromDateStartOfDay = new Date(fromDate);
            fromDateStartOfDay.setHours(0, 0, 0, 0); // Set to 12:00 AM
            const fromDateTimestamp = round(Math.floor(fromDateStartOfDay.getTime()) / 1000);
            params['start_date']=fromDateTimestamp;
            setIsFilterApplied(true);
        }else{
            delete params.start_date
        }
        if(toDate!==''){
            const toDateEndOfDay = new Date(toDate);
            toDateEndOfDay.setHours(23, 59, 0, 0); // Set to 11:59 PM
            const toDateTimestamp =round(Math.floor(toDateEndOfDay.getTime()) / 1000);
            params['end_date']=toDateTimestamp;
        }else{
            delete params.end_date
        }
        if (params['status'] === undefined) delete params.status;
        setSearchParams({...params, page: 1 })
    }

    const handleSearch=(e, key, value)=>{
        e.preventDefault();
        let params = Object.fromEntries(searchParams);
        params.page = 1
        if(key === 'keyword') {
            params[key] = encodeURIComponent(value); 
        }else {
            params[key] = value
        }
        if(params['keyword'] === '') delete params.keyword
        setSearchParams({ ...params})
    }


    const handleSort=(e,key)=>{
        let params = Object.fromEntries(searchParams);
        if(searchParams.get('sort_by') ==null) {
            params['sort_by'] = `${key}`
            params['sort_order'] = `ascending`
            setCurrentSortColumn({
                key: key,
                sort_order: 'ascending'
            });
        }else {
            if(searchParams.get('sort_by')==key) 
            {
                if(searchParams.get('sort_order')==='ascending') {
                    params['sort_order'] = `descending`
                    setCurrentSortColumn({
                        key: key,
                        sort_order: 'descending'
                    });
                }else{
                    params['sort_order'] = `ascending`
                    setCurrentSortColumn({
                        key: key,
                        sort_order: 'ascending'
                    });
                }
            }
            else {
                params['sort_by'] = key
                params['sort_order'] = `ascending`
                setCurrentSortColumn({
                    key: key,
                    sort_order: 'ascending'
                });
            }
        }
        setSearchParams({ ...params})
    }

    useEffect(() => {
        handleFetch();
        if(searchParams.get('filter')===null){
            setIsFilterApplied(false)
            setSelectedStatus('')
            setSelectedType('')
            setDate('')
            setsendFromDate('')
            setsendToDate('')
            setToDate('')
        }
    }, [location]);

    /**
     * The function handleChangePaymentInfo takes one parameter and calls a api endpoint.
     */
    const handleChangePaymentInfo = async (orderId, {paymentType, paymentStatus}) => {
        const response = await dataService.changePaymentInfo(orderId, {paymentType, paymentStatus})
        if(!response.error){
            handleFetch()
        }
    }

    return (
        <div className="sm:mx-10 mx-5 sm:my-8 mt-8 mb-20 ">
            {/* { modalOpen.isModalOpen && <AcceptBidderModal modalOpen={modalOpen} setModalOpen={setModalOpen} 
                approveBidderHandler={approveBidderHandler} loader={loader} />} */}
            {isOpen && <ModalBidder isOpen={isOpen} setIsOpen={setIsOpen} handleExport={handleExport} 
                loader={loader} type="sales" />} 
            {message!=="" && <ToastMeassage message={message}  />}
            <TableHeader
                sales={sales}
                profile={profile}
                message={message}
                loading={loading}
                setSalesInfo={setSalesInfo}
                setMessage={setMessage}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                handleSearch={handleSearch} 
                salesInfo={salesInfo}
                date={date}  
                setDate={setDate}
                toDate={toDate} 
                setToDate={setToDate} 
                selectedStatus={selectedStatus} 
                selectedType={selectedType}
                setSelectedStatus={setSelectedStatus}
                setSelectedType={setSelectedType}
                isFilterApplied={isFilterApplied}
                setIsFilterApplied={setIsFilterApplied}
                handleFilter={handleFilter}
                sendToDate={sendToDate}
                setsendToDate={setsendToDate}
                sendFromDate={sendFromDate}
                setsendFromDate={setsendFromDate}
                handleExport={handleExport}
                setIsOpen={setIsOpen}
                // exportHandler={exportHandler}
                
            />
            <div className="pb-5 pt-2 overflow-auto ">
                <ListingBody
                    sales={sales}
                    handleSort={handleSort}
                    loading={loading}
                    setSalesInfo={setSalesInfo}
                    isFilterApplied={isFilterApplied}
                    searchParams={searchParams}
                    currentSortColumn={currentSortColumn}
                    onChangePaymentInfo={handleChangePaymentInfo}
                />
            </div>
            {(salesInfo.current_page <= salesInfo.total_pages) &&
            <Paginate
                totalPageNumber={Math.ceil(salesInfo.total_records/10)}
                selectedPageNumber={Number(searchParams.get('page')) || 1}
                handleOnClickPageNumber={handleOnClickPageNumber} 
            />
            } 
        </div>
    )
}
