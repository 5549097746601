import React from 'react'

export default function ShippingAddress({orderDetail, loading}) {
    return (
        <div className='border border-[#DEDEDE] rounded-lg mb-7'>
            <h6 className='text-[#343434] text-[16px] font-[500] leading-normal md:py-5 py-3.5 
                    md:px-10 px-5 border-b border-[#CECECE]'>Billing address</h6>
            <div className='md:pt-8 pt-5 md:pb-[30px] pb-5 md:px-10 px-5'>
                <div className='border border-[#DEDEDE] rounded-lg md:py-[18px] py-5 md:px-[38px] px-5' 
                    data-testid="shipping_address">
                    {!loading?
                        <>
                            <p className='text-[#4A4A4A] text-sm font-[500] leading-normal pb-2.5'>
                                {Object.keys(orderDetail).length>0 && 
                                  `${orderDetail?.shipping_address['first_name']} ${' '} 
                                  ${orderDetail?.shipping_address['last_name']}`}
                            </p>
                            {Object.keys(orderDetail).length > 0 && (
                                <p className='text-[#4A4A4A] text-[16px] font-[500] leading-normal'>
                                    {orderDetail?.shipping_address['address_line1']} <br />
                                    {orderDetail?.shipping_address['address_line2']} <br />
                                    {orderDetail?.shipping_address['city']} <br />
                                    {orderDetail?.shipping_address['postal_code']} <br />
                                    {orderDetail?.shipping_address['country']}
                                </p>
                            )}
                        </>
                        :<p className='w-[32%] animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
            </div>
        </div>  
    )
}
