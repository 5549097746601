import { Link } from "react-router-dom";

export default function SuccessMessage(){
    return(
        <>
            <div className="xl:w-[30%] lg:w-[35%] md:w-[45%]  sm:w-[60%] w-full  bg-white sm:mt-20 mt-10 md:float-right 
                    lg:mr-20 md:mr-5 mx-auto rounded-[10px] h-fit password-changed-prompt">
                <div className="px-8  sm:h-customh8 h-customh2  flex justify-center flex-col items-center">
                    <p className="sm:text-[22px] text-[20px] text-[#282828] font-medium text-center leading-normal">
                        Your password has been successfully changed</p>
                    <p className="text-center text-[18px] text-[#A1A1A9] leading-normal  font-medium">
                        Sign in with your new details</p>
                    <Link to="/login" className="mt-3 w-full flex justify-center text-[#FFFFFF] 
                            cursor-pointer text-[14px] leading-normal bg-[#282828] rounded-md border border-[#FFF] px-8 
                            py-3 set-pswd-btn font-medium">Go to Sign In</Link>
                </div>
            </div>
        </>
    )
}