import React from 'react'
import { Link } from 'react-router-dom';

export default function RegistrationFooter() {
    return (
        <div>
            <div className=" items-center pt-[16px] pb-10  sm:hidden flex flex-wrap justify-center">
                <p className="text-[#FFF] font-medium  xs:text-[16px] text-[14px] leading-normal ">Already have an account?</p>
                <Link to="/login" id="signin-btn" 
                    className="ml-2 cursor-pointer text-[#FFF] rounded-md border border-[#FFF] px-8 
                    py-1.5 text-sm font-medium">Sign in</Link>
            </div>
            <div className="md:fixed md:block hidden bottom-12">
                <p className="xl:text-[50px] lg:text-[40px] md:text-[30px] text-[#FFF] font-medium leading-normal">
                    Master the Art of <br /> Auctioneering</p>
                <p className="text-[#FFF] mt-3 mb-4 font-medium">Professional auction software for anyone <br /> 
                    wanting to run their own auction.</p>
                <a href='https://www.indy.auction' target='_blank' 
                    id="feature-btn" 
                    className=" cursor-pointer text-[#FFF] rounded-[6px] border 
                    border-[#FFF] px-8 py-1.5 text-sm font-medium" rel="noreferrer">Features</a>
            </div>
            <footer className="sm:fixed mx-auto sm:mx-0 bottom-6 sm:right-10 ">
                <div className="flex  items-center sm:justify-end justify-center ">
                    <a href="https://www.instagram.com/indy.auction/" 
                        target='_blank' rel="noreferrer"><img  src="images/instagram-icon.svg" alt="instagram" 
                            loading="lazy" /></a> 
                    <a href="https://www.facebook.com/theauctioncollective/" target='_blank' rel="noreferrer">
                        <img className="px-4" src="images/facebook-icon.svg" alt="facebook" loading="lazy" /></a> 
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FAucCollective" 
                        target='_blank' rel="noreferrer">
                        <img className="pr-4" src="images/twitter-icon.svg" alt="twitter" loading="lazy" /></a>
                    <a href="https://www.linkedin.com/company/the-auction-collective/"
                        target='_blank' rel="noreferrer">
                        <img  src="images/linkedin-icon.svg" alt="linkedin" loading="lazy" /></a>
                </div>
                <p className="text-[#FFF] sm:text-end text-center sm:pt-3 pt-5 text-xs font-medium">
                    © Copyright 2024 Indy.auction. All Rights Reserved</p>
            </footer>
        </div>
    )
}
