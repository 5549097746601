import React from 'react'

export default function Empty() {
    return (
        <div className='text-center h-[450px] border-[#DEDEDE] border rounded-lg text-[#343434] 
            font-medium leading-normal text-[16px] md:text-[20px] flex justify-center items-center'>
            There are no insights available for this auction.
        </div>
    )
}
