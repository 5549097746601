import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function DashboardShimmer() {
    return (
        <div className="sm:px-10 px-5 sm:py-8 pt-8 pb-14 overflow-y-auto">
            <p className="sm:text-[34px] text-[28px] font-medium leading-normal text-[#343434]">
                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                    <Skeleton count={1} className='h-[45px] max-w-[400px] pt-4 ' />
                </SkeletonTheme>
            </p>
            <div className="border border-[#DEDEDE] rounded-[10px] mt-[22px]">
                <div className="flex items-center px-8 py-[22px] border-b border-[#CECECE]">  
                    <img src="images/start-first-auction-logo.svg" alt="auction-icon" loading="lazy" />
                    <p className="pl-2.5 text-[16px] leading-normal text-[#343434] font-medium w-[200px]">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4 w-10">
                            <Skeleton count={1} className='h-[20px] w-[155px]' />
                        </SkeletonTheme>
                    </p>
                </div> 
                <div className="flex relative justify-center flex-col   h-[230px]  items-center">
                    {/* <img src="images/blur-img.png" alt="blurimg" className="sm:block hidden" loading="lazy" /> */}
                    <div className="absolute  flex items-center justify-center flex-col">
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[30px] min-w-[50px] pt-4 ' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[25px] min-w-[300px] pt-4 ' />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[40px] min-w-[155px] pt-4 ' />
                        </SkeletonTheme>
                    </div>
                </div> 
            </div>
            <div className="mt-5 w-full flex flex-wrap sm:mb-0 mb-14 justify-between">
                <div className="bg-[#e0e0e0] flex flex-col justify-center items-center xmd:mr-[18px] 
                            lg:w-[28%] lg:mb-0 
                mb-4 xmd:w-[48%] w-full rounded-[10px] pt-[50px] pb-[60px] px-10 h-[400px]">
                </div>
                <div className="bg-[#e0e0e0] flex flex-col justify-center items-center lg:mr-[18px] lg:mb-0 mb-4 
                lg:w-[28%] xmd:w-[48%] w-full rounded-[10px] pt-[50px] pb-[44px] px-10 h-[400px]">
                </div>
                <div className=" rounded-[10px] bg-[#e0e0e0] lg:w-[38%] w-full h-[400px]">
                </div>
            </div>
        </div>    
    )
}
