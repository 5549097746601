import React, { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Oval } from  'react-loader-spinner'

export function PaymentInfoModal({
    isOpen,
    setIsOpen,
    handleOK,
    loader,
    disabled = false,
    paymentStatus: initPaymentStatus,
    paymentType: initPaymentType
}) {
    const [paymentType, setPaymentType] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')

    const handleSubmit = () => {
        handleOK({paymentType, paymentStatus})
    }

    useEffect(() => {
        if (initPaymentStatus && paymentStatus !== initPaymentStatus) setPaymentStatus(initPaymentStatus)
        if (initPaymentType && paymentType !== initPaymentType) setPaymentType(initPaymentType)
    }, [initPaymentStatus, initPaymentType])

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as='div' className='relative z-[99]' onClose={()=> setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-black bg-opacity-80' />
                </Transition.Child>
                <div className='fixed left-0 top-0 z-[1055] h-full w-full 
                    overflow-y-auto overflow-x-hidden outline-none'>
                    <div className='flex min-h-full items-center justify-center p-4 text-center'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                        >
                            <Dialog.Panel className='w-full transform overflow-hidden rounded-[10px]
                                    bg-white text-left align-middle shadow-xl transition-all 
                                    min-[640px]:mx-auto min-[640px]:mt-7 min-[640px]:min-h-[calc(100%-3.5rem)] 
                                    min-[640px]:max-w-[520px]'>
                                <Dialog.Title className='flex flex-shrink-0 items-center justify-between rounded-t-md 
                                    pt-5 pb-3.5 md:pl-7 pl-5 pr-3 border-b border-[#A1A1A9]'>
                                    Payment Info
                                    <button
                                        type='button'
                                        id='close-icon'
                                        className='box-content rounded-none border-none absolute top-4 right-4 z-10'
                                        data-te-modal-dismiss
                                        aria-label='Close' onClick={()=>setIsOpen(false)}>
                                        <img src='/images/CloseButton-modalcontent.svg' alt='img' loading='lazy'/>
                                    </button>
                                </Dialog.Title>
                                <div className='relative'>
                                    <div className='flex flex-col px-[80px] py-[50px]'>
                                        <div className='flex flex-col'>
                                            <label className='mb-2 text-sm font-medium'>Payment type</label>
                                            <select
                                                className='border py-2 px-3'
                                                value={paymentType}
                                                onChange={(e) => setPaymentType(e.target.value)}
                                            >
                                                <option value=''>-</option>
                                                <option value='Offline'>Offline</option>
                                                <option value='Stripe' disabled>Stripe</option>
                                                <option value='Paypal' disabled>Paypal</option>
                                            </select>
                                        </div>

                                        <div className='flex flex-col mt-[30px]'>
                                            <label className='mb-2 text-sm font-medium'>Payment status</label>
                                            <select
                                                className='border py-2 px-3'
                                                value={paymentStatus}
                                                onChange={(e) => setPaymentStatus(e.target.value)}
                                            >
                                                <option value=''>-</option>
                                                <option value='Paid'>Paid</option>
                                                <option value='Unpaid'>UnPaid</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='drop-shadow-[0px_-4px_4px_rgba(0, 0, 0, 0.06)]'>
                                        <div className='flex justify-center py-[10px]'>
                                            <button
                                                id='save-btn'
                                                disabled={loader || disabled}
                                                className='px-[31px] py-[12px] bg-[#282828] text-[#FFFFFF] leading-normal 
                                                    font-medium
                                                    text-[14px] rounded-[6px] flex justify-center disabled:bg-[#D4D4D8]' 
                                                onClick={handleSubmit}
                                            >
                                                Save
                                                {loader && (
                                                    <Oval
                                                        height={20}
                                                        width={50}
                                                        color='#FFFFFF'
                                                        wrapperClass=''
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor='#000000'
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}