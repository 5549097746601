import { useOutletContext } from "react-router-dom";
import DashboardAuction from "./insights";
import Empty from "./empty";

export default function Insights(){

    const outletContext = useOutletContext();

    const { auctionData } = outletContext;

    return(
        <div>
            {auctionData.status === 'Draft' ? <Empty /> : <DashboardAuction  auctionData={auctionData}/>}
        </div>
    )
}
