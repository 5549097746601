import { useOutletContext } from "react-router-dom"
import BidsHeader from "./bidsHeader"
import ListingBody from "./listingBody"
import { useEffect, useState } from "react";
import Paginate from "../../listAuction/paginate";
import ToastMeassage from "../../../../CommonComponent/toastMeasseage";

export default function BidsList(){

    const outletContext = useOutletContext();

    const { bidsFetchHandler,searchParam, setSearchParam, bids,loadBids, loading, setLoadBids, 
        bidsInfo,currentSortColumn,setCurrentSortColumn,auctionData, socketConn } = outletContext;


    const [searchValue, setSearchValue]=useState("");
    const [showExoprtToastMsg, setShowExportToastMsg]=useState(false)

    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParam)
        setSearchParam({ ...params, page: pageNum.selected + 1 })
    }

    useEffect(()=>{
        setLoadBids(true);
    },[])

    useEffect(() => {
        setSearchValue(
            searchParam.get('search_term')
                ? decodeURIComponent(searchParam.get('search_term'))
                : ''
        );
    }, [searchParam]);

    /**
     * The function `handleSearch` takes in an event, a key, and a value, and updates the search
     * parameters based on the key-value pair.
     */
    const handleSearch=(e, key, value)=>{
        e.preventDefault();
        let params = Object.fromEntries(searchParam);
        params.page = 1
        if(key === 'search_term') {
            // eslint-disable-next-line security/detect-object-injection
            params[key] = encodeURIComponent(value); 
        }else {
            // eslint-disable-next-line security/detect-object-injection
            params[key] = value
        }
        if(params['search_term'] === ''){ 
            delete params.search_term
            setLoadBids(true);
        }
        setSearchParam({ ...params})
    }
    /**
     * The function `handleSort` is used to update the search parameters based on the sorting key and
     * order.
     */
    const handleSort=(e,key)=>{
        let params = Object.fromEntries(searchParam);
        if(searchParam.get('sort') === false || searchParam.get('sort') === null) {
            params['sort'] = `${key}`
            params['order'] = `asc`
            setCurrentSortColumn({
                key: key,
                order: 'asc'
            });

        }else {
            if(searchParam.get('sort').includes(key)) 
            {
                if(searchParam.get('order')==='asc') {
                    params['order'] = `desc`
                    setCurrentSortColumn({
                        key: key,
                        order: 'desc'
                    });
                }else{
                    params['order'] = `asc`
                    setCurrentSortColumn({
                        key: key,
                        order: 'asc'
                    });
                }

            }
            else {
                params['sort'] = key
                params['order'] = `asc`
                setCurrentSortColumn({
                    key: key,
                    order: 'asc'
                });

            }
        }
        setSearchParam({ ...params})

    }


    useEffect(()=>{
        bidsFetchHandler(searchParam.get('page'));
    },[searchParam])

    return(
        <div>

            <BidsHeader searchParam={searchParam} searchValue={searchValue} setSearchParam={setSearchParam}
                setSearchValue={setSearchValue} handleSearch={handleSearch} bidsInfo={bidsInfo} 
                setShowExportToastMsg={setShowExportToastMsg} bids={bids}/>
            {showExoprtToastMsg && <ToastMeassage message={'Export complete'}/>}

            <ListingBody currentSortColumn={currentSortColumn} handleSort={handleSort} auctionData={auctionData}
                searchParam={searchParam} bids={bids} searchValue={searchValue} loadBids={loadBids} loading={loading} />
            {(bidsInfo?.current_page <= bidsInfo?.total_pages) &&
            <div className='pt-[80px]'>
                <Paginate totalPageNumber={Math.ceil(bidsInfo.total_records_found/10)}
                    selectedPageNumber={Number(searchParam.get('page')) || 1}
                    handleOnClickPageNumber={handleOnClickPageNumber} />
            </div>
            }
        </div>
    )
}
