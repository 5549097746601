import React from 'react'

export default function BuyerInformation({orderDetail,loading}) {
    return (
        <div className='border border-[#DEDEDE] rounded-lg mb-7'>
            <h6 className='text-[#343434] text-[16px] font-[500] leading-normal md:py-5 py-3.5 
                    md:px-10 px-5 border-b border-[#CECECE]'>Buyer information</h6>
            <div className='flex justify-between md:pt-8 pt-5 md:pb-[30px] pb-5 md:px-10 px-5 flex-wrap' 
                data-testid="buyer_info">
                <div className='md:w-[32%] w-full mr-2.5 mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Email address</label>
                    {!loading?
                        <p className='text-[16px] text-[#343434] font-[500] leading-normal'>
                            {orderDetail?.email_address}</p>
                        :<p className='md:w-[32%] w-full animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
                <div className='md:w-[32%] w-full mr-2.5 mb-2.5'>
                    <label className='text-[10px] text-[#343434] font-[500] leading-normal pb-1'>Name</label>
                    {!loading?
                        <p className='text-[16px] text-[#343434] font-[500] leading-normal'>
                            {Object.keys(orderDetail).length>0 && 
                                  `${orderDetail?.shipping_address['first_name']} ${' '} 
                                  ${orderDetail?.shipping_address['last_name']}`}
                        </p>
                        :<p className='md:w-[32%] w-full animate-pulse h-10 bg-[#f0efeff3]'></p>}
                </div>
            </div>
        </div>  
    )
}
