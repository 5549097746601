/* eslint-disable react/jsx-no-target-blank */
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { React,useState } from "react";

export default function MobileFooter(){
    const [isOpen, setIsOpen] = useState(false)
    const path = useLocation();

    /**
     * The function `toDashboard` removes an item from the sessionStorage with the key 'storedRoute'.
     */
    // const toDashboard=()=>{
    //     sessionStorage.removeItem('storedRoute');
    // }

    // mt-10

    return(
        <>
            <div className="bg-[#282828] w-full fixed bottom-0 px-5 py-6 flex 
                    justify-between items-center h-20 z-10 md:hidden">
                <Link to='/' >
                    <img src={path.pathname === '/' || path.pathname==='/dashboard' ? 
                        "/images/dashboard-icon.svg":
                        "/images/dashboard-inactive-icon.svg"} alt="dashboardicon" loading="lazy" className={`p-4 
                        ${path.pathname === '/' || path.pathname==='/dashboard'? 'bg-[#343434]':''} 
                        rounded-[10px] hover:bg-[#3F3F45] cursor-pointer`}/>
                </Link>
                <Link to='/account' >
                    <img src={path.pathname==='/account' ? "/images/account-active-icon.svg":
                        "/images/accounts-dashboard.svg"} alt="account" loading="lazy" className={`p-4 
                        ${path.pathname === '/account' ? 'bg-[#343434]':''}
                        hover:bg-[#3F3F45] rounded-[10px] cursor-pointer`}/>
                </Link>
                <Link to='/auctions' >
                    <img src={path.pathname==='/auctions' || path.pathname==='/auctions/create' ? 
                        "/images/auction-active.svg":
                        "/images/auction-logo-dashboard.svg"} alt="account" loading="lazy" className={`p-4 
                        ${path.pathname === '/auctions' || path.pathname==='/auctions/create' ? 'bg-[#343434]':''}
                        hover:bg-[#3F3F45] rounded-[10px] cursor-pointer`}/>
                </Link>
                <Link to='/allsales' >
                    <img src={path.pathname==='/allsales' || path.pathname==='/allsales' ? 
                        "/images/sales.svg":
                        "/images/sale.svg"} alt="allsales" loading="lazy" className={`p-4 
                        ${path.pathname === '/allsales' || path.pathname==='/allsales' ? 'bg-[#343434]':''}
                        hover:bg-[#3F3F45] rounded-[10px] cursor-pointer`}/>
                </Link>
               
                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="53" viewBox="0 0 2 53" fill="none">
                    <path d="M1 53V0" stroke="#3F3F45"/>
                </svg>
                <button className="flex cursor-pointer items-center p-4 hover:bg-[#3F3F45] rounded-[10px]" 
                    onClick={()=>setIsOpen((prevState) => !prevState)} 
                    type="button"
                    data-te-offcanvas-toggle
                    data-te-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    <p className="text-[11px] font-medium leading-normal text-[#FFFFFF]">More</p>
                    <img src="/images/three-dots-white.svg" alt="threedots" loading="lazy"/>
                </button>
            </div>
            <Drawer
                open={isOpen}
                onClose={()=>setIsOpen((prevState) => !prevState)}
                direction='right'
                className='bg-[#282828]'
            >
                <div className="flex items-center justify-end p-4">
                    <button type="button"
                        onClick={()=>setIsOpen((prevState) => !prevState)}
                        className="mb-0 font-medium text-[11px] pr-4 leading-normal text-[#FFFFFF]"
                        id="offcanvasRightLabel">
                        Close
                    </button>
                    <button
                        type="button"
                        onClick={()=>setIsOpen((prevState) => !prevState)}
                        className="box-content rounded-none border-none opacity-50 hover:no-underline 
                                    hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                        data-te-offcanvas-dismiss>
                        <span
                            className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none 
                                        disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none 
                                        [&.disabled]:opacity-25">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" 
                                fill="none">
                                <rect x="1.04103" width="11.7715" height="1.47144" transform="rotate(45 1.04103 0)" 
                                    fill="white"/>
                                <rect x="9.36427" y="1.04004" width="11.7715" height="1.47144" 
                                    transform="rotate(135 9.36427 1.04004)" fill="white"/>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="offcanvas-body flex-grow overflow-y-auto p-4">
                    <div className="flex flex-col justify-between">
                        <ul className="mx-6">
                            <li className={`px-4 hover:bg-[#343434] rounded-[11px] 
                            ${path.pathname === '/'?'bg-[#343434]':''}`}>
                                <Link to="/"  className="flex items-center py-3.5">
                                    <img src={path.pathname === '/' || path.pathname==='/dashboard' ? 
                                        "/images/dashboard-icon.svg":
                                        "/images/dashboard-inactive-icon.svg"} alt="dashboard" loading="lazy"/>
                                    <span className={`text-start text-[14px] font-medium pl-3.5
                                    ${path.pathname === '/' ? 'text-[#FFFFFF]':'text-[#A1A1A9]'}`}>
                                        Dashboard</span>
                                </Link>
                            </li>
                            <li className={`px-4 hover:bg-[#343434] rounded-[11px] 
                            ${path.pathname === '/account' ? 'bg-[#343434]':''}`}>
                                <Link to="/account" className="flex items-center my-[15px] py-3.5">
                                    <img src={path.pathname==='/account' ? "/images/account-active-icon.svg":
                                        "/images/accounts-dashboard.svg"} alt="account" loading="lazy"/>
                                    <span className={`text-start text-[14px] font-medium pl-3.5
                                    ${path.pathname === '/account' ? 'text-[#FFFFFF]':'text-[#A1A1A9]'}`}>
                                        Account</span>
                                </Link>
                            </li>
                            <li className={`px-4 hover:bg-[#343434] rounded-[11px] 
                            ${path.pathname === '/auctions' || path.pathname === '/auctions/create' || 
                            path.pathname.includes('/auctions') || 
                            path.pathname==='/auctions/lots' ? 'bg-[#343434]':''}`}>
                                <Link to='/auctions' className="flex items-center my-[15px] py-3.5">
                                    <img src={path.pathname==='/auctions' || path.pathname==='/auctions/create' ||
                                    path.pathname==='/auctions/lots' || path.pathname.includes('/auctions')
                                        ? "/images/auction-active.svg":
                                        "/images/auction-logo-dashboard.svg"} alt="auction" loading="lazy"/>
                                    <span 
                                        className={`text-start text-[14px] font-medium pl-3.5
                                        ${path.pathname === '/auctions' || path.pathname==='/auctions/create' || 
                                        path.pathname.includes('/auctions') ||
                                        path.pathname==='/auctions/lots' ? 
            'text-[#FFFFFF]':'text-[#A1A1A9]'}`}>
                                        Auctions</span>
                                </Link>
                            </li>
                            <li className={`px-4 hover:bg-[#343434] rounded-[11px]
                             ${path.pathname === '/allsales' || path.pathname === '/allsales' || 
                            path.pathname.includes('/allsales') || 
                            path.pathname==='/allsales' ? 'bg-[#343434]':''}`}>
                                <Link to="/allsales" className="flex items-center
                                 my-[15px] py-3.5">                                    
                                    <img src={path.pathname==='/allsales' || 
                                path.pathname.includes('/allsales') || path.pathname==='/allsales' 
                                || path.pathname==='/allsales' ? 
                                        "/images/sales.svg":
                                        "/images/sale.svg"} alt="auction" loading="lazy" />
                                    <span className={`
                                     ${path.pathname === '/allsales' || path.pathname==='/allsales' || 
                                        path.pathname.includes('/allsales') ||
                                        path.pathname==='/allsales' ? 
            'text-[#FFFFFF]':'text-[#A1A1A9]'}
                                     text-start text-[14px] font-medium pl-3.5`}>
                                        All Sales</span>
                                </Link>
                            </li>
                            <li className={`px-4 hover:bg-[#343434] rounded-[11px] 
                            ${path.pathname === '/allbidders' ? 'bg-[#343434]':''}`}>
                                <Link to="/allbidders" className="flex items-center my-[15px] py-3.5">
                                    <img src={path.pathname==='/allbidders' ? "/images/allBiddersHigh.svg":
                                        "/images/all-bidders-dashboard.svg"} alt="allbidders" loading="lazy"/>
                                    <span className={`text-start text-[14px] font-medium pl-3.5
                                    ${path.pathname === '/allbidders' ? 'text-[#FFFFFF]':'text-[#A1A1A9]'}`}>
                                        All Bidders</span>
                                </Link>
                            </li>
                            <li className={`px-4 hover:bg-[#343434] rounded-[11px] 
                            ${path.pathname === '/pricing' ? 'bg-[#343434]':''}`}>
                                <Link to="/pricing" className="flex items-center my-[15px] py-3.5">
                                    <img src={path.pathname==='/pricing' ? "/images/pricing-active.svg":
                                        "/images/pricing-logo-dashboard.svg"} alt="profile-icon" loading="lazy"/>
                                    <span className={`text-start text-[14px] font-medium pl-3.5 
                                    ${path.pathname === '/pricing' ? 'text-[#FFFFFF]':'text-[#A1A1A9]'}`}>
                                        Pricing</span>
                                </Link>
                            </li>
                            <li className="px-4 hover:bg-[#343434] hover:rounded-[11px]">
                                <a href='https://support.indy.auction/' target='_blank' 
                                    className="flex items-center my-[15px] py-3.5">
                                    <img src="/images/help-center.svg" alt="helpcenter" loading="lazy"/>
                                    <span className="text-[#A1A1A9] text-start text-[14px] font-medium pl-3.5">
                                        Help Centre</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Drawer>
        </>
    );
}