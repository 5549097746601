/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import {formatDate} from '../../../../../CommonComponent/dateConverter'
import { Listbox, Transition } from '@headlessui/react';
import { timeDifference, timeZoneMap, timezoneValues } from '../../../../../CommonComponent/staticValues';
import ToolTip from '../../../../../CommonComponent/tooltip';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import disableFieldsBasedOnAuctionStatus from '../../../disableFieldsBasedOnAuctionStatus';

export default function SetUpAuctionDates({auctionData,setAuctionData, startDate,setStartDate,endDate,
    setEndDate,startHour,setStartHour,startHourShow, setEndTimestamp,setStartTimestamp,
    setStartHourShow,startMin,setStartMin,startMinShow, setEditedAuctionData,
    setStartMinShow,endHour,setEndHour,endHourShow,setEndHourShow,endMin,setEndMin,
    endMinShow,setEndMinShow,timezoneShow,setTimezoneShow, invalidDate,setInvalidDate, sameTime, setSameTime}) {
    const state = useLocation()
    setStartHourShow(state.state===null?false:true)
    setStartMinShow(state.state===null?false:true)
    setEndHourShow(state.state===null?false:true)
    setEndMinShow(state.state===null?false:true)
    setTimezoneShow(state.state===null?false:true)
    const currentDate = new Date();


    /* The above code is a React useEffect hook that is triggered whenever the values of `startDate`,
    `endDate`, `startHour`, `endHour`, `startMin`, `endMin`, or `auctionData.time_zone` change. */
    useEffect(()=>{

        // Convert timestamp to time in the specified timezone

        if(startDate!=='' || endDate!==''){
            let start = new Date(startDate);
            let end = new Date(endDate);
            let syear = start.getFullYear();
            let smonth = start.getMonth(); // Months are zero-indexed in JavaScript (0 - 11)
            let sdate = start.getDate();

            let eyear = end.getFullYear();
            let emonth = end.getMonth(); // Months are zero-indexed in JavaScript (0 - 11)
            let edate = end.getDate();

            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);

            // Increment month by 1 to match moment-timezone's month format (1 - 12)
            // smonth += 1;
            // emonth += 1;

            // Call the function to create DateTime objects

            const IST = moment.tz(auctionData.time_zone=="" ? 'Etc/UTC' : timeZoneMap[auctionData.time_zone]);
            
            const startDateTime = IST.clone().set({ year: syear, month: smonth , date: sdate, hour: startHour, minute: startMin, second: 0, millisecond: 0 });

            const timestamp = startDateTime.valueOf();

            if(state.state==null){
                setEditedAuctionData((prevData)=>({...prevData, start_date: parseInt(timestamp)}))

            }else{
                if(state.state.start_date!== timestamp){
                    setEditedAuctionData((prevData)=>({...prevData, start_date: parseInt(timestamp)}))
                }else{
                    setEditedAuctionData((prevData) => {
                        const { start_date, ...remainingData } = prevData;
                        return remainingData;
                    })
                }
            }
            setAuctionData((prevData)=>({...prevData, start_date: parseInt(timestamp)}))
            
            const IST1 = moment.tz(auctionData.time_zone=="" ? 'Etc/UTC' : timeZoneMap[auctionData.time_zone]);
            
            const endDateTime = IST1.clone().set({ year: eyear, month: emonth , date: edate, hour: endHour, minute: endMin, second: 0, millisecond: 0 });
            
            // Convert to timestamp (Unix timestamp in milliseconds)
            
            const timestamp1 = endDateTime.valueOf();
            if(state.state==null){
                setEditedAuctionData((prevData)=>({...prevData, end_date: parseInt(timestamp1)}))
            }else{
                if(state.state.end_date!==timestamp1){
                    setEditedAuctionData((prevData)=>({...prevData, end_date: parseInt(timestamp1)}))
                }else{
                    setEditedAuctionData((prevData) => {
                        const { end_date, ...remainingData } = prevData;
                        return remainingData;
                    })
                }
            }
            setAuctionData((prevData)=>({...prevData, end_date: parseInt(timestamp1)}))   

            if(start>end){
                setInvalidDate(true);
                setSameTime(false);
            }else if(end>start){
                setInvalidDate(false);
                setSameTime(false);
            }else{
                setInvalidDate(false);
                if(startHour!=='' && endHour!=='' && startMin!=='' && endMin!==''){
                    if(parseInt(startHour)==parseInt(endHour)){
                        if(parseInt(startMin)>=parseInt(endMin)){
                            setSameTime(true);
                        }else{
                            setSameTime(false);
                        }
                    }else if(parseInt(startHour)>parseInt(endHour)){
                        setSameTime(true);
                    }else{
                        setSameTime(false);
                    }
                }else{
                    setSameTime(false);
                }
            }
            
        }
    },[startDate,endDate,startHour, endHour,startMin, endMin, auctionData.time_zone])
   
    /**
     * Function to return the disabled state 
     * from disabled fields maping based on it's
     * auction status
     * @param {*} field 
     * @returns 
     */
    const returnDisableState = (field)=>{
        let statusCheck = false;
        let status = auctionData?.status ? auctionData.status : "";
        if(status)
            status = status !== "Accepting bids" ? status.toLowerCase() : "acceptingBids"
        switch(field) {
        case "startHour": {
            statusCheck = disableFieldsBasedOnAuctionStatus[`${status}`]?.startHour;
            break;
        }
        case "startMin": {
            statusCheck = disableFieldsBasedOnAuctionStatus[`${status}`]?.startMin;
            break;
        }
        case "timeZone": {
            statusCheck = disableFieldsBasedOnAuctionStatus[`${status}`]?.timeZone;
            break;
        }
        default:
            break;
        }
        return statusCheck;
    }
    return (
        <div id="auction-dates-content" className="auction-dates-content">
            {/* <!-- start date --> */}
            <div className="pt-4 pb-3">
                <div className="flex">
                    <p className="font-medium text-[#343434] text-[12px] leading-normal">Start date</p>
                    <ToolTip   
                        title="The date the auction will be 'live' for bidding.">
                        <img src="/images/info-icon.svg" className={`pl-2 cursor-pointer`} 
                            alt="info-icon" loading="lazy" />
                    </ToolTip>
                </div>                
                <DatePicker
                    disabled={auctionData.status=="Accepting bids"}
                    minDate={currentDate}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    selected={startDate}
                    onChange={(startDate) => setStartDate(startDate)}
                    customInput={
                        <div className={`border flex mt-1 relative h-11 py-2.5 focus:border-[#343434] 
                            ${auctionData.status=="Accepting bids" ? "bg-[#F4F4F5] border-[#c9c9cc]" : "border-[#A1A1A9]"} `}>
                            <input id='start-date' autoComplete='off' type="text" disabled={auctionData.status=="Accepting bids"}
                                className={`text-[14px] z-2 pl-3 pr-7 w-full text-start outline-none font-medium 
                                disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] 
                                ${auctionData.status=="Accepting bids" ? 'cursor-default' : 'cursor-pointer'}
                                leading-normal text-[#343434] placeholder:text-[#A1A1A9]`}
                                value={startDate!=='' ? formatDate(startDate):''}
                                placeholder='Select' />
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon"
                                className="absolute cursor-pointer top-[19px] right-4" />
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5
                                            pointer-events-none">
                            </div>
                        </div>
                    }
                />
            </div>
            {/* <!-- dropdown for dates--> */}
            <label for="country" className="text-[12px] mt-3 font-medium leading-normal text-[#343434]">Start time</label>
            <div className="flex w-full">
                <Listbox as="div" disabled={returnDisableState("startHour")}
                    className="relative w-1/2" value={startHour} onChange={(value) => {setStartHourShow(true),
                    setStartHour(value)}}>
                    {({ open }) => (
                        <>
                            <Listbox.Button
                                type="button"
                                className={`text-[14px] text-start relative w-full h-11 px-3 border border-[#A1A1A9] disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                                ${startHourShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`}
                                id="hours1"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                            >
                                {`${startHour !== '' ? String(startHour).padStart(2, '0') : 'Hour'}`}
                            </Listbox.Button>
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                className="absolute top-[22px] right-[15px]" loading="lazy" />
                            <Listbox.Options 
                                className="bg-white rounded-[8px] z-10 absolute 
                            w-full [data-te-dropdown-show]:block shadow-bs1 h-[155px] customScrollbar 
                            overflow-y-auto overflow-x-hidden " 
                                aria-labelledby="dropdownMenuButton2" data-te-dropdown-menu-ref>
                                <div className="pr-[15px] py-1">
                                    {Array.from({ length: 24 }, (_, index) => (
                                        <Listbox.Option key={index} value={index} 
                                            className="pl-[6px] pr-[6px] pt-2 text-[10px]
                                        leading-normal font-medium text-[#4A4A4A] cursor-pointer">
                                            {({ active, selected }) => (
                                                <p className={`${active?'bg-[#E6E6E6]':''} rounded-[11px] px-2 py-1.5`}>
                                                    {index.toString().padStart(2, '0')}
                                                </p>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </>
                    )}
                </Listbox>
                <Listbox as="div" disabled={returnDisableState("startMin")}
                    className="relative w-1/2 ml-3" value={startMin} onChange={(value) => {setStartMinShow(true),
                    setStartMin(value)}}>
                    {({ open }) => (
                        <>
                            <Listbox.Button
                                type="button"
                                className={`text-[14px] text-start relative w-full h-11 px-3 border border-[#A1A1A9] disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                                ${startMinShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`}
                                id="minutes1"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                            >
                                {`${startMin!=='' ? String(startMin).padStart(2, '0') : 'Mins'}`}
                            </Listbox.Button>
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                className="absolute top-[22px] right-[15px]" loading="lazy" />
                            <Listbox.Options 
                                className="bg-white rounded-[8px] z-10 absolute 
                            w-full [data-te-dropdown-show]:block shadow-bs1 h-[155px] customScrollbar 
                            overflow-y-auto overflow-x-hidden" 
                                aria-labelledby="dropdownMenuButton2" data-te-dropdown-menu-ref>
                                <div className="pr-[15px] py-1">
                                    {Array.from({ length: 60 }, (_, index) => (
                                        <Listbox.Option key={index} value={index} 
                                            className="pl-[6px] pr-[6px] pt-2 text-[10px] 
                                        leading-normal font-medium text-[#4A4A4A] cursor-pointer">
                                            {({ active, selected }) => (
                                                <p className={`${active?'bg-[#E6E6E6]':''} rounded-[11px] px-2 py-1.5`}>
                                                    {index.toString().padStart(2, '0')}
                                                </p>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </>
                    )}
                </Listbox>
            </div>
            {/* <!-- end date --> */}
            <div className="pt-4 pb-3">
                <div className="flex">
                    <p className="font-medium end-date-label text-[#343434] text-[12px] leading-normal">End date</p>
                    <ToolTip   
                        title="The date the auction will close.">
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </ToolTip>
                </div>
                <DatePicker
                    selected={endDate}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    onChange={(endDate) => {setEndDate(endDate)}}
                    customInput={
                        <div className="border enddate-border flex mt-1 relative border-[#A1A1A9] h-11 py-2.5 
                focus:border-[#343434]" data-te-dropdown-ref>
                            <input id='end-date' autoComplete='off' datepicker datepicker-autohide type="text" 
                                className=" text-[14px] z-2 pl-3 pr-7 w-full cursor-pointer 
                    text-start outline-none font-medium leading-normal text-[#343434] placeholder:text-[#A1A1A9]"
                                value={endDate!==''?formatDate(endDate):''} 
                                placeholder="Select" />
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" loading="lazy" 
                                className="absolute cursor-pointer top-[19px] right-4" />
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                            </div>
                        </div>}
                />
                
                {invalidDate &&
                <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                text-[#984848]">End date is before the start date</p>}
            </div>
            {/* <!-- dropdown for dates--> */}
            <label for="country" className="text-[12px] mt-3 font-medium leading-normal text-[#343434]">End time</label>
            <div className="flex w-full">
                <Listbox as="div" className="relative w-1/2" value={endHour} onChange={(value) => {setEndHourShow(true),
                setEndHour(value)}}>
                    {({ open }) => (
                        <>
                            <Listbox.Button
                                type="button"
                                className={`text-[14px] text-start relative w-full h-11 px-3 border border-[#A1A1A9] 
                                ${endHourShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`}
                                id="dropdownMenuButton2"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                            >
                                {`${endHour !== '' ? String(endHour).padStart(2, '0') : 'Hour'}`}
                            </Listbox.Button>
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                className="absolute top-[22px] right-[15px]" loading="lazy" />
                            <Listbox.Options 
                                className="bg-white rounded-[8px] z-10 absolute 
                            w-full [data-te-dropdown-show]:block shadow-bs1 h-[155px] customScrollbar 
                            overflow-y-auto overflow-x-hidden" 
                                aria-labelledby="dropdownMenuButton2" data-te-dropdown-menu-ref>
                                <div className="pr-[15px] py-1">
                                    {Array.from({ length: 24 }, (_, index) => (
                                        <Listbox.Option key={index} value={index} 
                                            className="pl-[6px] pr-[6px] pt-2 text-[10px] 
                                        leading-normal font-medium text-[#4A4A4A] cursor-pointer">
                                            {({ active, selected }) => (
                                                <p className={`${active?'bg-[#E6E6E6]':''} rounded-[11px] px-2 py-1.5`}>
                                                    {index.toString().padStart(2, '0')}
                                                </p>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </>
                    )}
                </Listbox>
                <Listbox as="div" className="relative w-1/2 ml-3" value={endMin} onChange={(value) => {setEndMinShow(true),
                setEndMin(value)}}>
                    {({ open }) => (
                        <>
                            <Listbox.Button
                                type="button"
                                className={`text-[14px] text-start relative w-full h-11 px-3 border border-[#A1A1A9] 
                                ${endMinShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`}
                                id="dropdownMenuButton2"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                            >
                                {`${endMin!=='' ? String(endMin).padStart(2, '0') : 'Mins'}`}
                            </Listbox.Button>
                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                className="absolute top-[22px] right-[15px]" loading="lazy" />
                            <Listbox.Options 
                                className="bg-white rounded-[8px] z-10 absolute 
                            w-full [data-te-dropdown-show]:block shadow-bs1 h-[155px] customScrollbar 
                            overflow-y-auto overflow-x-hidden" 
                                aria-labelledby="dropdownMenuButton2" data-te-dropdown-menu-ref>
                                <div className="pr-[15px] py-1">
                                    {Array.from({ length: 60 }, (_, index) => (
                                        <Listbox.Option key={index} value={index} 
                                            className="pl-[6px] pr-[6px] pt-2 text-[10px] 
                                        leading-normal font-medium text-[#4A4A4A] cursor-pointer">
                                            {({ active, selected }) => (
                                                <p className={`${active?'bg-[#E6E6E6]':''} rounded-[11px] px-2 py-1.5`}>
                                                    {index.toString().padStart(2, '0')}
                                                </p>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Listbox.Options>
                        </>
                    )}
                </Listbox>
            </div>
            {(startHour===''&& endHour!==''||startMin===''&& endMin!=='')&&
                <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                text-[#984848]">End time is before the start time</p>}
            {sameTime &&
                <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                text-[#984848]">End time should be greater than start time</p>}
            {/* <!-- dropdown for timezone--> */}
            <Listbox as="div" disabled={returnDisableState("timeZone")} value={auctionData.time_zone} className="relative w-full" 
                onChange={(value)=>{
                    setAuctionData(prevData=>(setTimezoneShow(true),{...prevData, time_zone: value.name}))
                    setEditedAuctionData(prevData=>(setTimezoneShow(true),{...prevData, time_zone: value.name}))
                }}>
                {({ open }) => (
                    <>
                        <div className="flex mt-4">
                            <p className="text-[12px] font-medium leading-normal text-[#343434]">Time zone</p>
                            <ToolTip   
                                title="The timezone that corresponds to the auction countdown clock and 
                                which will be displayed to bidders.">
                                <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                            </ToolTip>
                        </div>
                        <Listbox.Button type="button" 
                            className={`text-[14px] text-start relative w-full h-11 px-3 border border-[#A1A1A9] disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                                ${timezoneShow?'text-[#343434]':'text-[#A1A1A9]'} outline-none font-medium leading-normal`} 
                            id="time-zone">{auctionData.time_zone===''?'Select':auctionData.time_zone +" "+ timeDifference[auctionData.time_zone] }</Listbox.Button>
                        <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                            className={`absolute top-[40px] right-[15px] 
                            ${open?'rotate-180':'transform-none'}`}
                            loading="lazy" />
                        <Listbox.Options 
                            className="bg-white rounded-[8px] z-30  absolute w-full [&[data-te-dropdown-show]]:block 
                shadow-bs1 list-none" aria-labelledby="time-zone">
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className=" pr-[15px] py-1  h-[155px] customScrollbar overflow-y-auto 
                                overflow-x-hidden">
                                    {timezoneValues.map((timezone) => (

                                        <Listbox.Option key={timezone.id} value={timezone} as={Fragment}
                                            className="pl-[18px] pr-[15px] py-2 list-none
                        text-[14px]  leading-normal font-medium text-[#343434]">
                                            {({ active, selected }) => (

                                                <li
                                                    className={`py-2 cursor-pointer rounded-[11px] px-2.5 text-[#343434] ${
                                                        active ? 'bg-[#E6E6E6]' : 'bg-white'
                                                    }`}
                                                >
                                                    {timezone.name} {timeDifference[timezone.name]}
                                                </li>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Transition>
                        </Listbox.Options>
                    </>
                )}
            </Listbox>
        </div>    
    )
}
