/* eslint-disable security/detect-object-injection */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {};
/* The code is creating a slice of the Redux store called `pricingSlice`. */
const bidsSlice = createSlice({
    name: 'bids',
    initialState,
    reducers: {
        setBids: (state, action) => {
            return { ...state, bids: action.payload };
        }
    }
})
export const { setBids} = bidsSlice.actions;
export default bidsSlice.reducer;