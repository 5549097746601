/* eslint-disable security/detect-object-injection */
import { createSlice } from '@reduxjs/toolkit';

const initialState=""

/* The code is creating a Redux slice using the `createSlice` function from the `@reduxjs/toolkit`
library. */
const nameSlice= createSlice({
    name: 'name',
    initialState,
    reducers: {
        setName: (state, action) => {
            return action.payload;
        }
    }
})

export const { setName } = nameSlice.actions;

export default nameSlice.reducer;