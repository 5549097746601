import { timeZoneMap } from "../../../../CommonComponent/staticValues";
import config from "../../../../config";

export default function SingleRow(props){

    const { bid, auctionData } =props;


    const formatDate = (timestamp) => {
        const date = new Date(timestamp); // Convert timestamp to Date
  
        // Format options for date and time
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
            hour12: false, // Force 24-hour format
            timeZone: timeZoneMap[auctionData.time_zone ? auctionData.time_zone : 'Asia/Kolkata'],
        };
  
        // Format the date using toLocaleString
        let formatted = date.toLocaleString('en-GB', options);
        if(formatted.includes('GMT+5:30')){
            formatted = formatted.replace('GMT+5:30', 'IST');
        }else if(formatted.includes('GMT+11')){
            formatted = formatted.replace('GMT+11', 'AESR');
        }else if(formatted.includes('GMT+13')){
            formatted = formatted.replace('GMT+13', 'NZST');
        }else if(formatted.includes('GMT+1')){
            formatted = formatted.replace('GMT+1', 'CET');
        }else if(formatted.includes('GMT+9')){
            formatted = formatted.replace('GMT+9', 'JST');
        }else if(formatted.includes('GMT+8')){
            formatted = formatted.replace('GMT+8', 'CST');
        }
  
        formatted = formatted.replace(/,/g, ' /');
  
        return formatted;
    };

    return(
        <tr className="border-b border-[#DEDEDE]" >
            <td className=" text-[#343434]  px-5 py-3 text-[14px] leading-normal font-medium ">
                {bid.lot_number}</td>
            <td><img  
                src={bid.lot_image==="" ? "/images/img-lotlist.png" : 
                    `${config.Storage.CDN}${bid.lot_image}`}
                className="my-3 w-[95px] h-[77px]" alt="img"/></td>
            <td className=" text-[#343434] px-4 py-3 text-[14px]  leading-normal font-medium" 
                // onClick={()=>Navigate(`/auctions/${id}/lots/view/${lot.lot_number}`, { state: isSocket})}
            >
                {bid.lot_title.length > 20 ? bid.lot_title.substring(0, 20) + '...' : bid.lot_title}</td>
            <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                {bid.paddle_number===""  ? '-' : bid.paddle_number}
            </td>
            <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                {bid.name===""  ? '-' : (bid.name.length > 20 ? bid.name.substring(0, 20) + '...' : bid.name)}
            </td>
            <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                <span className={` px-4 py-2 rounded-[42px] ${(bid.bid_status==="Winning" || bid.bid_status==="Won")  ? 
                    "bg-[#A8D9C8] text-[#489892]" : 
                    ( bid.bid_status==="UnderBidder" ? "bg-[#E3C79D] text-[#B98B47] " : "bg-[#E6E6E6] text-[#52525A]" )}`} >
                    {bid.bid_status==="UnderBidder" ? "Underbidder" : bid.bid_status}</span>
            </td>
            <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                {(bid.bid_amount===0 || bid.bid_amount===undefined) ? "-" : 
                    Number(bid.bid_amount).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                        style: 'currency',
                        currency: auctionData.currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
            </td>
            <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                {bid.time_stamp ? (bid.time_stamp==="" ? '-' : formatDate(bid.time_stamp)) : '-'}</td>
            {/* <td className=" sm:relative  sm:bg-transparent bg-[#fff]  sticky top-0 right-0">
                <div className=" sm:px-4 px-2 h-fit py-[43px] sm:shadow-none shadow-bs7 ">
                    <span className="flex justify-end ">
                        <Menu as="div" className="relative" >
                            <Menu.Button>
                                <img src="/images/three-dots-icon-black.svg" alt="img" 
                                    className="cursor-pointer three-dots-icon" type="button"
                                    id="action-lot" />
                            </Menu.Button>
                            <Menu.Items>
                                <div style={{"top" : "-80px"}} className="dropdown-cont absolute overflow-hidden 
                                rounded-[10px] float-left 
                                                    m-0 w-[150px] py-2 min-w-max right-0 z-[50] px-2
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3 ">
                                    <Menu.Item>
                                        <button  data-testid="view-lot" 
                                        // onClick={()=>Navigate(`/auctions/${id}/lots/view/${lot.lot_number}`,
                                         { state: isSocket})}
                                            className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto
                                             text-left block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] cursor-pointer 
                                                            
                                                            hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                        >View bids</button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button data-testid='update-lot' 
                                            // onClick={()=>Navigate(`/auctions/${id}/lots/${lot.lot_number}`,
                                            //     {state : auctionData})}
                                            className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 text-left
                                                            text-[12px] font-medium text-[#343434] cursor-pointer 
                                                            hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                        >Edit</button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button 
                                            // onClick={()=>{setDeleteLotModal(true); setLotNumber(lot.lot_number);
                                            //     setLotTitle(lot.title1) }}
                                            className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5
                                             hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            py-2 text-[12px] font-medium text-[#343434] cursor-pointer text-left"
                                            data-testid="delete-lot"
                                        >Delete</button>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Menu>
                    </span>  
                </div> 
            </td> */}
        </tr>
    )
}