import React, { useRef, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Oval } from  'react-loader-spinner'


export default function ImportLotModal({showImportModal, setShowImportModal, handleImportFile,uploadedFileName,
    setUploadedFileName,importLoader }) {

    const fileInput = useRef();
    const [uploadFileError, setUploadFileError] = useState('')

    /**
     * The function `handleFileName` is a JavaScript React function that handles the file name of an
     * uploaded file.
     */
    const handleFileName = (e)=>{
        e.preventDefault()
        const newArray = fileInput.current.files;
        if(newArray['length']==1){
            if(newArray[0].type==='text/csv'){
                setUploadFileError('')
                setUploadedFileName(newArray)
            }
            else{
                setUploadFileError('Please select a CSV file to import.')
                setUploadedFileName('')
            }
        }
    }

    return (
        <Dialog as="div" open={showImportModal} onClose={() => setShowImportModal(false)}
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-[#292929cc]"
            id="exampleModalXl8"
            tabindex="-1"
            aria-labelledby="exampleModalXlLabel"
            aria-modal="true"
            role="dialog">
            <Dialog.Panel
                data-te-modal-dialog-ref
                className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center 
                mx-3 transition-all duration-300 ease-in-out min-[640px]:mx-auto min-[640px]:mt-7 
                min-[640px]:min-h-[calc(100%-3.5rem)] min-[640px]:max-w-[658px]">
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding 
                    text-current shadow-lg outline-none">
                    <div
                        className="flex flex-shrink-0 items-center justify-between border-b border-[#A1A1A9] rounded-t-md 
                        pl-4 pr-3 py-4">
                        <h2 className="text-[22px] font-medium leading-normal text-[#343434]">Upload file</h2>
                        <button onClick={() => {setShowImportModal(false),setUploadedFileName('')}}
                            type="button"
                            className="box-content rounded-none border-none">
                            <img src="/images/CloseButton-modalcontent.svg" alt="img" />
                        </button>
                    </div>
                    <div className="relative mt-12 sm:mx-[68px] mx-8 h-[230px] flex flex-col justify-center items-center 
                    border-opacity-50  border-[#282828] border-2 border-dashed rounded-[10px]">
                        <p className="text-[16px] font-medium leading-normal mb-2 text-[#343434]">Drag file here</p>
                        <p className="text-[12px] font-medium leading-normal text-[#343434]">Or</p>
                        <div className="w-[120px] h-fit rounded-md border-[#A1A1A9] py-1 border mt-2 flex justify-center 
                        items-center relative">
                            <div>
                                <label for="upload-lot" className="z-0 cursor-pointer  text-[14px]">Choose file</label>
                                <input id="upload-lot" type="file" title="Upload Image" onChange={(e)=>handleFileName(e)}
                                    className="opacity-0 absolute top-0 left-0 
                                text-[0px] w-[120px] h-[40px] cursor-pointer z-10"
                                    accept=".csv"
                                    ref={fileInput} />
                            </div>
                        </div>
                        {uploadedFileName!=='' &&
                            <p className='text-[12px] my-2 text-[#343434] leading-normal'>
                                {uploadedFileName[0].name.replace(' ','')}</p>
                        }
                        {uploadFileError!=='' && 
                            <p className='text-[#984848] text-[12px] leading-normal py-2'>{uploadFileError}</p>
                        }
                        <p className="text-[12px] font-medium leading-normal text-[#343434] mt-2.5">
                            Accepted file type: CSV</p> 
                    </div>
                    <div className="flex justify-center mt-6 pb-9">
                        <button type='button' id="upload-lot-btn"
                            onClick={(e)=>handleImportFile(e)} disabled={uploadedFileName===''||
                        uploadFileError!==''|| importLoader} 
                            className=" px-[31px] py-[12px] bg-[#282828]  text-[#FFFFFF] leading-normal font-medium 
                        text-[14px] rounded-[6px] disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] flex 
                        justify-center items-center" data-te-modal-dismiss
                            aria-label="Close">
                            Upload
                            {importLoader && <Oval
                                height={20}
                                width={50}
                                color="#FFFFFF"
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#000000"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />}
                        </button>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}
