import React from 'react'
import {motion} from 'framer-motion'

export default function SuccessMessage({successMessage}) {
    return (
        <motion.div
            initial={{ opacity: 0 }}  animate={{ opacity: 1 }} 
            className="mt-[15px] px-5 success-msg-csv py-3 bg-[#A8D9C8] border border-[#489892] rounded-[6px]">
            <div className="flex items-center">
                <img src="/images/verify-account.svg" alt="img" className="pr-3" />
                <div>
                    <p id='successMsg' className="text-[12px] leading-normal font-bold text-[#343434]">{successMessage}</p>
                </div>
            </div>
        </motion.div>    
    )
}
