/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable max-len */
import { Menu } from '@headlessui/react'
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Link, useLocation, useNavigate , useParams} from 'react-router-dom';
import DeleteModal from '../listAuction/DeleteModal';
import { dataService } from '../../../services/dataService';
import PublishAuctionModal from './publishAuctionModal';
import config from '../../../config';
import UnPublishAuctionModal from './unpublishAuctionModal';
import CancelModal from '../listAuction/DeactivateModal';
import { Auth } from 'aws-amplify';
import { logout } from '../../auth/registrationSlice';
import { useDispatch } from 'react-redux';
import { clearData } from '../../account/profileSlice';

export default function Header({auctionData, cloneHandler, lotInfo, handleView, domain, bidInformation, lots, bidsInfo,setMessage, setUploadErrorMessage}){
    const state = useLocation();
    const {id, uid} = useParams();
    const Navigate=useNavigate();
    const [isDeleteModalOpen,setIsDeleteModalOpen] = useState(false)
    const [isCancelModalOpen,setIsCancelModalOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [unpublishModalOpen,setUnpublishModalOpen]= useState(false); 
    const [isUnPublished, setIsUnPublished] = useState(false)
    const [publishLoader,setPublishLoader] = useState(false)
    const [isRequiredFieldEmpty, setIsRequiredFieldEmpty] = useState(false)
    const [notConnect, setNotConnect]=useState(null)
    const [isPublished, setIsPublished] = useState(false)
    const user = (localStorage.getItem('user'));
    const [noLot,setNoLot]=useState(false);
    const [loading,setLoading]=useState(false);
    const dispatch = useDispatch()
    const plan_type=localStorage.getItem('plan_type');


    /**
     * The function `handleDelete` is an asynchronous function that deletes an auction and updates the
     * state and local storage accordingly.
     */
    const handleDelete = async(id)=>{
        setLoader(true)
        const data = await dataService.DeleteAuction(id)
        if(data.error===false){
            setLoader(false)
            setIsDeleteModalOpen(false)
            Navigate('/auctions')
            localStorage.setItem('AuctionDelete', 'success');
        }
    }
    const handleCancel = async(auctionId)=>{
        setLoader(true)
        const body={
            "type": "CANCEL"
        }
        const response = await dataService.UnpublishAuction(auctionId,body);
        if(response.error===true){
            setIsCancelModalOpen(false)
            setMessage('Auction is already in cancelled');
            setTimeout(() => {
                setMessage('')
            }, 4000);
            handleView()
        }
        else{
            setLoader(false)
            setIsCancelModalOpen(false)
            setMessage('Auction cancelled successfully');
            setTimeout(() => {
                setMessage('')
            }, 4000);
            handleView()
        }
    }

    /**
     * The function `handlePublishAuction` is an asynchronous function that publishes an auction and
     * updates the state accordingly.
     */
    const handlePublishAuction = async(auctionId)=>{
        setPublishLoader(true)

        const body = {
            "email": user
        }
        if(!((auctionData.auction_image =="" || auctionData.title=="" || auctionData.description=="" || auctionData.currency=="" ) || 
            (auctionData.start_date =="" || auctionData.end_date=="" || auctionData.time_zone=="" || auctionData.start_date ==null || auctionData.end_date==null ) || 
            (((auctionData.extension_type =="Cascade" || auctionData.extension_type =="Individual lots") 
            && auctionData.extension_time_between_lots=="") || auctionData.extension_time=="" || auctionData.registration_type=="" 
            || ((auctionData.add_buyer_fees =="Add percentage"  && auctionData.percentage=="") || ( auctionData.add_buyer_fees=="Add fixed fee" 
            && auctionData.fees=="" ) ))) ){
            
            if(lots.length>0){
                const data = await dataService.publishAuction(auctionId)
                if(data.error==true){
                    if(data.message.response.data.message==="Auction is already published or is Accepting bids"){
                        setUploadErrorMessage('Auction is already in Published State')
                        setPublishModalOpen(false)
                        handleView()
                        setTimeout(() => {
                            setUploadErrorMessage('')
                        }, 3000);
                    }else if(data.message.response.data.message==="Some lots are missing lot images"){
                        setPublishModalOpen(false)
                        setIsRequiredFieldEmpty(true)
                        setNoLot(true);
                    }else if(data.message.response.data.message==="Stripe or PayPal account is not linked."){
                        setNotConnect("stripe")
                        setPublishModalOpen(false)
                        setIsRequiredFieldEmpty(true)
                    }else if(data.message.response.data.message.includes("verification")){
                        setNotConnect("verification")
                        setPublishModalOpen(false)
                        setIsRequiredFieldEmpty(true)
                    } else if(data.message.response.data.message==="Unauthorised to perform this action."){
                        Auth.signOut();
                        dispatch(logout())
                        dispatch(clearData())
                        localStorage.clear()
                        Navigate('/login')
                    }

                }else{
                    setIsPublished(true)
                    setPublishModalOpen(false)
                    setPublishLoader(false)
                    handleView()
                }
            }else{
                setPublishModalOpen(false)
                setIsRequiredFieldEmpty(true)
            }
        }else{
            setPublishModalOpen(false)
            setIsRequiredFieldEmpty(true)
        }

        setPublishLoader(false)
        // setIsPublished(false)
    }



    const handleTemplate=(template)=>{
        if(template==="Classic"){
            localStorage.setItem('selectedTemplateId','1')
            return "classic";
        }else if(template==="" || template==="Single lot" || template==="Single Lot"){
            localStorage.setItem('selectedTemplateId','2')
            return "single-lot";
        }else if(template==="Standalone"){
            localStorage.setItem('selectedTemplateId','3')
            return "standalone";
        }
    }
    
    const handleUnpublishAuction = async(auctionId)=> {
        setPublishLoader(true)
        const body={
            "type": "UNPUBLISH"
        }
        const data = await dataService.UnpublishAuction(auctionId, body)
        if(data.error==true){
            setUnpublishModalOpen(false)
            setPublishLoader(false)
            setUploadErrorMessage('Auction not in published state')
            handleView()
            window.scrollTo(0, 0);
            setTimeout(()=>{
                setUploadErrorMessage("")
            },3000)
        }else{
            setIsUnPublished(true)
            setUnpublishModalOpen(false)
            setPublishLoader(false)
            setMessage('Auction unpublished successfully')
            handleView()
            window.scrollTo(0, 0);
            setTimeout(()=>{
                setMessage("")
            },3000)
        }
    }


    return(
        <>
            <div className="flex justify-between items-center mt-8 sm:flex-row flex-col">
                <div className='sm:w-auto w-full'>
                    {auctionData.status===undefined?
                        <>
                            <p className='w-[70px]'>
                                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4 min-w-[70px]">
                                    <Skeleton count={1} className='h-[30px] rounded-[42px] w-[70px]'/>
                                </SkeletonTheme>
                            </p>
                            <p className='w-[300px]' >
                                <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4 min-w-[70px]">
                                    <Skeleton count={1} className='h-[30px] rounded-[42px] w-[300px]'/>
                                </SkeletonTheme>
                            </p>
                        </>:
                        <span data-testid="status" 
                            className={`relative py-[8px] mb-3 px-[25px] text-[12px] 
                                ${auctionData.status==='Draft'?'bg-[#D4D4D8] text-[#343434]':
            auctionData.status==='Cancelled' ?'text-[#984848] bg-[#CA8787]':
                auctionData.status==='Published'?'bg-[#E3C79D] text-[#B98B47]':
                    auctionData.status==='Accepting bids'?
                        'bg-[#A8D9C8] text-[#489892]':'bg-[#DDD1D5] text-[#343434]'} 
                    rounded-[42px] text-end leading-normal font-semibold`}>
                            {auctionData.status==='Accepting bids' &&
                            <img className="absolute top-[12px] left-3 " src="/images/green-dot.svg" alt="img" />}
                            {auctionData.status}</span>
                    }
                    <p id="auction-title" className="sm:text-[34px] text-[20px]  font-medium leading-normal text-[#343434] sm:pt-0 pt-3.5">
                        {auctionData.title===''?'Auction title goes here': (auctionData?.title?.length > 50 ? auctionData?.title?.substring(0, 50) + '...' : auctionData?.title) }</p>
                </div>
                {Object.keys(auctionData).length===0?
                    <div className='flex'>
                        <p className='mr-3'>
                            <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                                <Skeleton count={1} className='h-[40px] min-w-[130px] pt-4 mr-2 ' />
                            </SkeletonTheme>        
                        </p>  
                        <p className=''>
                            <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                                <Skeleton count={1} className='h-[40px] min-w-[130px] pt-4 ' />
                            </SkeletonTheme>        
                        </p>  
                    </div>

                    :
                    <div className="flex sm:mt-0 mt-3 sm:w-auto w-full sm:justify-start justify-end">
                        {(auctionData.status!=='Completed' && auctionData.status!=='Cancelled' ) &&
                        <>
                            <Link type='button' target='_blank' data-testid="view_preview" to={`https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}?isPreview=true`}
                                className="sx:px-[31px] px-5 py-[12px] bg-[#FFFFFF] mr-3 text-[#52525A] 
                    leading-normal border border-[rgb(161,161,169)] font-medium text-[14px] rounded-[6px] hover:bg-[#A1A1A9] hover:text-[#FFFFFF]">
                                Preview
                            </Link>
                            {/* {auctionData.status!=='Accepting bids'&&  */}
                            <button id='edit-auction' type='button'
                                onClick={()=>Navigate(`/auctions/edit/${id}/${uid}/${handleTemplate(auctionData?.template_name)}`,{state: auctionData===undefined?'':auctionData})} 
                                className="sx:px-[31px] px-5 py-[12px] bg-[#FFFFFF] mr-3 text-[#52525A] 
                        leading-normal border border-[#A1A1A9] font-medium text-[14px] rounded-[6px] hover:bg-[#A1A1A9] hover:text-[#FFFFFF]">
                                Edit
                            </button>
                           
                            {auctionData.status!=='Published' && auctionData.status!=='Accepting bids' && 
                            <button data-testid="publish-auction" 
                                className="sx:px-[31px] px-5 py-[12px] bg-[#282828] mr-2 text-[#FFFFFF] hover:bg-[#3F3F45]
                        leading-normal font-medium text-[14px] rounded-[6px]"  onClick={() => {
                                    auctionData.status==='Published' ? setUnpublishModalOpen(true) : setPublishModalOpen(true)
                                }}>
                                Publish
                            </button> }
                        </>
                        }
                        <Menu as="div" className="flex items-center relative " id="menu_id">
                            <Menu.Button className="relative" data-te-dropdown-ref>
                                <img src="/images/three-dots-icon-black.svg" alt="img" className="cursor-pointer"   type="button"
                                    id="dropdownMenuButton1"
                                    data-te-dropdown-toggle-ref
                                    aria-expanded="false"/>
                            </Menu.Button>
                            <Menu.Items 
                                className="absolute top-[50px] right-0 z-50 float-left m-0 w-[145px] py-2 min-w-max list-none 
                            overflow-hidden px-2
                                rounded-[10px] border-none bg-white bg-clip-padding text-left text-base shadow-bs3 
                                [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton2">
                                {(auctionData.status!=="Accepting bids" && auctionData.status!=="Cancelled") && <Menu.Item>
                                    <button onClick={()=>{cloneHandler()}}
                                        disabled={plan_type.includes("Free")}
                                        className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto block 
                                                        w-full whitespace-nowrap bg-transparent pl-3 pr-5 text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            py-2 text-[12px] font-medium text-[#343434] cursor-pointer"
                                    >Clone</button>
                                </Menu.Item> }        
                                {auctionData.status==='Published'&&
                                <Menu.Item>
                                    <a data-testid="publish-auction"
                                        className="cursor-pointer block w-full whitespace-nowrap bg-transparent pl-3 pr-5 
                                    py-1.5 text-[12px] hover:bg-[#E6E6E6] hover:rounded-[11px]
                                        font-medium text-[#343434]"
                                        onClick={() => {
                                            auctionData.status==='Published' ? setUnpublishModalOpen(true) : setPublishModalOpen(true)
                                        }}
                                    >Unpublish</a>
                                </Menu.Item>}  
                                {auctionData.status!=='Accepting bids' && auctionData.status!=='Completed' &&
                                <Menu.Item>
                                    <button type='button' 
                                        disabled={auctionData.status==="Accepting Bids" || 
                                    auctionData.status==='Completed'}
                                        data-testid="delete-auction"
                                        onClick={()=>{setIsDeleteModalOpen(true)}}
                                        className="cursor-pointer block w-full whitespace-nowrap bg-transparent pl-3 pr-5 
                                    py-1.5 text-[12px] text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                        font-medium text-[#343434]"
                                    >Delete</button>
                                </Menu.Item>}  
                                {auctionData.status==='Accepting bids' && 
                                <Menu.Item>
                                    <button type='button' data-testid="deactivate"
                                        onClick={()=>{setIsCancelModalOpen(true)}}
                                        className="cursor-pointer block w-full whitespace-nowrap bg-transparent pl-3 pr-5 
                                    py-1.5 text-[12px] text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                        font-medium text-[#343434]"
                                    >Cancel</button>
                                </Menu.Item>}  
                            </Menu.Items>
                        </Menu>
                    </div>}
                {/* <!-- box content --> */}
            </div>
            <div className="flex ss:flex-nowrap flex-wrap mt-6">
                <div className="ss:w-1/3 w-full min-h-[99px] lg:px-10 px-5 pt-3 ss:mb-0 mb-2.5 rounded-[20px] bg-[#DDD1D5]">
                    <p className="text-[12px] leading-normal font-semibold text-[#52525A]">SUM OF CURRENT BIDS</p>
                    {(lotInfo.sum_current_bid!=undefined && Object.keys(auctionData).length > 0 ) ? <p className="xmd:text-[30px] md:text-[20px] text-[30px] lg:text-[34px] leading-normal font-bold text-[#282828]">{bidInformation.sum_of_current_bids ? 
                        Number(bidInformation.sum_of_current_bids).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                            style: 'currency',
                            currency: auctionData.currency,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }) : (Number(lotInfo.sum_current_bid).toLocaleString(auctionData.currency ==='INR' ? 'en-IN' : 'en-US', {
                            style: 'currency',
                            currency: auctionData.currency,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }))}</p> :<p className='md:w-[30%] w-full animate-pulse h-10 bg-[#dbd9d9f3]'></p>}
                </div>
                <div className=" ss:w-1/3 w-full min-h-[99px] px-5 lg:px-10 pt-3 ss:mx-5 ss:mb-0 mb-2.5 rounded-[20px] bg-[#B8D5DB]">
                    <p className="text-[12px] leading-normal font-semibold text-[#52525A]">NUMBER OF ACTIVE BIDDERS</p>
                    {/* {(bidsInfo!=undefined && Object.keys(auctionData).length > 0 ) ? <p className="text-[30px] lg:text-[34px] leading-normal font-bold text-[#282828]">{bidsInfo.total_records_found ? bidsInfo.total_records_found : 0}</p>:<p className='md:w-[30%] w-full animate-pulse h-10 bg-[#dbd9d9f3]'></p>} */}
                    {lotInfo.number_of_bids!=undefined ? <p className="text-[30px] lg:text-[34px] leading-normal font-bold text-[#282828]">{bidInformation.number_of_bids ? bidInformation.number_of_bids : (lotInfo.number_of_bids)}</p>:<p className='md:w-[30%] w-full animate-pulse h-10 bg-[#dbd9d9f3]'></p>}
                </div>
                <div className="ss:w-1/3 w-full min-h-[99px] px-5 lg:px-10 pt-3 rounded-[20px] ss:mb-0 mb-2.5 bg-[#ECD6C7]">
                    <p className="text-[12px] leading-normal font-semibold text-[#52525A]">TOTAL SELLING</p>
                    {(lotInfo.number_of_bids!=undefined && Object.keys(auctionData).length > 0 ) ? <p className="text-[30px] lg:text-[34px] leading-normal font-bold text-[#282828]">{bidInformation.current_bidding_lot ? bidInformation.current_bidding_lot : (lotInfo["total selling"] ? lotInfo["total selling"] : "0")}
                        <span className='lg:text-[23px] text-[18px] ' > {bidInformation.total_selling ? `(${Number(bidInformation.total_selling).toFixed(2)}%)` : (lotInfo["total selling"] ? `(${(Number(lotInfo["total selling"]) / Number(lotInfo.total_lots) * 100).toFixed(2)}%)` : "" )}</span></p>:<p className='md:w-[30%] w-full animate-pulse h-10 bg-[#dbd9d9f3]'></p>}
                </div>
            </div>
            <DeleteModal isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} 
                handleDelete={handleDelete} loader={loader} selectedId={auctionData.auction_id}/>
            {(publishModalOpen || isRequiredFieldEmpty || isPublished) && 
            <PublishAuctionModal notConnect={notConnect} publishModalOpen={publishModalOpen} setPublishModalOpen={setPublishModalOpen} 
                handlePublishAuction={handlePublishAuction} loader={publishLoader} selectedId={auctionData.auction_id}
                auctionData={auctionData} lotInfo={lotInfo} isRequiredFieldEmpty={isRequiredFieldEmpty} domain={domain} noLot={noLot}
                setIsRequiredFieldEmpty={setIsRequiredFieldEmpty} isPublished={isPublished} setIsPublished={setIsPublished} loading={loading} />}
            
            <CancelModal isCancelModalOpen={isCancelModalOpen} setIsCancelModalOpen={setIsCancelModalOpen} 
                handleCancel={handleCancel} loader={loader} selectedId={auctionData.auction_id} />

            {unpublishModalOpen && 
            <UnPublishAuctionModal notConnect={notConnect} unpublishModalOpen={unpublishModalOpen} setUnpublishModalOpen={setUnpublishModalOpen} 
                handleUnpublishAuction={handleUnpublishAuction} loader={publishLoader} selectedId={auctionData.auction_id}
                auctionData={auctionData} lotInfo={lotInfo} isRequiredFieldEmpty={isRequiredFieldEmpty} domain={domain} noLot={noLot}
                setIsRequiredFieldEmpty={setIsRequiredFieldEmpty} isUnPublished={isUnPublished} setIsUnPublished={setIsUnPublished} loading={loading} />
            }

        </>
    );
}