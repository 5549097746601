export const selectFontStyle = (font)=>{
    const headerFont = font;
    switch (headerFont) {
    case 'Inter': 
        return 'medium';
    case 'Arimo': 
        return 'arimo';
    case 'Comic Neue':
        return 'comicNeue';
        break;
    case 'Courier Prime':
        return 'courierPrime';
        break;
    case 'Gothic A1':
        return 'gothicA1';
        break;
    case 'Lato':
        return 'lato';
        break;
    case 'Montserrat':
        return 'montserrat';
        break;
    case 'OpenSans':
        return 'openSans';
        break;
    case 'Roboto':
        return 'roboto';
        break;
    case 'Tinos':
        return 'tinos';
        break;
    default:
        return 'medium';
        break;
    }
}