
import TableShimmer from "./tableShimmer";

export default function TbodyShimmer(){
    return(
        <>
            <TableShimmer/>
            <TableShimmer/>
            <TableShimmer/>
            <TableShimmer/>
            <TableShimmer/>
        </>
    );
}