/* eslint-disable max-len */
import Bids from "./bids"
import BidsShimmer from "./bidsShimmer"

export default function ListingBody({currentSortColumn, handleSort, searchParam, bids, searchValue, loadBids,
    auctionData, loading}){
    return(
        <div className="customScrollbar pt-2 overflow-x-auto mb-10 z-0 relative border-[#DEDEDE] border rounded-lg mt-4">
            <div className="z-0  xl:w-full w-fit" style={{ transform: 'rotateX(180deg)' }}
                // style={{transform: "rotateX(180deg)"}}
            >
                <table aria-label="Outside Table" className="w-full overflow-auto table-auto" 
                    style={{transform: "rotateX(-180deg)"}}
                >
                    <thead>
                        <tr className="bg-[#FFFFFF] border-b rounded-lg border-[#DEDEDE] h-12">
                            <th scope="col" className="rounded-lg px-4 py-3 min-w-[100px]">
                                <span data-testid="lot_no" className="flex items-center " 
                                    onClick={(e) => {handleSort(e,'lot_number')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Lot no.</p>
                                    {currentSortColumn.key==='lot_number' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 " />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3  " />)
                                    }
                                </span>
                            </th>
                            <th scope="col" colspan={2} className=" px-4 py-3 min-w-[250px] ">
                                <span data-testid='title' className="flex items-center justify-center" 
                                    onClick={(e) => {handleSort(e,'lot_title')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Title</p>
                                    {currentSortColumn.key==='lot_title' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer " />)
                                    }
                                </span>
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[150px]">
                                <span className="flex items-center" data-testid="paddle_no"
                                    onClick={(e) => {handleSort(e,'paddle_number')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                pr-1 py-1 cursor-pointer">Paddle no.</p>
                                    {currentSortColumn.key==='paddle_number' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer " />)
                                    }
                                </span>          
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[160px]">
                                <span className="flex items-center " data-testid="bidder_name"
                                    onClick={(e) => {handleSort(e,'name')}}>
                                    <p className="text-[16px] font-medium leading-normal cursor-pointer
                                                    text-[#343434] pr-1 py-1">Bidder name</p>
                                    {currentSortColumn.key==='name' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer " />)
                                    }
                                </span>          
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[111px] ">
                                <span className="flex items-center " 
                                    onClick={(e) => {handleSort(e,'bid_status')}}>
                                    <p data-testid="bidder_status" className="text-[16px] font-medium leading-normal text-[#343434] 
                                                    pr-1 py-1 cursor-pointer">Status</p>
                                    {currentSortColumn.key==='bid_status' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer " />)
                                    }
                                </span>
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[80px] ">
                                <span className="flex items-center " data-testid="bid"
                                    onClick={(e) => {handleSort(e,'bid_amount')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                    pr-1 py-1 cursor-pointer">Bid</p>
                                    {currentSortColumn.key==='bid_amount' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer " />)
                                    }
                                </span>
                            </th>
                            <th scope="col" className=" px-4 py-3 min-w-[200px] ">
                                <span className="flex items-center " data-testid="sort-paddle"
                                    onClick={(e) => {handleSort(e,'updated_at')}}>
                                    <p className="text-[16px] font-medium leading-normal text-[#343434] 
                                                    pr-1 py-1 cursor-pointer">Latest bid</p>
                                    {currentSortColumn.key==='updated_at' ? 
                                        (currentSortColumn.order==='asc'?
                                            <img src="/images/dropdown-icon.png" alt="img" 
                                                className=" dropdown-downwords3 cursor-pointer" />:
                                            <img src="/images/dropdown-upwords.svg" alt="img" 
                                                className="dropdown-upwords3 cursor-pointer" />
                                        ):
                                        (<img src="/images/dropdown-icon.png" alt="img" 
                                            className="dropdown-upwords3 cursor-pointer " />)
                                    }
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(bids.length > 0 && !loadBids && !loading) && (
                            <Bids
                                bids={bids} auctionData={auctionData}
                            />
                        )}
                        {(searchParam.get('search_term')!== null && searchValue !== '' && bids.length===0 && !loadBids ) && 
                        <tr>
                            <td
                                colspan="8"
                                className="sm:text-center text-start sm:pl-0 xs:pl-[200px] pl-[100px]"
                            >
                                <div className="h-[180px] mt-[100px]">
                                    <p className="text-[18px] font-medium  leading-normal text-[#34343">
                                        No search results for ‘{searchValue.length>30 ? 
                                            searchValue.slice(0,30)+'...' : searchValue}’</p>
                                </div>
                            </td>
                        </tr>
                        }
                        {
                            (searchParam.get('search_term')=== null && searchValue === '' && !loadBids && bids.length===0 && !loading) && <tr className="">
                                <td colSpan="8" className="sm:text-center text-start sm:pl-0 
                                        xs:pl-[200px] pl-[100px]">
                                    <div className="h-[180px] mt-[100px]">
                                        <p
                                            className="text-[18px] font-medium  leading-normal 
                                                        text-[#34343 mb-4"
                                        >
                                            No bids found
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        } 
                        {(loadBids || loading) &&
                        <BidsShimmer/> 
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}